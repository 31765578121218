import { Injectable } from '@angular/core';

import { SearchFilterService } from '../../_services';
import { SortOptionModel } from '../../_interfaces';

@Injectable()
export class SortOptionService {

  private sortOption: SortOptionModel[] = [
    { id: 1, title: 'Relevance', value: 'relevance', active: false },
    { id: 2, title: 'Most Recent', value: 'datedesc', active: false },
  ];

  constructor (private _searchFilterSvc: SearchFilterService) {
    this.setActiveDefault(this._searchFilterSvc.getDefaultSortBy());
  }

  public get (id?: number): Object[] | Object {
    if (id) {
      return this.sortOption.filter((type) => {
          return type.id === id;
        })[0] || {};
    } else {
      return this.sortOption;
    }
  }

  public setActiveDefault (value: string) {
    let defaultSort;

    this.sortOption.forEach((option) => {
      if (option.value === value) {
        option.active = true;
        defaultSort = option;
      } else {
        option.active = false;
      }
    });

    // if value passed does not match any options then use the first option.
    if (!defaultSort) {
      this.sortOption[0].active = true;
    }

    this._searchFilterSvc.setDefaultSortBy(defaultSort.value);
  }

  public isSupportedSort (value: string): boolean {
    let sortMethods = this.sortOption.filter((sort) => {
      return sort.value === value;
    });

    return sortMethods.length === 1;
  }
}
