import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppState } from '../../../app.service';
import { SiteHeaderMenuLinkList } from '../../../_interfaces/site-header-menu/site-header-menu';

declare var dataLayer: any;

@Component({
  selector: 'pq-topicListMenu',
  styleUrls: ['./_topicListMenu.component.scss'],
  templateUrl: './topicListMenu.component.html'
})
export class TopicListMenuComponent {

  @Input() public isTopicsMenuOpen: boolean = false;
  @Output() public closed = new EventEmitter();

  public profile: string = this._appState.get('current_profile') || '';
  public menuData: SiteHeaderMenuLinkList = {
    title: '',
    topics: [],
    type: ''
  };

  constructor (private _appState: AppState) {}

  public toggleHeaderSubMenu (e: Event) {
    const elementName = (<any> e.currentTarget)['name'];
    const elementPropValue = (<any> this)['_' + elementName];

    if (elementPropValue) {
      (<any> this)['_' + elementName] = false;
    } else {
      (<any> this)['_' + elementName] = true;
    }

  }

  public pushHomeTopicsToGA(category: String, label: String, origin: String) {
    if (dataLayer && Array.isArray(dataLayer)) {
      dataLayer.push({
        'event': 'HomeNavigation',
        'category': category,
        'label': origin + '_' + label
      });
    }
  }

  public close (): void {
    // call to parent to close the menus
    this.closed.emit();
  }

  @Input() set menu (data: SiteHeaderMenuLinkList) {
    let type;

    if (data['title']) {
      type = data['title'] === 'Common Assignments' ? 'assignments' : data['title'].toLowerCase();
    } else if (data['treeName'] === 'nonfictionbooks-detail') {
      type = data['treeName'].split('-')[0];
    }

    data['topics'].forEach((topic: object) => {
      let url = [ '/', this.profile, 'researchtopics' ];

      if (type) {
        url.push(type);
      }

      url = url.concat([ topic['nodeId'], '1' ]);

      topic['url'] = url;
    });

    if (data['treeName']) {
      data['title'] = data['treeName'];
    }

    this.menuData = data;
  }
}
