import svg4everybody from 'svg4everybody';

export function safeSvg4Everybody () {
  /*
      need to do this becuase svg4everybody sometimes causes
      issues when the app loads before svg4everybody is ready or
      when it tries to updated already updated svg tags

   */
  
  try {
    if (svg4everybody) {
      svg4everybody({
        polyfill: true
      });
    }
  } catch (error) {
    // catch the error. don't know if we may want to record it
    // just in case svg4everybody is causing issues down the road.
  }
}
