import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MaintainAttributesModel } from '../../_interfaces';

@Injectable()
export class MaintainService {

  public maintainModel: MaintainAttributesModel;

  private _url: string = '/svc/grapple/maintain';

  constructor (private _http: HttpClient) {
  }

  // Method to fetch config info from Grapple services
  public getConfigInfo (): Observable<any> {

    return this._http.get(this._url)
      .pipe(
        map((resp: object) => {
          this.maintainModel = resp['ConfigResponse'];

          // Set App side config values
          this.maintainModel.appConfigLocaleCode = window.navigator.language;
          this.maintainModel.appConfigAppServer = location.host;
          this.maintainModel.appConfigXFwdProto = location.protocol.split(':')[0];
          return this.maintainModel;
        })
      );
  }

  // Method to fetch build info from build.json
  public getBuildInfo (): Observable<any> {
    return this._http.get('/build.json');
  }

}
