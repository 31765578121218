<div class="container-barcode">
    <div>
        <h1>Access {{ getProductName() }}<br>using my library card</h1>

        <form #barcodeLoginForm="ngForm" (ngSubmit)="onSubmitBarcode($event, barcodeLoginForm.value, barcodeLoginForm.valid)">
            <input type="hidden" name="accountId" [value]="accountId" ngModel="{{accountId}}"l>
            <input type="hidden" name="groupId" [value]="groupId" *ngIf="groupId" ngModel="{{groupId}}">

            <div>
                <div class="alert alert-danger" *ngIf="currentError" [innerHTML]="currentError"></div>

                <div [ngClass]="currentError ? 'form-group has-error' : 'form-group'">
                    <label [attr.for]="'barcode'">Barcode number</label>
                    <input type="text" class="form-control" #barcode name="barcode" ngModel [disabled]="submitted" maxlength="50">
                </div>
            </div>
            <div class="container-actions">
                <button type="submit" class="btn btn-primary" [disabled]="submitted" [ngSwitch]="submitted">
                        <span *ngSwitchCase="true">
					        working...
				        </span>
                    <span *ngSwitchCase="false">
					        Login
				        </span>
                </button>
            </div>
        </form>
    </div>
</div>



