<div class="container-notification" [class.open]="show">
  <div>
    <span [innerHTML]="message"></span>
    <button type="button" aria-label="close notification" (click)="closeNotification($event)">
      <svg class="gr-cancel-squared" aria-hidden="true">
        <use xlink:href="./assets/img/icons.svg#gr-cancel-squared"></use>
      </svg>
    </button>
  </div>
</div>
