import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class GoogleAuthentificationService {

  private _url: string = '/svc/grapple/googleredirect';

  constructor (
    private _http: HttpClient,
  ) {}

  public get (profile: string): Promise<object> {
    let location = window.document.location.port ?
      window.document.location.protocol + '//' + window.document.location.hostname + ':' + window.document.location.port :
      window.document.location.protocol + '//' + window.document.location.hostname;

    if (profile) {
      location += '/' + profile;
    }

    return firstValueFrom(this._http.get(this._url, { params: { context: location + '/sso/google'}}).pipe(
      map((resp: object) => {
          return resp && resp['RedirectResponse'] ? resp['RedirectResponse'] : {};
        }
      )));
  }
}
