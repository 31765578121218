import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivate, Routes } from '@angular/router';
import { SessionService } from '../_services/session/session.service';
import { AppState } from '../app.service';
import { Observable, throwError, of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import moment from 'moment';
import { ROUTES } from '../app.routes';

@Injectable()
export class AuthGuardService implements CanActivate {
  private _isLoggedIn: boolean = false;

  constructor (
    public appState: AppState,
    private _sessionService: SessionService,
    private _router: Router,
  ) {}

  public canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> | Promise<boolean> | boolean {
    const status = this.appState.get('status');

    // redirect if the app general status is a 504 to the error view
    if (status === '504') {
      this._router.navigate(['error'], { replaceUrl: true });
      return true;
    }

    // create and populate the session object (which is used to determine when to call the session service)
    const url: string = state.url;
    const sessionObject = sessionStorage.getItem('sessionObject')
      ? JSON.parse(sessionStorage.getItem('sessionObject')) : {};

    const sessionCreated = sessionObject['creationDate'];
    const sessionCreatedDate = sessionCreated ? new Date(sessionCreated) : null;
    const isAuthenticated = typeof sessionObject['sessionRoles'] !== 'undefined' && (sessionObject['sessionRoles'] !== 'unauthenticated');

    let futureDate = moment(sessionCreatedDate);
    futureDate = futureDate.add(15, 'm');

    this._setProfile(url, route.paramMap.get('profile'));
    const profile = this.appState.get('current_profile');

    const profileCreationDate = this._getProfileCreationDate(profile);
    const futureProfileDate = moment(profileCreationDate);

    futureProfileDate.add(15, 'm');

    // there is no session creation date OR
    // has creation date less then 15 minutes ago or does not have session ID OR
    // there is no session id
    if (sessionCreatedDate === null || (sessionCreatedDate && moment.isDate(sessionCreatedDate) && futureDate.isBefore(/* now */)) || !isAuthenticated) {
      return this.doLoginCheck(url, profile, route.paramMap.get('profile'));
    }

    this._sessionService.setState(sessionObject);

    this._portalRedirects(url);
    return isAuthenticated;
  }

  public doLoginCheck (url: string, profile: string, routeProfile: string): Observable<boolean> | boolean {
    return this._sessionService.loggedInCheck().pipe(
      first(),
      map((isLoggedIn: boolean) => {
        this._isLoggedIn = isLoggedIn;
        if (isLoggedIn) {
          this._setProfile(url, routeProfile);

          this._portalRedirects(url);

          if (url === '/') {
            const commands = profile ? [profile] : [''];
            this._router.navigate(commands);
          }

          return isLoggedIn;

        } else {
          this._handleProxyUrl(url);
        }
      },
      catchError(error => this._handleError(error, ' Server Error')))
    );
  }

  private _portalRedirects (urlPath: string): boolean {
    // portal and product redirects
    // don't really want this in the auth guard but multiple route paths guards are not possible
    const grappleProducts = this.appState.get('grappleProducts') || [];
    const allProducts = this.appState.get('allProducts') || [];
    const pathData =  urlPath.split('?')[0].split('/').filter((fragment: string) => {
      return fragment !== '';
    });
    const urlEndSegment = pathData.length ? pathData[pathData.length - 1] : '';
    const urlStartSegment = pathData.length ? pathData[0] : '';
    const portalPath = 'portal';
    const queryParams = new URLSearchParams(urlPath.split('?')[1]);
    let profile = this.appState.get('current_profile');
    const hasOnlyOneProduct = (allProducts.length === 2 && grappleProducts.length === 1);

    //when there are no products on the subscription route to portal home which displays no products message
    if ((allProducts.length === 0) && urlStartSegment !== 'portal') {
      this.appState.set({'current_profile': null});
      this._router.navigate([portalPath, 'home'], { replaceUrl: true });
      return true;
    }  

    const isValidPathRoot = this._isPossibleValidPath(urlStartSegment);
    if (!this._isValidProfile(profile) && !isValidPathRoot && (urlStartSegment !== portalPath)) {
      return true;
    }

    // if url ends in a valid moniker, redirect app to the /moniker/home in order to prevent the 404 page from displaying
    if (pathData.length === 1 &&  this._isValidProfile(profile)) {
      this._router.navigate(['/', profile, 'home'], { replaceUrl: true });
      return true;
    }

    if ((urlStartSegment !== profile) && (urlStartSegment !== portalPath)) {
      // if account has one product, update profile to be that product
      if (hasOnlyOneProduct && this._isValidProfile(profile)) {
        profile = grappleProducts[0]['profile'];
        this.appState.set({ current_profile: profile });
        // redirect user to portal if there are no products on the account and user is NOT on the portal page
      } else if ((allProducts.length === 0) && (urlStartSegment !== portalPath)) {
        this._router.navigate([portalPath, 'home'], { replaceUrl: true });
        return false;
      }

      // portal redirect for accounts with 1 gr product and 1 product total
      if (((urlEndSegment === 'home') || (urlStartSegment === '')) && hasOnlyOneProduct) {
        profile = grappleProducts[0]['profile'];
        this.appState.set({ current_profile: profile });
        this._router.navigate([profile, 'home'], { replaceUrl: true, queryParams });

      } else if ((urlStartSegment === portalPath) && (pathData.length === 1)) {
        this._router.navigate([portalPath, 'home'], { replaceUrl: true, queryParams });

      } else if (((pathData.length === 0) || (urlStartSegment === portalPath)) && hasOnlyOneProduct) {
        this._router.navigate([profile, 'home'], { replaceUrl: true, queryParams });

      } else if ((pathData.length === 0) && ((grappleProducts.length === 0) || (grappleProducts.length > 1))) {
        this._router.navigate([portalPath, 'home'], { replaceUrl: true, queryParams });
      }
    }
  }

  private _handleProxyUrl (url: string): boolean {
    let defaultLoginUrl = sessionStorage.getItem('defaultLoginUrl');

    if (defaultLoginUrl) {
      if ((defaultLoginUrl.includes('/barcode?')
        || defaultLoginUrl.includes('/login?') || defaultLoginUrl.includes('/shibboleth?')
        || defaultLoginUrl.includes('/samlsso?') || defaultLoginUrl.includes('/googleauth?'))
        && defaultLoginUrl.toLowerCase().includes('accountid')) {

        // In PAM if default auth url is /barcode?accountid=92926&groupid=1234411
        // or /login?accountid=189737
        if (defaultLoginUrl.includes('/shibboleth?')) {
          defaultLoginUrl = defaultLoginUrl.replace("/shibboleth?","/login?");
        }
        if (defaultLoginUrl.includes('/samlsso?')) {
          defaultLoginUrl = defaultLoginUrl.replace("/samlsso?","/login?");
        }
        const fullDefaultLoginUrl =  new URL(window.location.protocol + '//' +  window.location.host + defaultLoginUrl);
        const queryParams = new URLSearchParams(fullDefaultLoginUrl.search.slice(1));
        const redirectUrl = new URL(window.location.protocol + '//' +  window.location.host + decodeURI(url));
        const redirectUrlParams = this._removeQueryStringParameter('searchid', redirectUrl.search.slice(1));

        if (!queryParams.has('location') && url.indexOf('/home') === -1) {
          let location = redirectUrl.pathname + '?' + redirectUrlParams.toString();

          if (location[0] !== '/') {
            location = '/' + location;
          }

          queryParams.set('location', location);
        }

        this._router.navigate([ defaultLoginUrl.slice(1).split('?')[0] ], { queryParams: this._paramsToObject(queryParams) });

      } else if (defaultLoginUrl.includes('?')) {

        // In PAM if default auth url is http://ezproxy.qa.proquest.com?url for an accountid
        // And if we access = http://localhost:3000/elibrary/document/3521734?accountid=93106
        // document.location.href is going to be the same as above
        // However if we access = http://localhost:3000/elibrary/?accountid=93106
        // this url will be navigated through no-content-guard. Hence the url will have location params.
        const urlDataArray = url.split("?");
        const siteUrl = window.location.protocol + '//' + window.location.hostname;
        let urlParams = new URLSearchParams(urlDataArray[1]);
        let redirectLocation = siteUrl + urlDataArray[0];

        // update url params from location
        if (urlParams.get('location')) {
          const locationUrlArrayData = decodeURI(urlParams.get('location')).split('?');

          redirectLocation = siteUrl + locationUrlArrayData[0];
          urlParams = new URLSearchParams(locationUrlArrayData[1]);
        }

        // remove search id.
        if (urlParams.get('searchid')) {
          urlParams.delete('searchid');
        }

        // update redirect url
        redirectLocation = redirectLocation + '?' + urlParams.toString();
        debugger;
        window.location.replace(defaultLoginUrl + redirectLocation);
      } else {
        // In PAM if default proxy url is http://fpizzo1.wixsite.com/home
        window.location.replace(defaultLoginUrl);
      }
    } else {
      if (url.includes('/?accountid=')) {
        // If no default auth url and url contains accountid param
        const redirectUrl = new URL(window.location.href + decodeURI(url));
        const queryObject = this._removeQueryStringParameter('searchid', redirectUrl.search.slice(1));
        let commands = ['login'];

        if (this.appState.get('current_profile')) {
          commands = [this.appState.get('current_profile')].concat(commands);
        }

        this._router.navigate(commands, { queryParams: queryObject, replaceUrl: true });
      } else {
        return  this._handleLoginRedirect(url);
      }
    }
  }

  private _handleError (error: any, message: string): Observable<boolean> {
    let errorMsg = error;

    if (error && error.error) {
      errorMsg = error.error;
    }

    throwError(new Error(errorMsg || message));

    return of(false);
  }

  private _handleLoginRedirect (url: string): boolean {
    let navParams = { replaceUrl: true };
    let commands = ['login'];

    if (this.appState.get('current_profile')) {
      commands = [this.appState.get('current_profile')].concat(commands);
    }

    /*
    removing the embedded token auth section on failed log in attempts so the if the url log in
     with user/pass the redirect will not cause  infinite loop
    */
    if (/\/embedded\//.test(url)) {
      const urlSegments = url.split('/');
      const index = urlSegments.indexOf('embedded');
      const updatedUrl = urlSegments.slice(0, index).join('/');
      url = updatedUrl;
    }

    if (url && (url.indexOf('/home') === -1 && url.indexOf('/login') === -1 && url !== '/')) {
      // If document/<goid> url contains searchId and if not authenticated,
      // remove searchId from location. We should not log this doc retrieval with searchId in usage.
      // Logging doc retrieval with searchId in usage is only applicable from search results page
      const urlDataArray = decodeURI(url).split('?');
      navParams['queryParams'] = { location: urlDataArray[0] + '?' + this._removeQueryStringParameter('searchid', urlDataArray[1]) };
    } else if (url) {
      navParams['queryParams'] = { location: url };
    }

    sessionStorage.removeItem('sessionObject');
    this._router.navigate(commands, navParams);

    return false;
  }

  private _removeQueryStringParameter (key: string, queryParamString: string): URLSearchParams {
    const params = new URLSearchParams(queryParamString);

    if (params.has(key)) {
      params.delete(key);
    }

    return params;
  }

  private _isValidProfile (requestedProfile: string) {
    let allProducts = this.appState.get('grappleProducts') || [];
    if (!Array.isArray(allProducts)) {
      allProducts = [ allProducts ];
    }

    return allProducts.filter((product) => {
      return product && product['profile'] === requestedProfile;
    }).length  >= 1;
  }

  private _setProfile (url: string, routeProfile: string): void {
    let urlProfile;
    let profile = this.appState.get('current_profile') || '';

    // if there is no profile property create one (profile property houses the loaded profiles)
    // this could happen if the profile does not exist on the server
    if (this.appState && (typeof this.appState.get('profile') === 'undefined' || this.appState.get('profile') === null)) {
      this.appState.set({ profile: {} })
    }

    if (routeProfile) {
      urlProfile = routeProfile;
    }

    // update the current profile from the next param (next is the upcoming route to be displayed)
    if (urlProfile && this.appState.get('current_profile') !== urlProfile && this._isValidProfile(urlProfile)) {
      this.appState.set({ current_profile: urlProfile });
      profile = urlProfile;
    }

    if (!urlProfile && profile && this._isValidProfile(profile)) {
      this._router.navigateByUrl('/' + profile + url);
    }
  }

  private _getProfileCreationDate (profile): Date {
    return profile && typeof this.appState.get('profile')[profile] !== 'undefined' &&
      this.appState.get('profile')[profile]['creation_date'] ?
        new Date(this.appState.get('profile')[profile]['creation_date']) :
        new Date();
  }

  private _isPossibleValidPath (pathRoot: string): boolean {
    let isValid =  false;
    const routes: Routes = ROUTES;

    routes.forEach((route: object) => {
      if (route['children'] && !isValid) {
        isValid = route['children'].some((childRoute: object) => {
          return childRoute['path'] === pathRoot;
        });
      }
    });

    return isValid;
  }

  private _paramsToObject (entries: URLSearchParams): object {
    let result = {};
    entries.forEach((value, key) => {
      result[key] = value;
    });

    return result;
  }
}
