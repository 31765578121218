import { Routes } from '@angular/router';
import { BarcodeComponent } from './barcode/barcode.component';
import { LoginComponent } from './login/login.component';
import { AppDownComponent, ErrorGuard } from './app-down';

import { AuthGuardService } from './_utilities/auth.guard.service';
import { AuthCheckGuardService } from './auth-check/auth.check.guard.service';
import { UpdatePathComponent, UpdatePathGuardService } from './update-path';
import { NoContentComponent } from './no-content';
import { SsoComponent } from './login/sso/sso.component';

export const ROUTES: Routes = [
  // required authorization url paths with the app
  // parent path is required in order to get full path information which is only currently available
  // at canActivate and not canLoad.

  { path: 'home', component: UpdatePathComponent, canActivate: [ UpdatePathGuardService ] },
  { path: 'advanced', component: UpdatePathComponent, canActivate: [ UpdatePathGuardService ] },
  { path: 'results', component: UpdatePathComponent, canActivate: [ UpdatePathGuardService ] },
  { path: 'document', component: UpdatePathComponent, canActivate: [ UpdatePathGuardService ] },
  { path: 'educators', component: UpdatePathComponent, canActivate: [ UpdatePathGuardService ] },
  { path: 'researchtopics', component: UpdatePathComponent, canActivate: [ UpdatePathGuardService ] },
  { path: 'leadingissues', component: UpdatePathComponent, canActivate: [ UpdatePathGuardService ] },
  { path: 'browsetopic', component: UpdatePathComponent, canActivate: [ UpdatePathGuardService ] },

  // non auth
  { path: 'login/:ssotype', component: SsoComponent },
  { path: ':profile/login/:ssotype', component: SsoComponent },
  { path: 'sso/:ssotype', loadChildren: () => import('./sso').then((m) => m.SsoModule)  },
  { path: ':profile/sso/:ssotype', loadChildren: () => import('./sso').then((m) => m.SsoModule)  },
  { path: 'login', component: LoginComponent },
  { path: ':profile/login', component: LoginComponent },
  { path: 'barcode', component: BarcodeComponent },
  { path: ':profile/barcode', component: BarcodeComponent },
  { path: 'error', component: AppDownComponent, canActivate: [ ErrorGuard ] },
  { path: ':profile/error', component: AppDownComponent, canActivate: [ ErrorGuard ] },
  { path: 'maintain', loadChildren: () => import('./maintain').then((m) => m.MaintainModule) },

  { path: 'portal', redirectTo: '/portal/home', pathMatch: 'full' },

  // cleverauth sso type urls
  { path: 'cleverauth', redirectTo: 'login/cleverauth', pathMatch: 'full' },
  { path: ':profile/cleverauth', redirectTo: ':profile/login/cleverauth', pathMatch: 'full' },
  { path: 'googleauth', redirectTo: 'login/googleauth', pathMatch: 'full' },
  { path: ':profile/googleauth', redirectTo: ':profile/login/googleauth', pathMatch: 'full' },

  {
    path: '',
    canActivate: [ AuthCheckGuardService ],
    children: [
      { path: 'embedded/:token', loadChildren: () => import('./auth-check').then((m) => m.AuthCheckModule ) },
      { path: ':profile/embedded/:token', loadChildren: () => import('./auth-check').then((m) => m.AuthCheckModule) },
      { path: 'ip', loadChildren: () => import('./auth-check').then((m) => m.AuthCheckModule)  },
      { path: ':profile/ip', loadChildren: () => import('./auth-check').then((m) => m.AuthCheckModule) },
      { path: 'refurl', loadChildren: () => import('./auth-check').then((m) => m.AuthCheckModule)  },
      { path: ':profile/refurl', loadChildren: () => import('./auth-check').then((m) => m.AuthCheckModule) },
      { path: 'geoauth', loadChildren: () =>  import('./auth-check').then((m) => m.AuthCheckModule) },
      { path: ':profile/geoauth', loadChildren: () => import('./auth-check').then((m) => m.AuthCheckModule) },
    ]
  },
  {
    path: ':profile',
    canActivate: [ AuthGuardService ],
    children: [
      { path: 'home', loadChildren: () => import('./home').then((m) => m.HomeModule) },
      { path: 'advanced', loadChildren: () => import('./advanced-search').then((m) => m.AdvancedSearchModule) },
      { path: 'results', loadChildren: () => import('./results').then((m) => m.ResultsModule) },
      { path: 'document', loadChildren: () => import('./doc-view').then((m) => m.DocViewModule) },
      { path: 'educators', loadChildren: () => import('./educators').then((m) => m.EducatorsModule) },

      // Research Topics
      { path: 'researchtopics', loadChildren: () => import('./topics').then((m) => m.TopicsModule) },

      // Issues
      { path: 'leadingissues', loadChildren: () => import('./topics').then((m) => m.TopicsModule) },

      { path: '**', component: NoContentComponent },
    ]
  }
];
