import { Component, OnInit,ViewEncapsulation  } from '@angular/core';
import { NotificationService } from './notification.service';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import moment from 'moment';

@Component({
  selector: 'pq-notification',
  styleUrls: ['./_notification.component.scss'],
  templateUrl: './notification.component.html',
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent implements OnInit {
  public message: string = '';
  public show: boolean = false;

  constructor(
    private _notificationSvc: NotificationService
  ) {}

  public ngOnInit ()  {
    let hideNotification = (Cookie.get('hideNotification') === 'true');
    // get the message and display it as long as the user has not hidden the message during this window session.
    if (!hideNotification) {
      this._notificationSvc.get().subscribe((resp: Object) => {
        this.message = this._getMessage(resp);

        // display the message if there is one
        if (this.message) {
          this.show = true;
        } else {
          this.show = false;
        }
      });
    }
  }

  public closeNotification (e: Event): void {
    Cookie.set('hideNotification', 'true', null, '/');
    this.show = false;
  }

  private _getMessage (data: Object): string {
    let message = '';
    let startDate;
    let endDate;
    let now =  moment();

    if (data['message']) {
      return data['message'];
    }

    if (data['notification'] && data['notification']['message']
      && data['notification']['startDate'] && data['notification']['endDate']) {
      startDate = moment(data['notification']['startDate']);
      endDate = moment(data['notification']['endDate']);

      // check to see if the start and end dates are valid and check to see if the message should be displayed
      // based on if the current date is after/on start date and before/on end date
      if (startDate.isValid() && endDate.isValid()
        && (startDate.isBefore(endDate) || startDate.isSame(endDate))
        && (now.isAfter(startDate) || now.isSame(startDate))
        && (now.isBefore(endDate) || now.isSame(endDate))) {

        return data['notification']['message'];
      }

    }

    return message;
  }
}
