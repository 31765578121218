import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, first, catchError } from 'rxjs/operators';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import XMLString from  '../../_utilities/xmlstring';
import { QueryString } from '../../_utilities/querystring';
import { SessionModel } from '../../_interfaces';
import { AppState } from '../../app.service';
import SessionTokenRedirect from '../../_utilities/session-token-redirect';
import DomainChecks from 'src/app/_utilities/domain-check';

@Injectable()
export class SessionService {
  public session: SessionModel = new SessionModel();
  private _isLogIns: Subject<boolean> = new ReplaySubject<boolean>(1);

  private _url: string = '/svc/grapple/session';

  constructor (
    private _http: HttpClient,
    private _queryString: QueryString,
    private _appState: AppState
  ) {}

  public load (): Observable<any> {
    const data = this.createPostData();
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(this._url, data, ({headers}));
  }

  public updateSession (nSession: SessionModel) {
    this.session = nSession;
  }

  public loggedInCheck (): Observable<boolean> {
     return this.load().pipe(
       first(),
       map((resp: Response) => {
         const json = resp;
         if (json['SessionResponse']) {
           if (json['SessionResponse'] && json['SessionResponse']['sessionRoles'] === 'authenticated') {
             const sessionObject = this.createSessionObject(json['SessionResponse'])

             sessionStorage.setItem('sessionObject', JSON.stringify(sessionObject));
             Cookie.set('session', sessionObject['sessionId'], null, '/');
             Cookie.set('branding', JSON.stringify(json['SessionResponse']['branding']), null, '/');

             const session = json['SessionResponse'] || {};
             this.setState(session);

             return true;
           } else {
             if (json['SessionResponse']) {
               if (json['SessionResponse']['defaultLoginUrl']) {
                 sessionStorage.setItem('defaultLoginUrl', json['SessionResponse']['defaultLoginUrl']);
               }

               if (json['SessionResponse']['errorInfo']) {
                 this._appState.set({ sessionErrorInfo: json['SessionResponse']['errorInfo'] });
               }
             }

             // clear session related cookies and cache
             Cookie.delete('session', '/');
             sessionStorage.removeItem('sessionObject');
           }
         }

         return false;
       }),
       catchError((err: any) => {
         // todo: may want to do something with the returns error
         return of(false);
       })
     );
  }

  public isLoggedIn (): boolean {
    return (this.session['sessionRoles'] === 'authenticated') || false;
  }

  public getSession (): SessionModel {
    return this.session;
  }

  public createPostData (): string {
    const pathname = window.location.pathname;
    const sessionObject = sessionStorage.getItem('sessionObject') ?
      JSON.parse(sessionStorage.getItem('sessionObject')) : {};

    const sessionId = sessionObject['sessionId'];
    const referrer = document.referrer;
    const accountId = this._queryString.getQueryString()['accountId'] || this._queryString.getQueryString()['accountid'];
    let token = this._queryString.getQueryString()['token'] ;
    let data = '<SessionRequest>';
    let durableUrl = document.location.href;
    const clever = this._appState.get('clever');
    let google = this._appState.get('google');
    const shibboleth =  this._appState.get('shibboleth');

    // forced auth type based on url
    if (/\/embedded\//.test(pathname)) {
      let pathData = window.location.pathname.split('/');
      token = pathData[pathData.length - 1]; // last value in the url path is the token
      data += XMLString.xmlwrap('TOKEN', 'strictTo', false);
      sessionStorage.setItem('authType', 'token');
    } else if (/\/ip/.test(pathname)) {
      data += XMLString.xmlwrap('IP', 'strictTo', false);
    } else if (/\/refurl/.test(pathname)) {
      data += XMLString.xmlwrap('REFERRER', 'strictTo', false);
      sessionStorage.setItem('authType', 'refurl');
    } else if (/\/geoauth/.test(pathname)) {
      data += XMLString.xmlwrap('GEOAUTH', 'strictTo', false);
    }

    data += XMLString.xmlwrap(referrer, 'referrer', false);
    if (token) {
      data += XMLString.xmlwrap(token, 'token', false);
      if(google != null){
        google = null;
      }
    } else if (sessionId) {
      data += XMLString.xmlwrap(sessionId, 'sessionId', false);
    } 
    if (accountId) {
      data += XMLString.xmlwrap(accountId, 'accountId', false);
      Cookie.set('accountId', accountId, null, '/');
    }

    if(clever) {
      data += XMLString.xmlwrap('CLEVER', 'strictTo', false);
      data += XMLString.xmlwrap(clever['code'], 'cleverCode', false);
      data += XMLString.xmlwrap(clever['state'], 'cleverState', false);
    }

    if(google) {
      data += XMLString.xmlwrap('GOOGLE', 'strictTo', false);
      data += XMLString.xmlwrap(google['code'], 'googleCode', false);
      data += XMLString.xmlwrap(google['state'], 'googleState', false);
    }

    if (shibboleth) {
      data += XMLString.xmlwrap(shibboleth['shibbolethParameters'], 'shibbolethParameters', false);
    }

    data += XMLString.xmlwrap(location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : ''), 'proxyHostPort', false);
    data += XMLString.xmlwrap(durableUrl, 'durableUrl', false);
    data += '</SessionRequest>';

    return data;
  }

  public setState (session : object = {}): void {

    if (session['preferences']) {
      session['preferences'].forEach((entry) => {
        if (entry['code']  === 'DEFAULT_RESULTS_PER_PAGE') {
          let entryValue = parseInt(entry['value'], 0);
          this._appState.set({ DEFAULT_RESULTS_PER_PAGE: entryValue });
        }
        if (entry['code']  === 'ENABLE_GOOGLE_INTEGRATION') {
          this._appState.set({ ENABLE_GOOGLE_INTEGRATION: entry['value'] });
        }
        if (entry['code']  === 'ENABLE_ONEDRIVE') {
          this._appState.set({ ENABLE_ONEDRIVE: entry['value'] });
        }
        if (entry['code']  === 'PRODUCT_URL') {
          this._appState.set({ PRODUCT_URL: atob(entry['value']) });
        }
        if (entry['code']  === 'REDIRECT_ID') {
          this._appState.set({ REDIRECT_ID: entry['value'] });
        }
      });
    }

    if (this._appState.get("REDIRECT_ID")) {
      let productUrl = this._appState.get("PRODUCT_URL")
      let redirectId = this._appState.get("REDIRECT_ID")
      let domainCheck = new DomainChecks();
      if (domainCheck.checkIfRedirectFromProxyDomain()) {
        let sessionTokenRedirect = new SessionTokenRedirect();
        sessionTokenRedirect.doRedirect(productUrl, redirectId);
      }
    }

    this._appState.set({
      accountId: session['accountId'],
      sessionType: session['sessionType'],
      branding: session['branding']
    });
  }

  public createSessionObject (sessionData: object): object {
    const sessionObject = {};

    if (sessionData) {
      const creation = new Date();

      (<any> sessionObject).creationDate = creation;
      (<any> sessionObject).sessionId = sessionData['sessionId'];
      (<any> sessionObject).branding = sessionData['branding'];
      (<any> sessionObject).preferences = sessionData['preferences'];
      (<any> sessionObject).ltiDeepLinkUri = sessionData['ltiDeepLinkUri'];
      (<any> sessionObject).sessionType = sessionData['sessionType'];
      (<any> sessionObject).sessionRoles = sessionData['sessionRoles'];
    }

    return sessionObject;
  }
}
