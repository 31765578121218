import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { AppState } from '../app.service';
import { AuthService } from '../_services/auth/auth.service';
import { SeoService } from '../_services/seo/seo.service';
import { SessionService } from '../_services/session/session.service';
import { ConfigService } from '../_services/config/config.service';
import { SessionModel } from '../_interfaces/session/session.model';
import { BootstrapProduct } from '../_utilities';

declare var dataLayer: any;

@Component({
  selector: 'pq-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./_barcode.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BarcodeComponent implements OnInit, AfterViewInit {
  @ViewChild('barcode', { static: false }) public vc: any;

  public submitted: boolean = false;
  public currentError: string = '';
  public errorMsg: Object = {
    incorrect: this.appState.get('barcodeIncorrect'),
    missing: this.appState.get('barcodeMissing'),
    issue: this.appState.get('barcodeIssue'),
    producterror: this.appState.get('noProductOnSubscriptionError'),
    zeroproductserror: this.appState.get('portalNoProductsError')
  };
  public groupId: string;
  public accountId: string;

  private _subscription: Subscription;
  private _redirectLocation: string = '';
  private _profile: string;
  private _bootRequest: Promise<object>;

  constructor (
    public appState: AppState,
    private _authService: AuthService,
    private _seoSvc: SeoService,
    private _sessionSvc: SessionService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _bootstrapProduct: BootstrapProduct,
    private _configSvc: ConfigService
  ) {

    this._subscription = _route.queryParams.subscribe((queryParam: any) => {
      // reset state to default unauthenticated state
      sessionStorage.clear();
      Cookie.delete('session', '/');
      Cookie.delete('accountId', '/');
      
      this._profile = _route.snapshot.params['profile'];

      let accountId = queryParam['accountId'] || queryParam['accountid'];
      let groupId = queryParam['groupId'] || queryParam['groupid'];
      let redirectLocation = queryParam['location'];
      let defaultPath = this._profile ? this._profile + '/home' : 'home';

      if (accountId) {
        this.accountId = accountId;

        if (groupId) {
          this.groupId = groupId;
        }

        if (redirectLocation) {
          this._redirectLocation = redirectLocation;
        }

        this._seoSvc.setTitle('Barcode');

      } else {
        this._router.navigate([defaultPath]);
      }
    });
  }

  public ngOnInit(): void {
    sessionStorage.setItem('gaSessionType', 'barcode');
  }

  public ngAfterViewInit (): void {
    this.vc.nativeElement.focus();
  }

  public onSubmitBarcode (e: Event, formData: Object, isValid: boolean): void {
    e.preventDefault();

    const profile = this.appState.get('current_profile') || undefined;

    this.submitted = true;
    this.currentError = '';

    if (isValid && (<any> formData)['barcode']) {
      // reset state to default unauthenticated state
      sessionStorage.clear();
      Cookie.delete('session', '/');
      Cookie.delete('accountId', '/');

      // set existing profile
      if (profile) {
        this.appState.set({ current_profile: profile });
      }
      if (dataLayer && Array.isArray(dataLayer)) {
        dataLayer.push({'event': 'BarcodeEntry', 'label': ( <any>formData )[ 'barcode' ]});
      }

      const request = this._doLogin(formData);
      request.then((resp: object) => {
        this._handleLoginResp(resp);
      });

    } else {
      this._handleError(null, 'missing');
    }

  }

  public getProductName () {
    return this.appState.get('displayname') || 'ProQuest';
  }

  private async _doLogin (model): Promise<object> {
    const request = await firstValueFrom(this._authService.login(model));
    return request;
  }

  private _handleLoginResp (resp: object) {
    let session = resp['SessionResponse'];

    if (session && session['sessionRoles'] === 'authenticated') {
      this._configSvc.get().then((configResp) => {
        this.appState.set(configResp);

        const sessionObject = this._sessionSvc.createSessionObject(session);

        // reset state to default unauthenticated state
        sessionStorage.clear();
        Cookie.delete('session', '/');
        Cookie.delete('accountId', '/');

        const previousProfile = this.appState.get('current_profile');
        const previousDisplayName = this.appState.get('displayname');

        this.appState.clear();
        // set cookie because services need the session ID to prove the user is authenticated
        Cookie.set('session', sessionObject[ 'sessionId' ], null, '/');
        this.appState.set({ current_profile: previousProfile, displayname: previousDisplayName, sessionType: 'BARCODE' });

        this._bootRequest = this._bootstrapProduct.executeLoad();
        this._bootRequest.then((resp: object) => {
          if (resp['completed'] === true && resp['status'] === 'successful') {

            // update app state
            sessionStorage.setItem('sessionObject', JSON.stringify(sessionObject));
            this.submitted = false;
            this._configSvc.updateAppState(session)

            this._doSuccessfulRedirect(session);
          } else if (resp['producterror'] === true) {
            this.currentError = this.errorMsg['producterror'];
          } else if (resp['zeroproductserror'] === true) {
            this.currentError = this.errorMsg['zeroproductserror'];
          } else if (resp['completed'] === true && resp['status'] === 'error') {
            const errorData = resp && resp['data'] && resp['data']['err'] ? resp['data']['err'] : {};
            const errorType = resp && resp['data'] && resp['data']['type'] ? resp['data']['type'] : {};

            this._handleError(errorData, errorType);
          }
        });
      });
    } else {
      if (session) {
        this._handleError(null, 'incorrect');
      } else {
        this._handleError(null, 'service');
      }
    }
  }

  private _handleError (err?: any, type?: string): void {
    this.submitted = false;

    if (type) {
      this.currentError = this.errorMsg[type];
    }
  }

  private _doSuccessfulRedirect (session: SessionModel) {
    let profile = this.appState.get('current_profile');
    const products = Array.isArray(this.appState.get('grappleProducts')) ? this.appState.get('grappleProducts') : [];
    const allProducts = Array.isArray(this.appState.get('allProducts')) ? this.appState.get('allProducts') : [];

    if (products.length === 1 && (this._redirectLocation && (this._redirectLocation.indexOf('portal') === -1))) {
      profile = products[0]['profile'];
      this.appState.set({ current_profile : profile });
    }

    let path = profile ? profile + '/home' : 'home';
    let params = { replaceUrl: true };

    session['preferences'].forEach((entry) => {
      if (entry['code']  === 'DEFAULT_RESULTS_PER_PAGE') {
        let entryValue = parseInt(entry['value'], 0);
        this.appState.set({ DEFAULT_RESULTS_PER_PAGE: entryValue });
      }
      if (entry['code']  === 'ENABLE_GOOGLE_INTEGRATION') {
        this.appState.set({ ENABLE_GOOGLE_INTEGRATION: entry['value'] });
      }
      if (entry['code']  === 'ENABLE_ONEDRIVE') {
        this.appState.set({ ENABLE_ONEDRIVE: entry['value'] });
      }
    });

    this._sessionSvc.updateSession(session);

    // if redirect path is the portal but user has one product then change it to be that products home page
    if ((products.length === 1 && allProducts.length === 1) && (this._redirectLocation && (this._redirectLocation.indexOf('/portal') !== -1))) {
      this._redirectLocation = path; // set redirect path and keep it similar to the stored values in pam
    }

    if (this._redirectLocation && this.appState.get('current_profile')) {
      path = this._redirectLocation[0] === '/' ? this._redirectLocation.split('?')[0].substr(1) : this._redirectLocation.split('?')[0];

      const pathDataArray =  path.split('/');

      // if the redirect does not have profile in it's url
      if (pathDataArray[0] !== this.appState.get('current_profile') && this.appState.get('displayname')) {
        path = this.appState.get('current_profile') + '/' + path;
      } else if (pathDataArray[0] === this.appState.get('current_profile') && this.appState.get('displayname')) {
        path = this._redirectLocation.split('?')[0].substr(1);
      } else {
        path = 'portal/home';
      }

      params['queryParams'] = this._createParamsObject(this._redirectLocation);
    } else if (((allProducts.length === 0) ||
      (Array.isArray(products) && (products.length  === 0 || products.length >= 2))) && (path.toLowerCase().split('?')[0].indexOf('/portal')) &&
      (products.length !== 1) && !profile) {
      path = 'portal/home';
    }

    const pathData = ['/'].concat(path.split('/')).filter((segment) => {
      return segment !== '';
    });

    this._router.navigate(pathData, params);
  }

  private _createParamsObject (data: string): Object {
    let paramsObject = {};
    let paramsString = data.split('?').length === 2 ? data.split('?')[1] : '';
    let paramsArray = [];

    if (paramsString) {
      paramsArray = paramsString.split('&');

      paramsArray.forEach((param) => {
        let paramDataArray = param.split('=');
        paramsObject[paramDataArray[0]] = paramDataArray[1];
      });
    }

    return paramsObject;
  }
}
