<form (submit)="doSearch(searchForm.value)" #searchForm="ngForm" novalidate class="form-basicSearch"  [ngClass]="sectionClassName">
  <fieldset>
    <div class="input-group">
      <mat-form-field>
        <input type="text" matInput
               [formControl]="ctrl"
               [matAutocomplete]="auto"
               name="query"
               [placeholder]="'Search ' + productName"
               class="form-control"
               [attr.aria-label]="'Search ' + productName">
      </mat-form-field>

      <div class="input-group-btn">
        <button type="submit" aria-label="search button" class="btn btn-default">
            <svg class="gr-search" xmlns="http://www.w3.org/2000/svg">
                <use xlink:href="./assets/img/icons.svg#gr-search"></use>
            </svg>
        </button>
      </div>
    </div>
    <div class="container-advSearchLink">
        <a (click)="pushAdvSearchNavToGA()" [routerLink]="[this.profile ? '/' + this.profile + '/advanced' : '/advanced']">advanced search</a>
    </div>
  </fieldset>

  <div class="sr-only" aria-live="polite">{{ariaMessageTxt}}</div>
  <mat-autocomplete #auto="matAutocomplete" (opened)="handleAutoCompleteOpened()" (closed)="handleAutoCompleteClosed()" >
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (onSelectionChange)="onAcOptionSelected(option)">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
</form>

<div class="container-actionLink" *ngIf="actionLink">
  <a [routerLink]="actionLink.location" [queryParams]="actionLink.urlParams">{{actionLink.label}}</a>
</div>
