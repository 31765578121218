import {
  AfterViewInit,
  Component,
  ElementRef, HostListener,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  Router,
  NavigationEnd,
  NavigationStart,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { Location } from '@angular/common';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { safeSvg4Everybody } from './_utilities';
import { AppState } from './app.service';
import { GoogleAnalyticsService } from './_services/google-analytics/google-analytics.service';
import { FaviconsService } from './_services/favicons/favicons.service';
import { GoogleDriveState, WindowMessageInterface } from './site-header/section';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { PendoService } from './login/user-pass/pendo.service';

declare var Velocity: any;
declare var dataLayer: any;
declare var OneTrust: any;
declare let pendo: any;
/**
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'pq-app',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./_app.component.scss'],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
  public iframeUrl: string = '';
  public displayLtiBanner: boolean = sessionStorage.getItem("id_token") !== null;
  public error: boolean = false;
  public loading: boolean = true;
  public profile: string = '';

  private _lastPoppedUrl: string;
  private _yScrollStack: number[] = [];
  private _enablePortalRedirect: boolean = false;
  private _googleComIframe: HTMLIFrameElement;
  private _googleInitialized: boolean = false;
  public targetCookies = false;
  @ViewChild('iframeGoogleCom', { static: false }) iframeGoogleCom: ElementRef;

  constructor(
    private _router: Router,
    private _location: Location,
    private _appState: AppState,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _faviconSvc: FaviconsService,
    private _renderer: Renderer2,
    private _gtmService: GoogleTagManagerService,
    private pendoService: PendoService
  ) {
    // this.initializePendo();
  }

  public ngOnInit() {
    if (typeof window['dataLayer'] === 'undefined') {
      window['dataLayer'] = undefined;
    }
    this._functionalCookies(window['OnetrustActiveGroups']);
    this._trackingTargetingCookie(window['OnetrustActiveGroups']);

    (async () => {
      while (!window.hasOwnProperty('OneTrust')) // define the condition as you like
        await new Promise(resolve => setTimeout(resolve, 1000));

      OneTrust.OnConsentChanged((e) => {
        this._updateTrackingByConsent(e.detail);
        this._functionalCookies(window['OnetrustActiveGroups'])
        this._trackingTargetingCookie(window['OnetrustActiveGroups'])
      });

    })();

    const cookieEvent = new CustomEvent('cookieChanged', {
      bubbles: true,
      detail: {
        cookieValue: '',
        checkChange: () => {
          if (cookieEvent.detail.cookieValue != document.cookie) {
            cookieEvent.detail.cookieValue = document.cookie;
            return 1;
          } else {
            return 0;
          }
        },
        listenCheckChange: () => {
          setInterval(function () {
            if (cookieEvent.detail.checkChange() == 1) {
              cookieEvent.detail.changed = true;
              //fire the event
              cookieEvent.target.dispatchEvent(cookieEvent);
            } else {
              cookieEvent.detail.changed = false;
            }
          }, 1000);
        },
        changed: false
      }
    });

    if (this._trackingScriptConsent(window['OnetrustActiveGroups'])) {
      this._gtmService.addGtmToDom();
      const gtmScript = document.getElementById('GTMscript');
      if (gtmScript) {
        gtmScript.classList.add('optanon-category-C0002');
      }
    }

    this._faviconSvc.reset();
    this._appState.set({ enablePortalRedirect: this._enablePortalRedirect });

    if (this._appState.get('externalDriveInfo') && this._appState.get('externalDriveInfo')['baseUrl']) {
      this.iframeUrl = this._createIframeUrl();
    }

    this._location.subscribe((routeEvent: any) => {
      this._lastPoppedUrl = routeEvent.url;
    });

    this._router.events.subscribe((routeEvent: any) => {
      const accountId = Cookie.get('accountId');

      this.error = false;

      if (routeEvent instanceof NavigationStart) {
        this.loading = true;

        if (routeEvent.url !== this._lastPoppedUrl) {
          this._yScrollStack.push(window.scrollY);
        }
      } else if (routeEvent instanceof NavigationEnd) {
        this.loading = false;

        if (routeEvent.url === this._lastPoppedUrl) {
          if (typeof localStorage.getItem('scrollY') === 'undefined') {
            this._lastPoppedUrl = undefined;
            Velocity(document.getElementsByTagName('body')[0], 'scroll', { offset: this._yScrollStack[0], mobileHA: false });
          }
        } else if (location['hash'] && document.getElementById(location['hash'].substr(1))) {
          Velocity(document.getElementById(location['hash'].substr(1)), 'scroll', { offset: '0', mobileHA: false });
          document.getElementById(location['hash'].substr(1)).focus();
        } else {
          Velocity(document.getElementsByTagName('body')[0], 'scroll', { offset: '0', mobileHA: false });
          document.getElementById('page').focus();
        }
        if (accountId) {
          this._updateUrlWithAccountId(accountId);
        }

      } else if (routeEvent instanceof NavigationCancel || routeEvent instanceof NavigationError) {
        this.loading = false;
      }

      if (routeEvent instanceof NavigationError) {
        this.error = true;
      }
    });

    this._appState.stateStatus.subscribe((state) => {
      if (typeof state['gaPageTitle'] !== 'undefined' && state['gaPageTitle'] !== '') {
        this._trackPageViewInGA(document.location['pathname'], state['gaPageTitle']);
        this._appState.set({ gaPageTitle: '' });
      }

      if (this._appState.get('externalDriveInfo') && this._appState.get('externalDriveInfo')['baseUrl'] && !this.iframeUrl) {
        this.iframeUrl = this._createIframeUrl();
      }

      if (typeof state['google'] !== 'undefined' && state['google']['isLoaded'] && !this._googleInitialized) {
        this._initGoogleIntegration();
      } else if (typeof state['google'] !== 'undefined' && this._googleInitialized) {
        this._sendIframeWindowMessage({ google: state['google'] } as WindowMessageInterface);
      }

      if (state['current_profile'] && this.profile !== state['current_profile']) {
        // use setTimeout function to prevent Angular "ExpressionChangedAfterItHasBeenCheckedError" error when updating the app profile
        setTimeout(() => {
          this._handleProfileStyles();
        });
      }

    });
    safeSvg4Everybody();
  }

  public ngAfterViewInit() {
    this._initGoogleIntegration();
  }

  public handleContentLink(e: Event) {
    e.preventDefault();
    Velocity(document.getElementById('content'), 'scroll', { offset: '0', mobileHA: false });
    document.getElementById('content').focus();
  }

  @HostListener('window:message', ['$event'])
  public onWindowMessage(e: any): void {
    const data = e.data ? e.data as WindowMessageInterface : {} as WindowMessageInterface;

    if (data.sender === 'grapple') {
      delete data.sender;
      this._appState.set(data);
    }

  }

  private _updateTrackingByConsent(consentState: any): void {
    const gtmScript = document.getElementById('GTMscript');
    const gtmIframe = document.getElementById('GTMiframe');
    const gaScript = this._getGAScript();
    // if the user revolts permission after giving permission
    if (gtmScript && !this._trackingScriptConsent(consentState)) {
      gtmScript.remove();
      gtmIframe.remove();
      if (gaScript) {
        gaScript.remove();
      }

      // if the user grants permission after not giving permission
    } else if (!gtmScript && this._trackingScriptConsent(consentState)) {
      this._gtmService.addGtmToDom();
    }
  }

  private _handleProfileStyles(): void {
    this.profile = this._appState.get('current_profile') ? this._appState.get('current_profile') : '';

    if (!this.profile) {
      if (Array.isArray(this._appState.get('grappleProducts')) && this._appState.get('grappleProducts').length === 1) {
        this.profile = this._appState.get('grappleProducts')[0]['profile'];
      }
    } else if (this.profile && this._isValidProfile(this.profile)) {
      this._appState.set({ current_profile: this.profile.toLowerCase() });
      this._useFavicon(this.profile);
    }
    // remove class from body to prevent multiple theme classes being attached to the body
    this._renderer.setAttribute(document.body, 'class', null);

    this._setTheme(this.profile);
  }

  private _initGoogleIntegration(): void {
    this._googleComIframe = this.iframeGoogleCom ? this.iframeGoogleCom.nativeElement : undefined;
    const clientId = this._appState.get('externalDriveInfo') && this._appState.get('externalDriveInfo')['googleDriveInfo'] && this._appState.get('externalDriveInfo')['googleDriveInfo']['clientId'] ? this._appState.get('externalDriveInfo')['googleDriveInfo']['clientId'] : undefined;
    const data: WindowMessageInterface = { sender: 'grapple', google: { clientId } as GoogleDriveState } as WindowMessageInterface;
    const googleStatus: GoogleDriveState = this._appState.get('google') || {};

    if (this._googleComIframe && clientId && googleStatus.isLoaded && !this._googleInitialized) {
      this._googleInitialized = true;
      this._sendIframeWindowMessage(data)
    }
  }

  private _sendIframeWindowMessage(data: WindowMessageInterface) {
    if (data.sender === undefined) {
      data.sender = 'grapple';
    }

    if (this._googleComIframe) {
      this._googleComIframe['contentWindow'].postMessage(data, '*');

      this._googleComIframe.onload = (ele) => {
        this._googleComIframe['contentWindow'].postMessage(data, '*');
      }
    }
  }

  private _trackPageViewInGA(page: string, title: string) {
    if (page && page !== 'undefined' && !page.startsWith('/document')) {
      try {
        this._googleAnalyticsService.sendPageViewToGA(page, '', title);
      } catch (err) { }
    }
  }

  private _updateUrlWithAccountId(accountId: string) {
    const urlData = this._location.path().split('?');
    const accountIdParam = 'accountid=' + accountId;
    let path = urlData[0] || '';
    let query = location['search'] ? location['search'].toLowerCase() : '';

    if (query && query.indexOf('accountid') === -1) {
      query += '&' + accountIdParam;
    } else if (!query) {
      query = '?' + accountIdParam;
    }

    if (location['hash'] && location['hash'].substring(1) !== 'undefined') {
      query += location['hash']
    }

    this._location.replaceState(path, query);
  }

  private _setTheme(profile: string): void {
    let classNameValue = 'theme-default';

    // set default theme if there is no profile or the profile is elibrary
    // display name is used to determine if the profile is valid
    if (profile && profile.toLowerCase() !== 'elibrary' && this._appState.get('displayname')) {
      classNameValue = 'theme-' + profile.toLowerCase();
    }

    if (document.body.className) {
      document.body.className = '';
    }

    this._renderer.addClass(document.body, classNameValue);
  }

  private _useFavicon(name: string): void {
    // nested to allow the theme to be set even if there is no favicon for the profile provided
    try {
      if (name && typeof name === 'string') {
        this._faviconSvc.activate(name);
      }
    } catch (e) { }
  }

  private _isValidProfile(requestedProfile: string) {
    let allProducts = this._appState.get('grappleProducts') || [];
    if (!Array.isArray(allProducts)) {
      allProducts = [allProducts];
    }

    return allProducts.filter((product) => {
      return product && product['profile'] === requestedProfile;
    }).length >= 1;
  }

  private _createIframeUrl(): string {
    // return absolute path for iframe in order to get around Google white list for parent window urls using proxy
    return window.location.hostname.toLowerCase() === 'localhost'
      ? 'http://localhost:3000/static/googlecom.html'
      : this._appState.get('externalDriveInfo')['baseUrl'] + '/static/googlecom.html';
    //return this._appState.get('externalDriveInfo')['baseUrl'] + '/static/googlecom.html';
  }

  /* Function to use cookie values to determine whether to load tracking script or not */
  private _trackingScriptConsent(state): boolean {
    return state && state.toString().indexOf('C0002') !== -1;
  }
  _trackingTargetingCookie(state): void {
    this.pendoService.targetingCookiesEnabledOrDisabled(state && state.toString().indexOf('C0004') !== -1);
  }

  _functionalCookies(state): void {
    const functionalCookieEnabled = state && state.toString().indexOf('C0003') !== -1;
    this.pendoService.functiongCookiesEnabledOrDisabled(functionalCookieEnabled)
  }


  private _getGAScript(): HTMLElement {
    const gaSource = 'https://www.google-analytics.com/analytics.js';
    const theHead = document.getElementsByTagName('head')[0];
    const scripts = theHead.getElementsByTagName('script');
    let ga = undefined;

    for (let i = 0, total = scripts.length; i < total; i++) {
      let currentScript = scripts[i];
      if (currentScript.src === gaSource) {
        ga = currentScript;
      }
    }

    return ga;
  }

  private loadOTBanner() {

    let otConsentSdk = document.getElementById("onetrust-consent-sdk");
    if (otConsentSdk) {
      otConsentSdk.remove();
    }

    if (OneTrust != null) {
      OneTrust.Init();

      setTimeout(function () {
        OneTrust.LoadBanner();

        var toggleDisplay = document.getElementsByClassName(
          "ot-sdk-show-settings"
        );

        for (var i = 0; i < toggleDisplay.length; i++) {
          toggleDisplay[i].addEventListener('click', (e) => {
            e.stopImmediatePropagation();
            OneTrust.ToggleInfoDisplay();
          });
        }
      }, 1000);
    }
  }
}
