import { NgModule } from '@angular/core';

import { ModalService } from './modal.service';
import { ModalPlaceholderComponent } from './modal.component';

@NgModule({
  declarations: [ModalPlaceholderComponent],
  exports: [ModalPlaceholderComponent],
  providers: [ModalService]
})
export class ModalModule {
}
