import moment from 'moment';

export default class DateFormat {
  public static validate (value: string): boolean {
    const isNum = /^\d+$/.test(value);
    let isValid = false;
    let valueLen = value ? value.length : 0;

    if (valueLen === 8 && isNum) {
      isValid = true;
    }

    return isValid;
  }

  public static inputValid (value: string): boolean {
    const valueLen = value ? value.length : 0;
    let isValid = value && (valueLen === 10) && (value.split('/').length === 3);
    let valueArray = [];

    if (valueLen >= 4 && valueLen <= 10) {

      for (let separator of this.supportedSeparators) {
        if (value.indexOf(separator) !== -1) {
          let data = value.split(separator);
          valueArray = valueArray.concat(data);
        }
      }

      let valueArrayLen = valueArray.length;

      // input uses separator (.i.e., 01/20004, 5/2005, 06-12-2017)
      if (valueArrayLen > 1 && valueArrayLen <= 3) {
        if ((valueArrayLen === 2 && valueArray[valueArrayLen - 1].length === 4) ||
          (valueArrayLen === 3 && valueArray[valueArrayLen - 1].length === 4)) {
          isValid = true;
        }
      } else {
        // input does not use separator (i.e., 2004 or 01012017)
        if (value.length === 4 || value.length === 8) {
          isValid = true;
        }
      }

    }

    return isValid;
  }

  // converts date string to YYYYMMDD format
  public static format (value: any): string {
    let date = '';
    let valueLen = value ? value.length : 0;
    let valueArray = [];

    if (valueLen <= 10) {
      for (let separator of this.supportedSeparators) {
        if (value.indexOf(separator) !== -1) {
          let data = value.split(separator);
          valueArray = valueArray.concat(data);
        }
      }

      if (valueArray.length !== 0) {
        if (valueArray.length === 3) {
          date = valueArray[2] + this.updateNumberFormat(valueArray[0]) + this.updateNumberFormat(valueArray[1]);
        } else {
          date = valueArray[1] + this.updateNumberFormat(valueArray[0]) + '01';
        }
      } else {
        if (valueLen === 4) {
          date = value + '0101';
        } else if (valueLen === 8) {
          date = value.substr(4) + value.substr(0, 4);
        }
      }
    }

    return date;
  }

  // converts string to MM/DD/YYYY
  public static displayFormat (value: string): string {
    let date = '';
    let valueLen = value ? value.length : 0;
    let valueArray = [];

    if (valueLen <= 10) {
      for (let separator of this.supportedSeparators) {
        if (value.indexOf(separator) !== -1) {
          let data = value.split(separator);
          valueArray = valueArray.concat(data);
        }
      }

      if (valueArray.length !== 0) {
        if (valueArray.length === 3) {
          date = this.updateNumberFormat(valueArray[0]) + '/' + this.updateNumberFormat(valueArray[1]) + '/' + valueArray[2];
        } else {
          date = this.updateNumberFormat(valueArray[0]) + '/01/' + valueArray[1];
        }
      } else {
        if (valueLen === 4) {
          date = '01/01/' + value;
        } else if (valueLen === 8) {
          date = value.substr(0, 2) + '/' + value.substr(2, 2) + '/' + value.substr(4, 4);
        }
      }
    }

    return date;
  }

  public static updateNumberFormat (value: string): string {

    if (value.length === 1) {
      value =  '0' + value;
    }

    return value;
  }

  public static convertDateToString (value: any): string {
    const format = 'MM/DD/YYYY';
    let dateString = value || '';

    // is it a Date Object
    if (typeof dateString['getMonth'] === 'function') {
      dateString = moment(dateString).format(format);
    }

    return dateString;
  }

  public static convertToDateObject (value: string): Object {
    return moment(value);
  }

  private static supportedSeparators: string[] = ['.', '/', '-'];
}
