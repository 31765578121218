import { Component, Input, OnChanges } from '@angular/core';

declare var dataLayer: any;

@Component({
  selector: 'pq-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./_loading.component.scss']
})
export class LoadingComponent implements OnChanges {

  @Input() public loading: boolean = false;
  @Input() public error: boolean = false;
  @Input() public success: boolean = false;
  @Input() public msg: string = `There was a problem with your search. Please check the address and try again.`;
  @Input() public loadingMsg: string = 'Loading...';
  @Input () public reopenGoogleorMicro: boolean = false;

  public ngOnChanges (): void {
    if (this.error && dataLayer && Array.isArray(dataLayer)) {
      dataLayer.push({'event': 'ErrorEvent', 'label': this.msg});
    }
  }
}
