import { Injectable } from '@angular/core';
import { AppState } from '../app.service';

/**
 * Gives values that ought to have been derived from product-ui-profile, but require some sort of logic.
 */
@Injectable()
export class ProductConfig {

  constructor (
    public appState: AppState
  ) {}

  public getHeaderIcon(): string {
    const icons: object = {
      elibrary: 'gr-elibrary',
      sirsissuesresearcher: 'gr-researcher',
      sirsdiscoverer: 'gr-discoverer'
    };
    const profile = this.appState.get('current_profile');
    if (profile && icons[profile]) {
      return icons[profile];
    }
    return '';
  }

}
