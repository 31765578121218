import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ProductService {
  public document: object;
  private _url: string = '/svc/grapple/product/';

  constructor (private _http: HttpClient) {
  }

  public get (profile: string): Observable<any> {
    const url = this._url + profile.toLowerCase();
    return this._http.get(url + '?proxyHostPort=' + location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : ''));
  }

  public async getLimitedInfo (profile: string): Promise<any> {
    const url = '/svc/grapple/monikerinfo/' + profile.toLowerCase();

    return await firstValueFrom(this._http.get(url).pipe(
      map((res) => {

        // there is a real problem
        if (!res['MonikerResponse']) {
          throw new Error('Service error');
        // there is no matching ui profile
        } else  if (res['MonikerResponse'] && res['MonikerResponse']['valid'] === 'false') {
          return res;
        } else {
          let productInfo = (res['MonikerResponse'] && res['MonikerResponse']['productinfo'] && JSON.parse(res['MonikerResponse']['productinfo'])['ProductInfo'])  ? JSON.parse(res['MonikerResponse']['productinfo'])['ProductInfo']['MetaProduct'] : {};
          let profile = (res['MonikerResponse'] && res['MonikerResponse']['uiprofile']) ? JSON.parse(res['MonikerResponse']['uiprofile'])['profile'] : [];

          if (typeof productInfo === 'undefined') {
            productInfo = {};
          }

          if (typeof profile === 'undefined') {
            profile = [];
          }

          res = {

            ProductInfo: {
              moniker: productInfo['Moniker'],
              uiprofile: productInfo['Moniker'],
              displayname: productInfo['Name'],
              helpURL: profile && profile.length ? profile[0]['helpURL'] : '',
              trialURL: profile && profile.length ? profile[0]['trialURL']: ''
            }
          };
        }

        // map the value from the service to match that of the product request end point
        return res;

      })
    ));
  }

  public async getAllProducts (): Promise<Object> {
    const products = this._http.get('/svc/grapple/allproducts');
    return await firstValueFrom(products)
  }

  public getProductUrl (profile: string, platform: string): Observable<object> {
    return this._http.get('/svc/grapple/getproducturl?profile=' + encodeURIComponent(profile.toLowerCase()) + '&platform=' + encodeURIComponent(platform.toLowerCase()));
  }
}
