<!-- using hidden attribute instead of ngIf because of a display issue with Angular Material tooltips caused by the
element not being available when the library is loaded -->
<div class="container-googleAccount" [hidden]="!isAvailable">
  <button
    [ngClass]="{ 'loggedIn': logInStatus.getValue() }"
    [matTooltip]="tooltipText.getValue()"
    type="button"
    class="btn"
    (click)="toggleGoogleAccountLink($event)" name="linkGoogleButton" [disabled]="loading">

      <img src="./assets/img/google-icon.svg" alt="Google" title="Google">
      <span > {{ btnText.getValue() }}</span>
  </button>
</div>
