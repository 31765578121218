<div
  class="container-login container-loginUser"
  [ngClass]="{ noProduct: !getProductName() }"
>
  <div>
    <h1
      *ngIf="getProductName()"
      [innerHTML]="'Log in to ProQuest ' + getProductName()"
    ></h1>
    <h1 *ngIf="!getProductName()">Log in to ProQuest</h1>

    <form
      class="form-signin"
      #f="ngForm"
      (ngSubmit)="onSubmitLogin(f.value, f.valid)"
    >
      <div class="alert alert-danger" role="alert" *ngIf="message != ''">
        <b [innerHTML]="boldMessage"></b>
        <span [innerHTML]="message"></span>
      </div>

      <div class="form-group">
        <label for="inputUsername">Username</label>
        <input
          type="text"
          id="inputUsername"
          class="form-control"
          placeholder="Username"
          [ngModel]="model.user"
          name="user"
          required
          autofocus
          #user="ngModel"
          [disabled]="submitted"
        />
      </div>
      <div class="form-group">
        <label for="inputPassword">Password</label>
        <input
          type="password"
          id="inputPassword"
          class="form-control"
          placeholder="Password"
          [ngModel]="model.password"
          name="password"
          required
          #password="ngModel"
          [disabled]="submitted"
        />
      </div>

      <div class="form-group container-actions">
        <div>
          <button
            type="button"
            class="btn btn-link ga-manuallogin-forgot"
            (click)="toggleRecoverAccountInfo($event)"
          >
            Forgot your username or password?
          </button>
        </div>
        <div>
          <button
            class="btn btn-lg btn-primary"
            type="submit"
            (mousedown)="pushLoginClickToGA($event)"
            [disabled]="submitted"
            [ngSwitch]="submitted"
          >
            <span *ngSwitchCase="true">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="20px"
                height="20px"
                viewBox="0 0 40 40"
                enable-background="new 0 0 40 40"
                xml:space="preserve"
              >
                <path
                  opacity="0.2"
                  fill="#fff"
                  d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                  s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                  c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                />
                <path
                  fill="#ccc"
                  d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                  C22.32,8.481,24.301,9.057,26.013,10.047z"
                >
                  <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 20 20"
                    to="360 20 20"
                    dur="0.5s"
                    repeatCount="indefinite"
                  />
                </path>
              </svg>

              working...
            </span>
            <span *ngSwitchCase="false">Log In</span>
          </button>
        </div>
      </div>
      <div class="container-forgotInfoAction">
        <div
          class="alert alert-warning"
          role="alert"
          aria-live="assertive"
          *ngIf="isVisibleForgotInfo"
        >
          Please contact your administrator for help recovering your username or
          password.
        </div>
      </div>
    </form>
  </div>
  <nav>
    <h2>more login options</h2>
    <ul>
      <li>
        <button type="button" (click)="handleClickToGoogleLogin()">
          <img src="/assets/img/google-icon.svg" alt="" />
          <span
            >Log in with Google<br /><span
              >You must use your school Google account</span
            ></span
          >
        </button>
      </li> 
      <li>
        <a
          (mousedown)="pushCleverLoginClickToGA($event)"
          [routerLink]="
            profileVal
              ? ['/', profileVal, 'login', 'cleverauth']
              : ['/', 'login', 'cleverauth']
          "
        >
          <img src="/assets/img/clever-logo.jpeg" alt="" />
          <span>Log in with Clever</span>
        </a>
      </li>
      <li>
        <button type="button" (click)="handleClickToDayFindInstitution()">
          <img src="/assets/img/institution-icon.svg" alt="" />
          <span>Find your school or institution</span>
        </button>
      </li>
      <!--<li>
        <a (mousedown)="pushGoogleLoginClickToGA($event)" [routerLink]="profileVal ? ['/', profileVal, 'login', 'googleauth'] : ['/', 'login', 'googleauth']">
          <img src="/assets/img/google-icon.svg" alt="">
          <span>Log in with Google<br><span>You must use your school Google account</span></span>
        </a>
      </li>-->
    </ul>
  </nav>
</div>
