import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MicrosoftAuthService } from '../../../_services';
import { AppState } from '../../../app.service';

declare var dataLayer: any;

@Component({
  selector: 'pq-ms-btn',
  templateUrl: './microsoft-btn.component.html',
  styleUrls: ['./_microsoft-btn.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MicrosoftGraphBtnComponent implements OnInit {
  public isAvailable:boolean = false;
  public loading: boolean = false;
  public tooltipText: string;
  public isLoggedIn: boolean = false;
  private _initializeLoad: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _statusText: object = {
    logged_in: 'Log Out',
    logged_out: 'Log In',
    default_tooltip: 'Log in with Microsoft',
    signed_in_tooltip_pre: 'Log out '
  };

  private _username: string;
  private _btnText: BehaviorSubject<string> = new BehaviorSubject<string>(this._statusText['logged_out']);

  constructor (
    private _msGraphSvc: MicrosoftAuthService,
    private _appState: AppState
  ) {}

  public ngOnInit (): void {
    this.isLoggedIn = this._msGraphSvc.isLoggedIn();
    this._appState.set({ msapi: this.isLoggedIn, msAuthSignedIn: this.isLoggedIn });
    this.tooltipText = this.isLoggedIn ? this._statusText['signed_in_tooltip_pre'] + this._username :  this._statusText['default_tooltip'];

    if (this._appState.get('externalDriveInfo')
      && this._appState.get('externalDriveInfo')['oneDriveInfo']
      && this._appState.get('externalDriveInfo')['oneDriveInfo']['clientId']) {
      this._initializeLoad.next(true);
    }

    this._appState.stateStatus.subscribe((state: Object) => {
      if (state['requestMsLogin'] === true) {
        this._appState.set({ requestMsLogin: false });
        this._msGraphSvc.login().then(this._handleLoginSuccess.bind(this), this._handleLoginFailure.bind(this));
      }

      if (state['externalDriveInfo']
        && state['externalDriveInfo']['oneDriveInfo']
        && state['externalDriveInfo']['oneDriveInfo']['clientId']
        && this._initializeLoad.getValue() === false) {

        this._initializeLoad.next(true);
      }
    });

    this._initializeLoad.subscribe((status) => {
      if (status === true) {
        this.initLoad();
      }
    });
  }

  public initLoad (): void {
    this._msGraphSvc.initMsAuth().subscribe((status: boolean) => {
      this.isAvailable = status;
      this._appState.set({ msapi: status });
      if (status && this._msGraphSvc.isLoggedIn()) {
        this.isLoggedIn = this._msGraphSvc.isLoggedIn();
        this._appState.set({ msAuthSignedIn: true });
        this._btnText.next(this._statusText['logged_in']);
        this._msGraphSvc.getUserInfo().then(
          this._handleUserInfoCall.bind(this), this._handleUserInfoCall.bind(this)
        ).then(
          this._msGraphSvc.setUserDriveInfo.bind(this._msGraphSvc), this._msGraphSvc.setUserDriveInfo.bind(this._msGraphSvc)
        );
      }
    });
  }

  public getButtonTxt (): BehaviorSubject<string> {
    return this._btnText;
  }

  public toggleMicrosoftAccountLink(e: Event): void {
    let isLoggedIn = this._msGraphSvc.isLoggedIn();

    if (!this.loading) {
      this.loading = true;

      if (!isLoggedIn) {
        if (dataLayer && Array.isArray(dataLayer)) {
          dataLayer.push({'event': 'GlobalEvent', 'category': 'Microsoft Account', 'action': 'Microsoft Login', 'label': 'Login Attempt'});
        }

        this._appState.set({isMsFromHeaderLink: true, isMsFromSaveToCloudOverlay: false});
        // when the user manually closes/blocks the pop up it causes a cancel even which causes a
        // unresolved promise Angular error. This is because the hellojs does not resolve the promise
        // in a way that causes the Angular error. The error does not affect the app or is noticeable to the end user.
        this._msGraphSvc.login()
          .then(this._handleLoginSuccess.bind(this), this._handleLoginFailure.bind(this));

      } else {
        if (dataLayer && Array.isArray(dataLayer)) {
          dataLayer.push({'event': 'GlobalEvent', 'category': 'Microsoft Account', 'action': 'Microsoft Login', 'label': 'Logout'});
        }

        this._msGraphSvc.logout().then(this._handleLogout.bind(this));
        this._username = undefined;
      }
    }
  }

  @HostListener('window:focus', [])
  public onWindowFocus (): void {
    let isLoggedIn = this._msGraphSvc.isLoggedIn();
    this.isLoggedIn = isLoggedIn;
    if (isLoggedIn) {
      this._handleLoginSuccess.bind(this);
    } else {
      this._handleLogout();
    }
  }

  private _handleUserInfoCall (resp: Object): void {
    this.loading = false;
    this._btnText.next(this._statusText['logged_in']);
    this._username = this._getUserFullName(resp) || resp['givenName'] || resp['surname'] || resp['userPrincipalName'] || '';
    this.tooltipText = this._statusText['signed_in_tooltip_pre'] + this._username;
    this._appState.set({ ms_user_data: resp });
  }

  private _getUserFullName(resp: Object): String {
    return resp['displayName'] ? resp['displayName'] : resp['givenName'] && resp['surname'] ? `${resp['givenName']} ${resp['surname']}` : undefined;
  }

  private _handleLogout (): void {
    this._btnText.next(this._statusText['logged_out']);
    this.loading = false;
    this._username = undefined;
    this.isLoggedIn = false;
    this._appState.set({ msAuthSignedIn: false });
    this.tooltipText = this._statusText['default_tooltip']
  }

  private _handleLoginSuccess (): void {
    this.isLoggedIn = true;
    this._msGraphSvc.getUserInfo().then(
      this._handleUserInfoCall.bind(this), this._handleUserInfoCall.bind(this)
    ).then(
      this._msGraphSvc.setUserDriveInfo.bind(this._msGraphSvc), this._msGraphSvc.setUserDriveInfo.bind(this._msGraphSvc)
    );

    if (dataLayer && Array.isArray(dataLayer)) {
      if (this._appState.get('isMsFromSaveToCloudOverlay') === true) {
        dataLayer.push({'event': 'SaveToCloudOverlay', 'label': 'Microsoft OneDrive', 'action': 'MS Login'});
      } else if (this._appState.get('isMsFromHeaderLink') === true) {
        dataLayer.push({'event': 'GlobalEvent', 'category': 'Microsoft Account', 'action': 'Microsoft Login', 'label': 'Login'});
      }
    }

    this._appState.set({isMsFromHeaderLink: false, isMsFromSaveToCloudOverlay: false});
    this._appState.set({ msAuthSignedIn: true });
  }

  private _handleLoginFailure (err: object): void {

    this.loading = false;
    this._btnText.next(this._statusText['logged_out']);
    this._appState.set({ msAuthSignedIn: false });
    this._appState.set({isMsFromHeaderLink: false, isMsFromSaveToCloudOverlay: false});
  }
}
