import { Component, OnInit } from '@angular/core';
import { AppState } from '../app.service';
import { SeoService } from '../_services/seo/seo.service';

@Component({
  selector: 'pq-no-content',
  templateUrl: './no.content.component.html',
  styleUrls: ['./_no.content.component.scss'],
})
export class NoContentComponent implements OnInit {
  public title: string = "Page Not Found";

  constructor (
    public appState: AppState,
    private _seoSvc: SeoService
  ) {
    this._seoSvc.setTitle(this.title);

  }

  public ngOnInit (): void {}
}

