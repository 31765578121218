import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { animate, keyframes, style, trigger, transition } from '@angular/animations';

import { Calendar } from './calendar';
import DateFormat from '../../../_utilities/date.format';

@Component({
  selector: 'pq-datepicker',
  animations: [
    trigger('calendarAnimation', [
      transition('* => left', [
        animate(180, keyframes([
          style({ transform: 'translateX(105%)', offset: 0.5 }),
          style({ transform: 'translateX(-130%)', offset: 0.51 }),
          style({ transform: 'translateX(0)', offset: 1 })
        ]))
      ]),
      transition('* => right', [
        animate(180, keyframes([
          style({ transform: 'translateX(-105%)', offset: 0.5 }),
          style({ transform: 'translateX(130%)', offset: 0.51 }),
          style({ transform: 'translateX(0)', offset: 1 })
        ]))
      ])
    ])
  ],
  styleUrls: ['./_datepicker.component.scss'],
  templateUrl: './datepicker.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DatepickerComponent implements OnChanges {
  // api bindings
  @Input() public disabled: boolean;
  @Input() public accentColor: string;
  @Input() public altInputStyle: boolean;
  @Input() public dateFormat: string = 'YYYY/MM/DD';
  @Input() public fontFamily: string;
  @Input() public rangeStart: string = '';
  @Input() public rangeEnd: string = '';
  // data
  @Input() public placeholder: string = 'Select a date';
  @Input() public inputText: string;
  @Input() public inputName: string = 'date';
  @Input() public inputLabel: string;
  @Input() public dateType: string;

  // view logic
  @Input() public showCalendar: boolean;
  @Input() public inputStyle: string = 'block';
  @Input() public hasError: boolean = false;

  // events
  @Output() public onChange: EventEmitter<any> = new EventEmitter<any>();

  // time
  @Input() public calendarDays: number[];
  @Input() public currentMonth: string;
  @Input() public dayNames: string[];
  @Input() public hoveredDay: Date;

  public calendar: Calendar;
  public currentMonthNumber: number;
  public currentYear: number;
  public months: string[];
  // animation
  public animate: string;
  // colors
  public colors: { [ id: string ]: string };
  // listeners
  public clickListener: Function;
  public disablePrevMonthSelection: boolean = false;
  public disableNextMonthSelection: boolean = false;

  private _date: Date;
  private _timer: number;
  private _timeLimit: number = 400;

  constructor (private renderer: Renderer2, private elementRef: ElementRef) {
    // view logic
    this.showCalendar = false;
    // colors
    this.colors = {
      black: '#333333',
      blue: '#1285bf',
      lightGrey: '#f1f1f1',
      white: '#ffffff'
    };
    this.accentColor = this.colors['blue'];
    this.altInputStyle = false;
    // time
    this.calendar = new Calendar();
    this.dayNames = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    this.months = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July',
      'August', 'September', 'October', 'November', ' December'
    ];
    // listeners
    this.clickListener = renderer.listen('document', 'click', (event: MouseEvent) => this.handleGlobalClick(event));
  }

  // State Management
  // ------------------------------------------------------------------------------------
  public closeCalendar (): void {
    this.showCalendar = false;
    window.clearTimeout(this._timer);
  }

  public setDate (): void {
    this._date = this._date || new Date();
    this.setInputText(this._date);
    this.setCurrentValues(this._date);
  }

  public setCurrentMonth (monthNumber: number) {
    this.currentMonth = this.months[monthNumber];
    const calendarArray = this.calendar.monthDays(this.currentYear, this.currentMonthNumber);
    this.calendarDays = [].concat.apply([], calendarArray);
  }

  public setHoveredDay (day: Date): void {
    this.hoveredDay = day;
  }

  public removeHoveredDay (day: Date): void {
    this.hoveredDay = null;
  }

  public setInputText (date: Date): void {
    let inputText: string = '';

    if (typeof date['getMonth'] === 'function') {
      let month: string = (date.getMonth() + 1).toString();
      if (month.length < 2) {
        month = `0${month}`;
      }
      let day: string = (date.getDate()).toString();
      if (day.length < 2) {
        day = `0${day}`;
      }

      switch (this.dateFormat.toUpperCase()) {
        case 'YYYY/MM/DD':
          inputText = `${date.getFullYear()}/${month}/${day}`;
          break;
        case 'MM/DD/YYYY':
          inputText = `${month}/${day}/${date.getFullYear()}`;
          break;
        case 'DD/MM/YYYY':
          inputText = `${day}/${month}/${date.getFullYear()}`;
          break;
        default:
          inputText = `${date.getFullYear()}/${month}/${day}`;
          break;
      }
    }

    this.inputText = inputText;
  }

  // Input Handlers
  // ------------------------------------------------------------------------------------
  public updateInputText (val: string) {
    if (DateFormat.inputValid(val)) {
      let date = DateFormat.displayFormat(val);
      let dateObject = new Date(date);

      if (dateObject.toDateString() !== 'Invalid Date' && dateObject.getFullYear() >= 1970) {
        this._date = dateObject;
        this.onChange.next(dateObject);
      }
    }
  }

  // Click Handlers
  // ------------------------------------------------------------------------------------
  public onArrowLeftClick (): void {
    const currentMonth: number = this.currentMonthNumber;
    let newYear: number = this.currentYear;
    let newMonth: number;

    if (currentMonth === 0) {
      newYear = this.currentYear - 1;
      newMonth = 11;
    } else {
      newMonth = currentMonth - 1;
    }

    window.clearTimeout(this._timer);

    if (this.canMoveToPrevMonth()) {
      this.currentYear = newYear;
      this.currentMonthNumber = newMonth;
      this.setCurrentMonth(newMonth);
      this.triggerAnimation('left');
    }
  }

  public onArrowRightClick (): void {
    const currentMonth: number = this.currentMonthNumber;
    let newYear: number = this.currentYear;
    let newMonth: number;

    window.clearTimeout(this._timer);

    if (currentMonth === 11) {
      newYear = this.currentYear + 1;
      newMonth = 0;
    } else {
      newMonth = currentMonth + 1;
    }

    if (this.canMoveToNextMonth()) {
      this.currentYear = newYear;
      this.currentMonthNumber = newMonth;
      this.setCurrentMonth(newMonth);
      this.triggerAnimation('right');
    }
  }

  public onCancel (): void {
    this.closeCalendar();
  }

  public onInputClick (): void {
    if (this.showCalendar) {
      this.showCalendar = false;
    } else {
      this.showCalendar = true;
    }
  }

  public onSelectDay (day: Date): void {
    // setting the date based on the calendar
    this._date = day;
    this.onChange.next(this._date);
    this.showCalendar = false;
  }

  // Listeners
  // ------------------------------------------------------------------------------------
  public handleGlobalClick (event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.closeCalendar();
    }
  }

  public handleInputBlur (e: Event) {
     this._timer = window.setTimeout(() => {
      this.closeCalendar();
    }, this._timeLimit);
  }

  public handleInputClick (e: Event) {
    if (this.showCalendar) {
      this.showCalendar = false;
    } else {
      this.showCalendar = true;
    }
  }

  // Helpers
  // ------------------------------------------------------------------------------------
  public getDayBackgroundColor (day: Date): string {
    let color = this.colors['white'];
    if (this.isChosenDay(day)) {
      color = this.accentColor;
    } else if (this.isCurrentDay(day)) {
      color = this.colors['lightGrey'];
    }
    return color;
  }

  public getDayFontColor (day: Date): string {
    let color = this.colors['black'];
    if (this.isChosenDay(day)) {
      color = this.colors['white'];
    }
    return color;
  }

  public isChosenDay (day: Date): boolean {
    let isDay = false;

    if ((day && (typeof day.toDateString === 'function')) && (this._date && (typeof this._date.toDateString === 'function'))) {
      isDay = day.toDateString() === this._date.toDateString();
    }

    return isDay;
  }

  public isCurrentDay (day: Date): boolean {
    let isDay = day && (typeof day.toDateString === 'function') && (day.toDateString() === new Date().toDateString());

    return isDay;
  }

  public isHoveredDay (day: Date): boolean {
    return this.hoveredDay ? this.hoveredDay === day && !this.isChosenDay(day) : false;
  }

  public triggerAnimation (direction: string): void {
    this.animate = direction;
    setTimeout(() => this.animate = 'reset', 185);
  }

  public canMoveToNextMonth (): boolean {
    const rangeEnd: string = this.rangeEnd ? DateFormat.convertDateToString(this.rangeEnd) : '';
    const rangeEndDate = DateFormat.convertToDateObject(rangeEnd);
    const currentMonth: number = this.currentMonthNumber;
    let newYear: number = this.currentYear;
    let newMonth: number;

    if (currentMonth === 11) {
      newYear = this.currentYear + 1;
      newMonth = 0;
    } else {
      newMonth = currentMonth + 1;
    }

    const newDate = new Date(newYear, newMonth);

    return (!rangeEnd || newDate.getTime() <= rangeEndDate.valueOf())
  }

  public canMoveToPrevMonth ():  boolean {
    const rangeStart: string = this.rangeStart ? DateFormat.convertDateToString(this.rangeStart) : '';
    const rangeStartDate = DateFormat.convertToDateObject(rangeStart);
    const currentMonth: number = this.currentMonthNumber;
    let newYear: number = this.currentYear;
    let newMonth: number;

    if (currentMonth === 0) {
      newYear = this.currentYear - 1;
      newMonth = 11;
    } else {
      newMonth = currentMonth - 1;
    }

    const newDate = new Date(newYear, newMonth);

    return (!rangeStart || newDate.getTime() >= rangeStartDate.valueOf());
  }

  public ngOnChanges (changes: SimpleChanges) {
    if (changes.date && changes.date.currentValue) {
      let date = changes.date.currentValue;
      if (typeof date === 'string' && this._isValidDateString(date)) {
        date = new Date(DateFormat.displayFormat(changes.date.currentValue.substr(4, 4) + changes.date.currentValue.substr(0, 4)));
      }

      this._date = date;
      this.setDate();
    }
  }

  private _isValidDateString (val: string = ''): boolean {
    const isValid = val && (val.length === 8) && !isNaN(Number(val));

    return isValid;
  }

  private setCurrentValues (date: Date) {
    this.currentMonthNumber = date.getMonth();
    this.currentMonth = this.months[this.currentMonthNumber];
    this.currentYear = date.getFullYear();
    const calendarArray = this.calendar.monthDays(this.currentYear, this.currentMonthNumber);
    this.calendarDays = [].concat.apply([], calendarArray);
  }

  @Input()
  set date (val: string) {

    const isValid = this._isValidDateString(val);

    if (!isValid) {
      val = undefined;
    }

    if (typeof val === 'undefined' || val === null) { // initial state of date picker
      this.inputText = '';
      this.setCurrentValues(new Date()); // set calendar
    }
  }

  get date (): string {
    return this.inputText;
  }
}
