import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FileDownloadService {

  private _fileExt: object = {
    'image/jpeg': 'jpeg',
    'application/pdf': 'pdf',
    'application/msword': 'doc',
    'audio/mpeg': 'mp3',
    'video/mp4': 'mp4',
    'text/plain': 'txt'
  };

  private _defaultFileName: string = 'output-file';

  constructor (private _http: HttpClient) {}

  public get (fileUrl: string, fileType: string, title?: string, formatFileName: boolean = true): Observable<any> {
    let filename = this._defaultFileName;
    filename = title && formatFileName ? this.createFilename(title) : title && !formatFileName ? title : this._defaultFileName;

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', fileType);
    return this._http.get(fileUrl, { headers, responseType: 'blob' })
      .pipe(
        map((resp: any) => {
          if (resp && resp.type) {
            return resp;
          }
        }),
        map((blob) => {
        let ext = this._fileExt[fileType] ? this._fileExt[fileType] : this._fileExt['text/plain'];
        if (blob) {
          saveAs(blob, filename + '.' + ext);
        }
      })
      );
  }

  public createFilename (value: string): string {
    let filename = value.toLowerCase()
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()\[\]\'\"]/g, '') // remove punctuation
      .replace(/[^\x00-\x7F]/g, '') // remove any non printable characters
      .substr(0, 255) // limit length for filenames for certain OSs
      .trim() // trim whitespace from the ends
      .split(' ').join('_'); // replace whitespace with underscore

    return filename;
  }
}
