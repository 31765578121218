<!-- using hidden attribute instead of ngIf because of a display issue with Angular Material tooltips caused by the
element not being available when the library is loaded -->
<div class="container-microsoftAccount" [hidden]="!isAvailable">
  <button
    class="btn"
    [ngClass]="{ 'loggedIn': isLoggedIn === true }"
    [matTooltip]="tooltipText"
    type="button"
    (click)="toggleMicrosoftAccountLink($event)"
    name="linkMicrosoftButton"
    [disabled]="loading">

    <img src="./assets/img/microsoft-logo.svg" alt="Microsoft" title="Microsoft">
    <span> {{ getButtonTxt() | async }}</span>
  </button>
</div>
