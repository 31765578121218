import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DocumentAttributesModel } from '../../_interfaces/document/document.model';
import XMLString from '../../_utilities/xmlstring';

@Injectable()
export class UsageLoggingService {

  private url: string = '/svc/grapple/logusage';

  constructor (private _http: HttpClient) {}

  public doLogUsage (eventType: string, document: DocumentAttributesModel): Promise<any> {
    let postBody = this.createPostBody(eventType, document);
    return this._http.post(this.url, postBody).toPromise();
  }

  public createPostBody (eventType: string, postData: DocumentAttributesModel): string {
    let postBody = '<DocPostProcessRequest>';

    postBody += XMLString.xmlwrap(eventType, 'event', false);
    delete postData.externalLink;
    delete postData.thumbnailImage;
    delete postData.text;

    postBody += '<attributes>';
    for (let prop in postData) {
      if (postData.hasOwnProperty(prop) && postData[prop]) {
        postBody += XMLString.xmlwrap(postData[prop].toString(), prop, false);
      }
    }

    postBody += '</attributes></DocPostProcessRequest>';

    return postBody;
  }

}
