import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TermService {
  private _url: string = '/svc/grapple/autocomplete';

  private _queryLen: number = 2;

  constructor (
    private _http: HttpClient
  ) {}

  public get (query: string, profile: string): Observable<any> {
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'text/plain');
    let request: Observable<any> = new BehaviorSubject<string[]>([]);
    if (query.length >= this._queryLen) {
       request = this._http.get(this._url + '?q=' + query + '&m=' + profile, {headers})
        .pipe(
          map((resp: object) => {
        
            if (resp['AutoCompleteResponse']['phrase'] &&
              resp['AutoCompleteResponse']['phrase'].constructor === Array) {
              return resp['AutoCompleteResponse']['phrase'];
            }
          })
        );
    }
    

    return request;
  }

  public filter(collection: string[], val: string): string[] {
    return collection.filter((option) => new RegExp(`^${val}`, 'gi').test(option));
  }
}
