import { Component } from '@angular/core';
import { SeoService } from '../_services';

@Component({
  selector: 'pq-app-down',
  templateUrl: './app.down.component.html',
  styleUrls: ['./_app.down.component.scss']
})
export class AppDownComponent {
  constructor (private _seoSvc: SeoService) {
    this._seoSvc.setTitle('Web Application is down');
  }
}
