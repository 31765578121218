import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Router, NavigationEnd, RoutesRecognized } from '@angular/router';

import { AppState } from '../app.service';
import { SessionService } from '../_services';
import { SessionModel } from '../_interfaces/session/session.model';
import {
  SiteHeaderMenu, SiteHeaderMenuLinkList,
  SiteHeaderSubMenu
} from '../_interfaces/site-header-menu/site-header-menu';
import { ProductConfig } from '../_utilities/product.config';
import { ProductCheck } from '../_utilities';

declare var dataLayer: any;

@Component({
  selector: 'pq-site-header',
  templateUrl: './site.header.component.html',
  styleUrls: ['./_site.header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SiteHeaderComponent implements OnInit {
  @ViewChild('menu', { static: false }) public topicsMenu;

  public currentProfile: string = this.appState.get('current_profile') || this.getProfile;
  public topics: object[] = this.appState.get('ROOT_TOPICS') || [];
  public session: SessionModel = this._sessionService.getSession();
  public showSearch: boolean = false;
  public isNavigationOpen: boolean = false;
  public headerNavigationClasses: string[] = ['collapse', 'navbar-collapse', 'bs-navbar-collapse'];
  public gaSearchFrom: string = 'results';
  public headerSearchBarClasses = ['container-search', 'container-search-hide'];
  public productName: string = '';
  public productIcon: string = '';

  public headerMenu: SiteHeaderMenu = {
    topics: {
      btnLabel: 'Research Topics',
      menu: [],
      className: ['dropdown', 'clearfix'],
      isOpen: false
    },
    books: {
      btnLabel: 'Nonfiction Books',
      menu: this.appState.get('NONFICTION_BOOKS') ? this._formatBooksMenuData() : undefined,
      className: ['item-nonfictionBooks', 'dropdown'],
      isOpen: false
    }
  };

  private _openClass: string = 'open';
  private _topicMenuItemIndex: number = -1;
  private _isPortalPage: boolean = false;
  private _profile: string = '';

  @Input() set profile (value: string) {
    this._profile = (value !== null) ? value : '';
  };

  get getProfile (): string {
    return this._profile;
  }

  constructor (
    public appState: AppState,
    private _sessionService: SessionService,
    private _route: Router,
    private _productConfig: ProductConfig,
    private _productCheck: ProductCheck
  ) {
    this._route.events.subscribe((event) => {
      this.productIcon = _productConfig.getHeaderIcon();

      if (event instanceof RoutesRecognized) {
        this._isPortalPage = (event.state.url.toLowerCase().indexOf('/portal') !== -1) && this.isAuth();
      }

      if (event instanceof NavigationEnd) {
        const url = event.url;
        this.showSearch = (url.indexOf('/home') ===  -1) && (url.indexOf('/advanced') === -1) && (url.indexOf('/error') === -1) && (url !== <string> '/');
        // updating the state of the research topics button
        this._updateActiveState(this.headerMenu.topics, ((url.indexOf('/researchtopics/') > -1) && (url.indexOf('nonfictionbooks') === -1)) || (url.indexOf('/leadingissues/') > -1));

        this._updateActiveState(this.headerMenu.books, (url.indexOf('nonfictionbooks') > -1));

        this._isPortalPage = url.indexOf('/portal') !== -1;
      }
    });


    this.appState.stateStatus.subscribe((status: Object) => {
      if (status['ROOT_TOPICS']) {
        this.headerMenu.topics.menu = this.appState.get('ROOT_TOPICS');
        this._updateDropDownMenu();
        this.headerMenu.topics.className.push('linkCol' + this.headerMenu.topics.menu.length.toString());
      }

      if (status['displayname']) {
        this.productName = this.appState.get('displayname');
      }

      if (status['current_profile']) {
        this.currentProfile = status['current_profile'];
      }

      if (status['NONFICTION_BOOKS']) {
        this.headerMenu.books.menu = this._formatBooksMenuData(status);
      }

    });
  }

  public ngOnInit () {
    this.headerMenu.books.menu = this._formatBooksMenuData();
  }

  @HostListener('window:keydown', ['$event'])
  public keyboardInput (e: KeyboardEvent) {
    if (e['keyCode'] === 27 && this._isDropdownOpen()) {
      this._doCloseTopicsMenus();
    } else if (e['keyCode'] === 40 && this._isDropdownOpen()) {
      e.preventDefault();
      const links = this.topicsMenu.nativeElement.getElementsByTagName('a');
      if (links.length) {
        this._getNext(links).focus();
      }
    } else if (e['keyCode'] === 38 && this._isDropdownOpen()) {
      e.preventDefault();
      let links = this.topicsMenu.nativeElement.getElementsByTagName('a');
      if (links.length) {
        this._getPrev(links).focus();
      }
    }
  }

  @HostListener('document:click', ['$event'])
  public handleMenuCloseExternalClick (e: Event) {
    let currentTarget = e.target as HTMLElement;
    const tagName = currentTarget.tagName.toLowerCase();

    if (tagName === 'svg') {
      currentTarget = currentTarget.parentElement;
    } else if (tagName === 'use') {
      currentTarget = currentTarget.parentElement.parentElement;
    }


    if (typeof this.topicsMenu !== 'undefined' && (!this.topicsMenu.nativeElement.contains(e.target)) && currentTarget.id.indexOf('btn-topicsMenuControl') === -1) {
      this.close();
    }
  }

  public isAuth (): boolean {
    let sessionObject = sessionStorage.getItem('sessionObject')
      ? JSON.parse(sessionStorage.getItem('sessionObject')) : {};

    return (typeof sessionObject['sessionRoles'] !== 'undefined' && sessionObject['sessionRoles'] !== null)
      && sessionObject['sessionRoles'] !== 'unauthenticated';
  }

  public isPortal (): boolean {
    return this._isPortalPage && this.isAuth();
  }

  public isMicrosoftEnabledInSession (): boolean {
    return (typeof this.appState.get('ENABLE_ONEDRIVE') !== 'undefined'
      && this.appState.get('ENABLE_ONEDRIVE') !== null
      && this.appState.get('ENABLE_ONEDRIVE') === 'Y');
  }

  public isGoogleEnabledInSession (): boolean {
    return (typeof this.appState.get('ENABLE_GOOGLE_INTEGRATION') !== 'undefined'
      && this.appState.get('ENABLE_GOOGLE_INTEGRATION') !== null
      && this.appState.get('ENABLE_GOOGLE_INTEGRATION') === 'Y');
  }

  public toggleHeaderMenu (e: Event, type: string): void {
    e.stopPropagation();

    if(this.headerMenu[type].isOpen) {
      this.headerMenu[type].isOpen = false;
      this._updateOpenStatus(this.headerMenu[type], false);
    } else {
      this.close();
      this.headerMenu[type].isOpen = true;
      this.headerMenu[type].className.push(this._openClass);
    }
  }

  public close () {
    this._doCloseTopicsMenus();
  }

  public toggleNavigation () {
    const navOpenClass = 'in';

    if (this.isNavigationOpen) {
      this.isNavigationOpen = false;
      this.headerNavigationClasses.splice(this.headerNavigationClasses.indexOf(navOpenClass), 1);
    } else {
      this.isNavigationOpen = true;
      this.headerNavigationClasses.push(navOpenClass);
    }
  }

  public showSearchPopup () {
    if (this.headerSearchBarClasses.length === 2) {
      this.headerSearchBarClasses.pop();
    }
    document.getElementsByName('query')[0].focus();
  }

  public hideSearchPopup () {
    if (this.headerSearchBarClasses.length === 1) {
      this.headerSearchBarClasses.push('container-search-hide');
    }
  }

  public pushToGA (category: string, label: string, origin: string, action?: string) {
    const data = {'event': origin, 'category': category, 'label': label };
    data['action'] = action;

    if (Array.isArray(dataLayer)) {
      dataLayer.push(data);
    }
  }

  public getHelpURL() {
    let helpURL = this.appState.getProfileProperty('helpURL');
    if(helpURL) {
        return helpURL;
    }
    return 'https://support.proquest.com/#submitcase';
  }

  private _formatBooksMenuData (data?: object): SiteHeaderMenuLinkList[] {
    const booksMenuData = data && data['NONFICTION_BOOKS'] ? data['NONFICTION_BOOKS'] : this.appState.get('NONFICTION_BOOKS');

    if (typeof booksMenuData !== 'undefined' && Array.isArray(booksMenuData)) {
      booksMenuData.forEach((item: object) => {
        delete item['title'];
      });
    }

    return booksMenuData;
  }

  private _isDropdownOpen (): boolean {
    let isOpen = false;

    for (let prop in this.headerMenu) {

      if (this.headerMenu.hasOwnProperty(prop)) {
        for (let key in this.headerMenu[prop]) {
          if (this.headerMenu[prop]['isOpen']) {
            isOpen = true;
          }
        }
      }
    }

    return isOpen;
  }

  private _updateActiveState (menu: SiteHeaderSubMenu, isActive: boolean): void {
    const index = menu.className ?  menu.className.indexOf('active') : -1;

    if (isActive && index === -1) {
      menu.className.push('active');
    } else if (!isActive && index > -1) {
      menu.className.splice(index, 1);
    }

  }

  private _updateOpenStatus (menu: object, isOpen: boolean): void {
    const index = menu['className'] ? menu['className'].indexOf(this._openClass) : -1;

    if (isOpen && index === -1) {
      menu['className'].push(this._openClass);
    } else if (!isOpen && index > -1) {
      menu['className'].splice(index, 1);
    }

  }

  private _doCloseTopicsMenus (): void {

    for (const prop in this.headerMenu) {
      if (this.headerMenu.hasOwnProperty(prop)) {

        this.headerMenu[prop]['isOpen'] = false;
        this._updateOpenStatus(this.headerMenu[prop], false);
      }
    }
  }

  private _getNext (items: any[]): any {
    this._topicMenuItemIndex++;

    if (this._topicMenuItemIndex > items.length - 1) {
      this._topicMenuItemIndex = 0;
    }

    return items[this._topicMenuItemIndex];
  }

  private _getPrev (items: any[]): any {
    this._topicMenuItemIndex--;

    if (this._topicMenuItemIndex < 0) {
      this._topicMenuItemIndex = items.length - 1;
    }

    return items[this._topicMenuItemIndex];
  }

  private _updateDropDownMenu (): void {
    this.headerMenu['topics']['btnLabel'] = 'Research Topics';

    if (this._productCheck.isResearcher(this.appState.get('current_profile'))) {
      this.headerMenu['topics']['btnLabel'] = 'Leading Issues';
    }
  }
}
