import { Injectable } from '@angular/core';

@Injectable()
export class ProductCheck {
  
  public iseLibraryCheck (profile: string): boolean {
    return this._doCheck(profile, 'elibrary');
  }
  
  public isResearcher (profile: string): boolean {
    return this._doCheck(profile, 'sirsissuesresearcher');
  }
  
  public isDiscoverer (profile: string): boolean {
    return this._doCheck(profile, 'sirsdiscoverer');
  }
  
  private _doCheck (profileVal: string, productVal: string): boolean {
    return productVal === profileVal;
  }
}