import { Injectable } from '@angular/core';
import { MicrosoftAuthService } from '../../_services';
import { HttpClient } from '@angular/common/http';
import XMLString from '../../_utilities/xmlstring';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DocumentAttributesModel } from '../../_interfaces/document/document.model';
import { AppState } from '../../app.service';
import { GoogleDriveState } from '../../site-header/section';

@Injectable()
export class SaveToCloudService {

  private _microsoftUrl: string = '/svc/grapple/saveonedrive';
  private _googleUrl: string = '/svc/grapple/savegoogledrive';

  constructor(
    private _http: HttpClient,
    private _msAuthSvc: MicrosoftAuthService,
    private _appState: AppState
  ) { }

  public connectMS(): void {
    this._msAuthSvc.initMsAuth().toPromise().then(() => {
      this._msAuthSvc.login();
    });
  }

  public saveToGoogleDrive(docid: string, postData: DocumentAttributesModel, citationStyle: any): Promise<any> {
    const googleApiStatus: GoogleDriveState = this._appState.get('google');
    const accessToken = googleApiStatus.accessToken;
    const accessTokenExpiresIn = googleApiStatus.accessTokenExpiresIn;
    const accessTokenType = googleApiStatus.accessTokenType;
    const postBody = this._createGooglePostBody(postData, accessToken, accessTokenExpiresIn, accessTokenType, citationStyle);
    const url = this._googleUrl + '?goid=' + docid + '&db=' + this._appState.get('current_profile');
    return this._http.post(url, postBody)
      .pipe(
        catchError((error: Response) => {
          // Service will throw 401 if the temp access token is expired.
          // If so, connect to Google api and create the temp access token.
          if (error.status === 401) {

          }

          return throwError(error);
        })
      )
      .toPromise();

  }

  public saveToOneDrive(docid: string, postData: DocumentAttributesModel, citationStyle: any): Promise<any> {
    let accessToken = this._msAuthSvc.getAccessToken() || '';
    let accessTokenExpiresIn = this._msAuthSvc.getExpiresIn() || '';

    let postBody = this._createMicrosoftPostBody(postData, accessToken, accessTokenExpiresIn, citationStyle);
    let url = this._microsoftUrl + '?goid=' + docid + '&db=' + this._appState.get('current_profile');

    return this._http.post(url, postBody).toPromise()
      .catch((error: Response) => {
        if (error.status === 401) {
          this.connectMS();
        }

        return throwError(error).toPromise();
      });
  }

  private _createGooglePostBody(postData: DocumentAttributesModel,
    accessToken: string,
    accessTokenExpiresIn: string,
    accessTokenType: string,
    citationStyle: any): string {



    let postBody = '<GrappleGoogleTokenResponse>';
    postBody += XMLString.xmlwrap(accessToken, 'googleAccessToken', false);
    postBody += XMLString.xmlwrap(accessTokenExpiresIn, 'googleTokenExpiresIn', false);
    postBody += XMLString.xmlwrap(accessTokenType, 'googleTokenType', false);
    postBody += XMLString.xmlwrap(citationStyle, 'citationStyle', false);
    postBody += XMLString.xmlwrap(document.location.href, 'durableUrl', false);
    postBody += '<attributes>';
    postBody += this._formatDocumentData(postData);
    postBody += '</attributes>';
    postBody += '</GrappleGoogleTokenResponse>';
    return postBody;
  }

  private _createMicrosoftPostBody(postData: DocumentAttributesModel,
    accessToken: string,
    accessTokenExpiresIn: string, citationStyle: any): string {

    let postBody = '<GrappleOneTokenResponse>';
    postBody += XMLString.xmlwrap(accessToken, 'oneAccessToken', false);
    postBody += XMLString.xmlwrap(accessTokenExpiresIn, 'oneTokenExpiresIn', false);
    postBody += XMLString.xmlwrap(citationStyle, 'citationStyle', false);
    postBody += '<attributes>';
    postBody += this._formatDocumentData(postData);
    postBody += '</attributes>';
    postBody += '</GrappleOneTokenResponse>';
    return postBody;
  }

  private _formatDocumentData(document: DocumentAttributesModel): string {
    let excludedProp = {
      text: true,
      abstract: true,
      multimediaConfig: true,
      docLink: true
    };
    let postDocument = '';


    for (let prop in document) {
      if (document.hasOwnProperty(prop) && document[prop] && !excludedProp[prop]) {
        postDocument += XMLString.xmlwrap(document[prop].toString(), prop, false);
      }
    }

    return postDocument;
  }
}
