import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DocumentAttributesModel } from '../../_interfaces/document/document.model';
import XMLString from '../../_utilities/xmlstring';
import { AppState } from '../../app.service';

@Injectable()
export class CitationService {
  private url: string = '/svc/grapple/cite';

  constructor (
    private _http: HttpClient,
    private _appState: AppState
  ) {}

  public get (citeStyle: string, document: DocumentAttributesModel): Promise<any> {
    let postBody = this.createPostBody(citeStyle, document);

    return this._http.post(this.url + '?db=' + this._appState.get('current_profile'), postBody).toPromise();
  }

  public createPostBody (citeStyle: string, postData: DocumentAttributesModel): string {
    let postBody = '<DocPostProcessRequest>';

    postBody += XMLString.xmlwrap(citeStyle, 'citeStyle', false);

    postBody += '<attributes>';
    for (let prop in postData) {
      if (postData.hasOwnProperty(prop) && postData[prop]) {
        postBody += XMLString.xmlwrap(postData[prop].toString(), prop, false);
      }
    }

    postBody += '</attributes></DocPostProcessRequest>';

    return postBody;
  }
}
