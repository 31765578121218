import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { UserModel } from '../../_interfaces';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService, SeoService, SessionService, ConfigService } from '../../_services';
import { AppState } from '../../app.service';
import { SessionModel } from '../../_interfaces/session/session.model';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { BootstrapProduct } from '../../_utilities/bootstrap.product';
import { firstValueFrom } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FindInstitutionComponent } from '../find-institution/find-institution.component';
import { GoogleLoginComponent } from '../google-login/google-login.component';
import { OtiService } from '@sp/oti-snippet';
import { request } from 'https';
import { HttpRequest } from '@angular/common/http';
import { PendoService } from './pendo.service';

declare var dataLayer: any;
declare let pendo: any;
declare let APP_GLOBALS: any;

@Component({
  selector: 'pq-userpass',
  templateUrl: './user-pass.component.html',
  styleUrls: ['./_user-pass.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserPassComponent implements OnInit {
  public model: UserModel = new UserModel('', '');
  public submitted: boolean = false;
  public profileVal: string = this.appState.get('current_profile');
  public boldMessage: string = 'We couldn\'t log you in. ';
  public message: string = '';
  public isVisibleForgotInfo: boolean = false;

  private _redirectLocation: string = '';
  private _bootRequest: Promise<object>;
  private _errorMessages: Object = {
    incomplete: this.appState.get('loginIncomplete'),
    service: this.appState.get('loginService'),
    incorrect: this.appState.get('loginIncorrect'),
    cleverauth: this.appState.get('ssoCleverAcctError'),
    googleauth: this.appState.get('ssoGoogleAcctError'),
    shibbolethauth: this.appState.get('ssoShibbolethGeneralError'),
    producterror: this.appState.get('noProductOnSubscriptionError'),
    zeroproductserror: this.appState.get('portalNoProductsError')
  };
  public tragetCookie = false;

  constructor(
    public appState: AppState,
    public dialog: MatDialog,
    private _authService: AuthService,
    private _seoSvc: SeoService,
    private _sessionService: SessionService,
    private _router: Router,
    private _bootstrapProduct: BootstrapProduct,
    private _configSvc: ConfigService,
    private _route: ActivatedRoute,
    private pendoService: PendoService
  ) {
    this._route.queryParamMap.subscribe((queryParams: ParamMap) => {
      sessionStorage.clear();
      Cookie.delete('session', '/');
      Cookie.delete('accountId', '/');

      if (queryParams.get('error')) {
        const type = queryParams.get('sso') || 'service';
        this._handleError(undefined, type);
      }
    });
  }

  @Input() set profile(value: string) {
    this.profileVal = value;
  };

  @Input() set redirectLocation(value: string) {
    this._redirectLocation = value;
  }

  get redirectLocationVal() {
    return this._redirectLocation;
  }

  public ngOnInit(): void {
    sessionStorage.setItem('gaSessionType', 'usernamepass');
  }

  @HostListener('window:keydown', ['$event'])
  public keyboardInput(e: KeyboardEvent) {
    if ((e['keyCode'] === 13) && dataLayer && Array.isArray(dataLayer)) {
      dataLayer.push({ 'event': 'ManualLoginEnterButton' });
    }
  }

  public pushLoginClickToGA(e: MouseEvent) {
    if (dataLayer && Array.isArray(dataLayer)) {
      dataLayer.push({ 'event': 'ManualLoginClickButton' });
    }
  }

  public pushCleverLoginClickToGA(e: MouseEvent) {
    if (dataLayer && Array.isArray(dataLayer)) {
      dataLayer.push({ 'event': 'CleverLoginClickButton' });
    }
  }

  public pushGoogleLoginClickToGA(e: MouseEvent) {
    if (dataLayer && Array.isArray(dataLayer)) {
      dataLayer.push({ 'event': 'GoogleLoginClickButton' });
    }
  }

  public onSubmitLogin(model: UserModel, isValid: boolean): void {
    this.message = '';

    if (isValid && model['user'] && model['password']) {
      if (dataLayer && Array.isArray(dataLayer)) {
        dataLayer.push({ 'event': 'ManualLoginTextEntry', 'label': 'user=username' });
      }

      Cookie.delete('session', '/');
      Cookie.delete('accountId', '/');

      this.submitted = true;

      const request = this._doLogin(model);

      request.then((resp) => {
        // handle login resp
        this._handleLoginResp(resp);
      });

      request.catch((err: any) => {
        this._handleError(err, 'service');
      });

    } else {
      this._handleError(null, 'incomplete');
    }
  }

  public handleClickToDayFindInstitution() {
    if (dataLayer && Array.isArray(dataLayer)) {
      dataLayer.push({ 'event': 'InstitutionLoginClickButton' });
    }
    const config = new MatDialogConfig();
    config.autoFocus = false;
    const dialog = this.dialog.open(FindInstitutionComponent, config);

  }

  public handleClickToGoogleLogin() {
    if (dataLayer && Array.isArray(dataLayer)) {
      dataLayer.push({ 'event': 'GoogleLoginClickButton' });
    }
    const config = new MatDialogConfig();
    config.autoFocus = false;
    const dialog = this.dialog.open(GoogleLoginComponent, config);

  }

  public toggleRecoverAccountInfo(e) {
    if (this.isVisibleForgotInfo) {
      this.isVisibleForgotInfo = false;
    } else {
      this.isVisibleForgotInfo = true;
    }
  }

  public getProductName() {
    return this.appState.get('displayname');
  }

  private async _doLogin(model): Promise<object> {
    const request = await firstValueFrom(this._authService.login(model))
    return request;
  }

  private _handleLoginResp(resp: object) {
    const session = resp['SessionResponse'];

    if (session && session.sessionRoles === 'authenticated') {
      this._configSvc.get().then((configResp) => {
        this.appState.set(configResp);

        const sessionObject = this._sessionService.createSessionObject(session);

        // reset state to default unauthenticated state
        sessionStorage.clear();
        Cookie.delete('session', '/');
        Cookie.delete('accountId', '/');

        const previousProfile = this.appState.get('current_profile');
        const previousDisplayName = this.appState.get('displayname');

        this.appState.clear();
        // set cookie because services need the session ID to prove the user is authenticated
        Cookie.set('session', sessionObject['sessionId'], null, '/');
        this.appState.set({ current_profile: previousProfile, displayname: previousDisplayName, sessionType: 'USERNAMEPASS' });

        this._bootRequest = this._bootstrapProduct.executeLoad();
        this._bootRequest.then((resp: object) => {
          if (resp['completed'] === true && resp['status'] === 'successful') {

            // update app state
            sessionStorage.setItem('sessionObject', JSON.stringify(sessionObject));
            this.submitted = false;
            this._configSvc.updateAppState(session)
            this._doSuccessfulRedirect(session);
          } else if (resp['producterror'] === true) {
            this.boldMessage = '';
            this._handleError(null, 'producterror');
          } else if (resp['zeroproductserror'] === true) {
            this.boldMessage = '';
            this._handleError(null, 'zeroproductserror');
          } else if (resp['completed'] === true && resp['status'] === 'error') {
            const errorData = resp && resp['data'] && resp['data']['err'] ? resp['data']['err'] : {};
            const errorType = resp && resp['data'] && resp['data']['type'] ? resp['data']['type'] : {};

            this._handleError(errorData, errorType);
          }
        });
      });

    } else {
      if (session) {
        this._handleError(null, 'incorrect');
      } else {
        this._handleError(null, 'service');
      }
    }
  }

  private _doSuccessfulRedirect(session: SessionModel): void {
    let profile = this.appState.get('current_profile');
    const products = Array.isArray(this.appState.get('grappleProducts')) ? this.appState.get('grappleProducts') : [];
    const allProducts = Array.isArray(this.appState.get('allProducts')) ? this.appState.get('allProducts') : [];
    const hasOnlyOneProduct = (allProducts.length === 2 && products.length === 1);
    let path = profile ? profile + '/home' : 'portal/home';

    if (hasOnlyOneProduct && (this.redirectLocationVal && (this.redirectLocationVal.indexOf('portal') === -1))) {
      profile = products[0]['profile'];
      this.appState.set({ current_profile: profile });
      path = profile + '/home';
    }

    const params = { replaceUrl: true };

    session['preferences'].forEach((entry) => {
      if (entry['code'] === 'DEFAULT_RESULTS_PER_PAGE') {
        let entryValue = parseInt(entry['value'], 0);
        this.appState.set({ DEFAULT_RESULTS_PER_PAGE: entryValue });
      }
      if (entry['code'] === 'ENABLE_GOOGLE_INTEGRATION') {
        this.appState.set({ ENABLE_GOOGLE_INTEGRATION: entry['value'] });
      }
      if (entry['code'] === 'ENABLE_ONEDRIVE') {
        this.appState.set({ ENABLE_ONEDRIVE: entry['value'] });
      }
    });

    this._sessionService.updateSession(session);

    // if redirect path is the portal but user has one product then change it to be that products home page
    if (hasOnlyOneProduct && (this.redirectLocationVal && (this.redirectLocationVal.indexOf('/portal') !== -1))) {
      this._redirectLocation = '/' + path; // set redirect path and keep it similar to the stored values in pam
    }

    if (this.redirectLocationVal && this.appState.get('current_profile')) {
      path = this.redirectLocationVal[0] === '/' ? this.redirectLocationVal.split('?')[0].substr(1) : this.redirectLocationVal.split('?')[0];

      const pathDataArray = path.split('/').filter((segment) => {
        return segment !== '';
      });

      // if the redirect does not have profile in it's url
      if (pathDataArray[0] !== this.appState.get('current_profile') && this.appState.get('displayname')) {
        path = '/' + this.appState.get('current_profile') + '/' + path;

      } else if (pathDataArray[0] === this.appState.get('current_profile') && this.appState.get('displayname')) {
        path = this.redirectLocationVal.split('?')[0].substr(1);

        if (pathDataArray.length === 1) {
          path += '/home';
        }
      } else {
        path = 'portal/home';
      }

      params['queryParams'] = this._createParamsObject(this.redirectLocationVal);
    }

    this._router.navigate(['/'].concat(path.split('/')), params);
  }

  private _createParamsObject(data: string): Object {
    let paramsObject = {};
    let paramsString = data.split('?').length === 2 ? data.split('?')[1] : '';
    let paramsArray = [];

    if (paramsString) {
      paramsArray = paramsString.split('&');

      paramsArray.forEach((param) => {
        let paramDataArray = param.split('=');
        if (paramDataArray[0].toLowerCase() !== 'accountid') {
          paramsObject[paramDataArray[0]] = paramDataArray[1];
        }
      });
    }

    return paramsObject;
  }

  private _handleError(err?: any, type?: string): void {
    this.submitted = false;

    if (type) {
      this.message = this._errorMessages[type];
    }
  }
}
