import { CommonModule }   from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { NotificationComponent } from './notification.component';
import { NotificationService } from './notification.service';

export * from './notification.component';

@NgModule({
  declarations: [
    NotificationComponent
  ],
  providers: [ NotificationService ],
  exports: [ NotificationComponent ],
  imports: [ CommonModule, FormsModule ]
})
export class NotificationModule {}
