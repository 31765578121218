<header class="navbar navbar-inverse site-header" id="site-header" *ngIf="!isPortal()">
    <div class="navbar-siteheader">
        <ul class="navbar-nav" *ngIf="isAuth()">
            <li *ngIf="productIcon">
                <a (click)="pushToGA('Header', 'Home', 'GlobalEvent', 'Navigation')" [routerLink]="[currentProfile, 'home']" class="navbar-brand diagonal" title="return to home">
                  <svg [ngClass]="productIcon">
                    <use [attr.xlink:href]="'./assets/img/icons.svg#' + productIcon"></use>
                  </svg>
                </a>
            </li>
            <li class="navbar-brand diagonal-thin" *ngIf="!productIcon"></li>
            <li>
                <div class="navbar-brand product-name" [ngClass]="{ 'no-subtitle': !productName }">
                    <div class="pq-logo" *ngIf="productName"></div>
                    <a [routerLink]="[currentProfile, 'home']" [innerHTML]="productName || 'ProQuest'"></a>
                </div>
            </li>
        </ul>

        <ul class="navbar-nav" *ngIf="!isAuth()">
          <li *ngIf="productIcon">
            <div class="navbar-brand diagonal">
              <svg [ngClass]="productIcon">
                <use [attr.xlink:href]="'./assets/img/icons.svg#' + productIcon"></use>
              </svg>
            </div>
          </li>
          <li class="navbar-brand diagonal-thin" *ngIf="!productIcon"></li>
          <li>
            <div class="navbar-brand product-name" [ngClass]="{ 'no-subtitle': !productName }">
              <div class="pq-logo" *ngIf="productName"></div>
              <div [innerHTML]="productName || 'ProQuest'"></div>
            </div>
          </li>
        </ul>

        <div *ngIf="isAuth() && showSearch" [ngClass]="headerSearchBarClasses">
            <pq-basic-search sectionClassName="header-search" [profile]="currentProfile" [gaSearchFrom]="gaSearchFrom"></pq-basic-search>
            <button type="button" class="btn btn-close visible-xs visible-sm hidden-md" (click)="hideSearchPopup()">
                <svg class="gr-cancel-squared">
                    <use xlink:href="./assets/img/icons.svg#gr-cancel-squared"></use>
                </svg>
            </button>
        </div>

        <div class="container-subNav" *ngIf="isAuth()">
            <div *ngIf="showSearch">
                <button type="button" class="visible-xs visible-sm btn-search btn btn-default" (click)="showSearchPopup()">
                    <svg class="gr-search" xmlns="http://www.w3.org/2000/svg">
                        <use xlink:href="./assets/img/icons.svg#gr-search"></use>
                    </svg>
                </button>
            </div>

            <button data-target="#example-navbar-2" data-toggle="collapse" type="button" class="navbar-toggle" (click)="toggleNavigation()" title="eLibrary Menu">
                <svg class="gr-menu" xmlns="http://www.w3.org/2000/svg">
                    <use xlink:href="./assets/img/icons.svg#gr-menu"></use>
                </svg>
            </button>
        </div>
    </div>


    <nav [ngClass]="headerNavigationClasses" *ngIf="isAuth()" [attr.aria-expanded]="isNavigationOpen">
        <div class="nav navbar-nav navbar-right nav-help">
          <ul class="nav navbar-nav">
            <li *ngIf="isMicrosoftEnabledInSession()" class="item-cloudSvc">
              <pq-ms-btn></pq-ms-btn>
            </li>

            <li *ngIf="isGoogleEnabledInSession()" class="item-cloudSvc">
              <pq-google-link></pq-google-link>
            </li>

            <li>
              <a (click)="pushToGA('Header', 'Help Button', 'GlobalEvent')" href="{{getHelpURL()}}" target="_blank" aria-label="Help Section" title="Help Section" role="button" class="link-help">
                <svg class="gr-help-circled hidden-xs" xmlns="http://www.w3.org/2000/svg">
                  <use xlink:href="./assets/img/icons.svg#gr-help-circled"></use>
                </svg>
                <span aria-hidden="true" class="hidden-md hidden-lg">Help</span>
              </a>
            </li>
          </ul>
        </div>

        <div class="nav navbar-nav navbar-below">
            <ul class="nav navbar-nav">
                <li routerLinkActive="active" [routerLinkActiveOptions]="{exaxt: true}">
                    <a [routerLink]="[currentProfile, 'home']" (click)="close()" class="link-home">Home</a>
                </li>

                <li [ngClass]="headerMenu.topics.className" [attr.aria-expanded]="headerMenu.topics.isOpen" (pqClickOutside)="close()" *ngIf="headerMenu.topics.menu && headerMenu.topics.menu.length">
                    <button class="btn btn-link dropdown-toggle" id="btn-topicsMenuControl" type="button" (click)="toggleHeaderMenu($event,'topics')">
                        {{ headerMenu.topics.btnLabel }}
                        <svg class="gr-down-open" xmlns="http://www.w3.org/2000/svg">
                            <use xlink:href="./assets/img/icons.svg#gr-down-open"></use>
                        </svg>
                    </button>
                    <div class="dropdown-menu" role="menu" #menu>
                        <pq-topicListMenu *ngFor="let menu of headerMenu.topics.menu" [menu]="menu" [isTopicsMenuOpen]="headerMenu.topics.isOpen" (closed)="close()"></pq-topicListMenu>
                    </div>
                </li>

                <li [ngClass]="headerMenu.books.className" [attr.aria-expanded]="headerMenu.books.isOpen" *ngIf="headerMenu.books.menu &&  headerMenu.books.menu.length">
                  <button class="btn btn-link dropdown-toggle" id="btn-nfBooksMenuControl" type="button" (click)="toggleHeaderMenu($event, 'books')">
                    {{ headerMenu.books.btnLabel }}
                    <svg class="gr-down-open" xmlns="http://www.w3.org/2000/svg">
                      <use xlink:href="./assets/img/icons.svg#gr-down-open"></use>
                    </svg>
                  </button>
                  <div class="dropdown-menu" role="menu" #menu>
                    <pq-topicListMenu *ngFor="let menu of headerMenu.books.menu" [menu]="menu" [isTopicsMenuOpen]="headerMenu.books.isOpen" (closed)="close()"></pq-topicListMenu>
                  </div>
                </li>
            </ul>
            <ul class="nav navbar-nav navbar-right">
                <li routerLinkActive="active" [routerLinkActiveOptions]="{exaxt: true}">
                    <a (click)="pushToGA('Educators Resources', 'Educators Resources Page', 'HomeNavigation')" [routerLink]="[currentProfile, 'educators']" (click)="close()" >Educators' Resources</a>
                </li>
            </ul>
        </div>
    </nav>

    <nav [ngClass]="headerNavigationClasses" *ngIf="!isAuth()" [attr.aria-expanded]="isNavigationOpen">
      <div class="nav navbar-nav navbar-right nav-help">
        <ul class="nav navbar-nav">
          <li>
            <a (click)="pushToGA('Header', 'Help Button', 'GlobalEvent')" [href]="getHelpURL()" target="_blank" aria-label="Help Section" title="Help Section" role="button" class="link-help">
              <svg class="gr-help-circled hidden-xs" xmlns="http://www.w3.org/2000/svg">
                <use xlink:href="./assets/img/icons.svg#gr-help-circled"></use>
              </svg>
              <span aria-hidden="true" class="hidden-md hidden-lg">Help</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
</header>

<header class="navbar navbar-inverse site-header" id="site-header" *ngIf="isPortal()">
  <div class="navbar-siteheader">
    <ul class="navbar-nav">
      <li class="navbar-brand diagonal-thin"></li>
      <li>
        <div class="navbar-brand product-name no-subtitle">
          Homework Central<sup>&reg;</sup>
        </div>
      </li>
    </ul>

    <div class="container-subNav">
      <button data-target="#example-navbar-2" data-toggle="collapse" type="button" class="navbar-toggle" (click)="toggleNavigation()" title="ProQuest Product Portal Menu">
        <svg class="gr-menu" xmlns="http://www.w3.org/2000/svg">
          <use xlink:href="./assets/img/icons.svg#gr-menu"></use>
        </svg>
      </button>
    </div>
  </div>

  <nav [ngClass]="headerNavigationClasses" [attr.aria-expanded]="isNavigationOpen">
    <div class="nav navbar-nav navbar-right nav-help">
      <ul class="nav navbar-nav">
        <li>
          <a (click)="pushToGA('Header', 'Help Button', 'GlobalEvent')" [href]="getHelpURL()" target="_blank" aria-label="Help Section" title="Help Section" role="button" class="link-help">
            <svg class="gr-help-circled hidden-xs" xmlns="http://www.w3.org/2000/svg">
              <use xlink:href="./assets/img/icons.svg#gr-help-circled"></use>
            </svg>
            <span aria-hidden="true" class="hidden-md hidden-lg">Help</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</header>
