import { Injectable } from '@angular/core';
import { AppState } from '../../app.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EducatorsResourcesService {
  
  
  constructor (
    public appState: AppState,
    private _http: HttpClient,
  ) {}
  
  get (moniker: string): Promise<object> {
    const url = '/assets/data/' + moniker + '/educators_resources.json';
    
    return this._http.get(url).toPromise();
  }
}
