<div [ngClass]="{ 'open': isTopicsMenuOpen }" class="container-menuList">
  <button type="button" class="btn btn-link hidden-lg" (click)="toggleHeaderSubMenu($event)" *ngIf="menuData.type">
    {{ menuData.title }}
    <svg class="gr-down-open" xmlns="http://www.w3.org/2000/svg">
      <use xlink:href="./assets/img/icons.svg#gr-down-open"></use>
    </svg>
  </button>
  <ul [ngClass]="{ 'noTitle': !menuData.title }">
    <li class="dropdown-header hidden-xs hidden-sm hidden-md" *ngIf="menuData.title">{{menuData.title}}</li>
    <li *ngFor="let topic of menuData.topics">
      <a (click)="pushHomeTopicsToGA(menuData.title, topic.nodeName, 'Top')" [attr.tabindex]="isTopicsMenuOpen ? '0' : '-1'" [routerLink]="topic.url" [innerHTML]="topic.nodeName" (click)="close()"></a>
    </li>
  </ul>
</div>
