<div class="container-login container-loginInstitution" *ngIf="!hasMatchAccountId">
  <div>
    <h1>Select your school or institution</h1>
    <p>We were unable to identify the school or institution that provides you with access to {{ getProductName ()}}. Please select it from the list below.</p>

    <form novalidate [formGroup]="loginForm" (ngSubmit)="onSubmit($event)">
      <div class="form-selectInstitutionContent">
        <div class="form-group select-wrapper form-control">
          <select formControlName="institutionAccount" aria-label="school or institution account">
            <option value="">Select your school or institution</option>
            <option *ngFor="let account of multipleMatchingAccount" [value]="account.accountId">{{account.accountName}}</option>
          </select>
        </div>
        <div>
          <button type="submit" class="btn btn-default">Log In</button>
        </div>
      </div>
    </form>
  </div>
</div>
<pq-loading [loading]="true" [error]="false" *ngIf="hasMatchAccountId"></pq-loading>
