import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AppState } from '../../app.service';
import { Cookie } from 'ng2-cookies/ng2-cookies';

@Component({
    selector: 'pq-site-branding',
    templateUrl: './site.branding.component.html',
    styleUrls: ['./_site.branding.component.scss']
})
export class SiteBrandingComponent implements OnInit {
    public brandImage: SafeUrl;
    public displayBranding: boolean = false;
    public imageText: string;
    public imageUrl: string;
    public linkText: string;
    public linkTextUrl: string;
    public linkOpensInNewWindow: boolean = true;
    public imageOpensInNewWindow: boolean =  true

    constructor (
        public _DomSanitizer: DomSanitizer,
        private _appState: AppState
    ) {}

    public ngOnInit () {
      const brandingCookie = Cookie.get('branding') !== 'undefined' ? JSON.parse(Cookie.get('branding')) : {};
      const brandingData = this._appState.get('branding') || brandingCookie;
      this._setBranding(brandingData);

      this._appState.stateStatus.subscribe((state) => {
        this._appState.stateStatus.subscribe((state) => {
          if (typeof state['branding'] !== 'undefined') {
            this._setBranding(state['branding']);
          }
        });
      });
    }

    public handleBrokenImage (): void {
      // if the image is broken replace it with the spacer to prevent repeated network calls by the browser for the image.
      this.brandImage = '/assets/img/spacer.png';
    }

    private _setBranding (brandingData: object): void {
      if (brandingData) {
        this.displayBranding = true;
        const branding = brandingData;
        this.linkOpensInNewWindow = (branding['opensInNewWindow'] === 'Y');
        this.linkText = branding['linkText'];
        this.linkTextUrl =  branding['linkUrl'];

        if (branding['brandingImage']) {
          const brandingImage = Array.isArray(branding['brandingImage']) ? branding['brandingImage'][0] : branding['brandingImage'];
          this.imageUrl = brandingImage['imageURL'];
          this.imageText = brandingImage['imageText']
          this.brandImage = this._DomSanitizer.bypassSecurityTrustUrl(brandingImage['imagePath'])
          this.imageOpensInNewWindow = (brandingImage['opensInNewWindow'] === 'Y');
        }

      }
    }
}
