import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AppState } from '../../app.service';

@Injectable()
export class DocumentService {

  private url: string = '/svc/grapple/docretrieve';
  private emsPrefixUrl: string = '/svc/grapple/docretrieve?goid=';
  private _searchId: string = '';

  constructor (
    private _http: HttpClient,
    private _appState: AppState,
    private _route: ActivatedRoute
  ) {
    this._route.queryParams.subscribe((queryParam: any) => {
      this._searchId = queryParam['searchId'];
    });
  }

  public get (docid: string, id?: string): Promise<any> {
    // todo: remove when mock story passes
    if (docid === 'sirsissuemock') {
      return this._http.get('/assets/data/mock/cyberbullying_91942.json').toPromise();
    } else if (docid === 'discovereranimalmock') {
      return this._http.get('/assets/data/mock/discoverer-animal-topic.json').toPromise();
    } else if (docid === 'discoverercontroversialmock') {
      return this._http.get('/assets/data/mock/discoverer-controversial-topic.json').toPromise();
    } else if (docid === 'skstimelinemock') {
      return this._http.get('/assets/data/mock/sks-timeline.json').toPromise();
    } else if (docid === 'discoverercountrymock') {
      return this._http.get('/assets/data/mock/discoverer-country.json').toPromise();
    } else if (docid === 'discovererprovincemock') {
      return this._http.get('/assets/data/mock/discoverer-province-topic.json').toPromise();
    } else if (docid === 'discovererstatemock') {
      return this._http.get('/assets/data/mock/discoverer-state-topic.json').toPromise();
    } else if (docid === 'discovererbiomock') {
      return this._http.get('/assets/data/mock/discoverer-bio-topic.json').toPromise();
    }
    
    let apiUrl = this.url + '?goid=' + docid + '&db=' + this._appState.get('current_profile');

    if (id) {
      apiUrl += '&id=' + id;
    }
    // DONT log searchId in the doc retrieval usage if through the durable url (if not from the search results)
    // Pass the searchId is valid if the result opened in new tab/window and also refreshing the same page
    if (this._searchId && history.length && history.length !== 2) {
      apiUrl += '&searchId=' + this._searchId;
    }

    return this._http.get(apiUrl)
      .pipe(
        catchError((error: any) => {
          return throwError(error.error || 'Server error');
        })
      )
      .toPromise();
  }

  public getEmsDocLink(docId: string, searchId: string): Observable<any> {

    let emsUrlWithDocId: string = this.emsPrefixUrl + docId + '&db=' + this._appState.get('current_profile');
    // No need to check for history.length since PDFonly link doesn't work in new tab/window.
    // Even if we open PDFOnly link in new tab/window it will display empty content page where there will be no PDF or Save PDF to download.
    // On click of PDF Only it should download the pdf.
    if (searchId) {
      emsUrlWithDocId += '&searchId=' + searchId;
    }

    return this._http.get(emsUrlWithDocId)
      .pipe(
        catchError((error: any)  => throwError(error.error) || 'Server error' )
      );

  }

  public getPdfAvailableDocLink(docId: string, searchId: string): Observable<any> {

    let pdfUrlWithDocId: string = this.url + '/pdfavailable?goid=' + docId + '&db=' + this._appState.get('current_profile');
    // No need to check for history.length since Pdf Available link doesn't work in new tab/window.
    if (searchId) {
      pdfUrlWithDocId += '&searchId=' + searchId;
    }

    return this._http.get(pdfUrlWithDocId)
      .pipe(
        catchError((error: any)  => throwError(error.error) || 'Server error' )
      );

  }

  private _getHeroImageContent (data: Object[]): string {
    return data.filter((section) => {
      return section['key'] === 'section-heroshot';
    })[0]['value'];
  }

  private _isHeroImageLandscape (data: Object[]): boolean {
    return data.filter((section) => {
      return section['key'] === 'section-heroshot';
    })[0]['isLandscape'] !== 'false' || false;
  }

}
