import { Component, OnInit } from '@angular/core';
import { SeoService } from '../_services/seo/seo.service';
import { Router } from '@angular/router';
import { AppState } from '../app.service';

@Component({
  selector: 'pq-update-path',
  templateUrl: './update.path.component.html'
})
export class UpdatePathComponent implements OnInit {
  constructor (
    private _router: Router,
    private _seoSvc: SeoService,
    private _appState: AppState
  ) {
    this._seoSvc.setTitle('Checking URL location');
  }

  public ngOnInit () {
    // this view should never be reached and following code is just fallback
    const profile =  this._appState.get('current_profile');
    let commands = [];

    if (profile) {
      commands.push(profile);
    } else {
      commands.push('portal');
    }

    commands.push('home');

    // sessions without a profile will get redirect to the portal page and sessions with a profile will
    // get redirected to the home page for that product
    this._router.navigate(commands);
  }
}
