import { AppState } from '../app.service';
import { EventLoggingService } from '../_services/log-event/eventlogging.service';
import { ProductService } from '../_services/product/product.service';
import { TokenService } from '../_services/token/token.service';
import { TopicService } from '../_services/topic/topic.service';
import { AuthService } from '../_services/auth/auth.service';
import { UiProfileService } from '../_services/ui-profile/ui-profile.service';
import { Observable, firstValueFrom, lastValueFrom } from 'rxjs';
import { ROUTES } from '../app.routes';
import moment from 'moment';
import { Injectable } from '@angular/core';
import { SeoService } from '../_services/seo/seo.service';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { SessionService } from '../_services/session/session.service';
import { SessionModel } from '../_interfaces';

@Injectable()
export class BootstrapProduct {

  constructor (
    private _appState: AppState,
    private _productSvc: ProductService,
    private _tokenSvc: TokenService,
    private _topicSvc: TopicService,
    private _eventLogSvc: EventLoggingService,
    private _uiProfileSvc: UiProfileService,
    private _seoSvc: SeoService,
    private _sessionSvc: SessionService,
    private _authService: AuthService
  ) {}

  public async executeLoad (): Promise<object> {
    try {
      const requestProducts = await this._productSvc.getAllProducts();
      let isFrom = window.location.href.toString();
      let isPortal = window.location.href.toString().toLowerCase().split('?')[0].indexOf('/portal') !== -1;
      const products = this._createAllProductCollection(requestProducts);
      const allNativeProductsResp = this._getNativeProducts(requestProducts);

      if (this._appState.get('current_profile') === 'portal' || this._appState.get('current_profile') === null) {
        this._appState.set({ current_profile: undefined });
        isPortal = true;
      }

      if (!products.length) {
        //found an error detectable only once logged in, so unauthenticate
        this._unauthenticateUser(isFrom);
        return Promise.resolve({ completed: true, status: 'error', zeroproductserror: true });
      } else {
        this._appState.set({
          grappleProducts: allNativeProductsResp,
          allProducts: this._sortProductAlphabetically(products).filter((product) => {
            return product['accessAllowed'] !== 'false';
          })
        });
        if (!isPortal && typeof this._appState.get('current_profile') !== 'undefined') {
          if (!this._isValidProfile(this._appState.get('current_profile'))) {
            //found an error detectable only once logged in, so unauthenticate
            this._unauthenticateUser(isFrom);
            return Promise.resolve({ completed: true, status: 'error', producterror: true });
          }
        }
        if (!isPortal) {
          this._setProfile();
        }

        isPortal = !this._appState.get('current_profile');

        const loadDataRequest = await this._loadAppData(isPortal);

        return Promise.resolve(loadDataRequest);

      }

    } catch (err) {
      return Promise.resolve({ completed: false, status: 'error' });
    }
  }

  private _unauthenticateUser(isFrom: string) {
    if (isFrom.includes('/login') || isFrom.includes('/barcode')) {
      this._authService.logout();
      sessionStorage.clear();
      Cookie.delete('session', '/');
      Cookie.delete('accountId', '/');
    }
  }

  private _getNativeProducts (allProductsResp: object): object[] {
    let allNativeProductsResp = ( allProductsResp[ 'AllPortalProductsResponse' ]
      && allProductsResp[ 'AllPortalProductsResponse' ][ 'NativeProductsResponse' ]
      && allProductsResp[ 'AllPortalProductsResponse' ][ 'NativeProductsResponse' ][ 'PortalProductResponse' ])
      ? allProductsResp[ 'AllPortalProductsResponse' ][ 'NativeProductsResponse' ][ 'PortalProductResponse' ] : [];

    if (!Array.isArray(allNativeProductsResp)) {
      allNativeProductsResp = [ allNativeProductsResp ];
    }

    return allNativeProductsResp;
  }

  private _setProfile (): void {
    const urlProfile = this._getProfile(document.location.pathname.toLowerCase());

    if (urlProfile && this._isValidProfile(urlProfile)) {
      this._appState.set({ current_profile: urlProfile });
      Cookie.set('profile', urlProfile, null, '/');
    }
  }

  private async _loadAppData (isPortal: boolean): Promise<object> {
    try {
      if (isPortal || typeof this._appState.get('current_profile') !== 'undefined') {
        const promises = this._createProductApiPromiseCollection(isPortal);
        const bootstrap =  await Promise.all(promises);

        // Token Response
        // link for returning to platform
        this._updateAppState(bootstrap[0], 'AuthTokenResponse');

        // Event Logging Response
        const eventtypes = bootstrap[1];

        if (eventtypes && Array.isArray(eventtypes)) {
          this._appState.set({ EVENTTYPES: eventtypes });
        }

        if (!isPortal) {
          const productInfo = bootstrap[ 3 ] as Object;

          // product settings
          this._updateAppState(productInfo, 'ProductInfoResponse');

          if (productInfo && productInfo[ 'description' ]) {
            this._seoSvc.setMetaTag('description', productInfo[ 'description' ]);
          }

          if (productInfo && productInfo[ 'displayname' ]) {
            this._seoSvc.setProduct(productInfo[ 'displayname' ]);
          }

          // ui profile response
          this._handleUIProfileResp(this._appState.get('current_profile'), bootstrap[ 2 ]);

          const topicTreeRequest = await this._topicSvc.getTopicMenu(this._appState.get('current_profile'));
          if (topicTreeRequest[ 'MultiTopicTreeResponse' ] && topicTreeRequest[ 'MultiTopicTreeResponse' ][ 'NamedTreeResponse' ]) {

            let topicTreeResponse = topicTreeRequest[ 'MultiTopicTreeResponse' ][ 'NamedTreeResponse' ];
            let topics = [];

            // convert to array if need not already so
            if (!Array.isArray(topicTreeResponse)) {
              topicTreeResponse = [ topicTreeRequest[ 'MultiTopicTreeResponse' ][ 'NamedTreeResponse' ] ];
            }

            topicTreeResponse.forEach((response: object) => {
              if (response[ 'TopicTreeResponse' ] && response[ 'TopicTreeResponse' ][ 'TopicTreeNode' ]) {
                topics.push({
                  topics: response[ 'TopicTreeResponse' ][ 'TopicTreeNode' ],
                  title: response[ 'title' ],
                  treeName: response[ 'treeName' ]
                });
              }
            });

            const nonfictionbooks = topics.filter((topic) => {
              return topic[ 'treeName' ] === 'nonfictionbooks-detail';
            });

            if (nonfictionbooks.length) {
              this._appState.set({NONFICTION_BOOKS: nonfictionbooks});

              topics = topics.filter((topic) => {
                delete topic[ 'title' ];
                return topic[ 'treeName' ] === 'detail';
              });
            }

            if (topics.length && topics[0]['topics'] && topics[0]['topics'].length) {
              const rootTopics = topics[0]['topics'];
              const totalRootTopics = rootTopics.length;
              // move All Leading Issues to the top for Issues Reseacher
              if (rootTopics[totalRootTopics - 1]['nodeName'] === 'All Leading Issues') {
                rootTopics.unshift(rootTopics.pop());
                topics[0]['topics'] = rootTopics;
              }
            }

            this._appState.set({ROOT_TOPICS: topics});
          }
        }
      }

      return await Promise.resolve({ completed: true, status: 'successful' });
    } catch (err) {
      return await Promise.resolve({ completed: true, status: 'error', data: err });
    }
  }

  private async getBrandingAndPreferences (): Promise<Observable<any>> {
    const session = this._sessionSvc.load();

    return await firstValueFrom(session);
  }

  private _createProductApiPromiseCollection (isPortal: boolean): Promise<any>[] {
    let promises = [];
    const platformBaseAis = [
      this._tokenSvc.get(), // 0
      this._eventLogSvc.getEventTypes(), // 1
    ];

    // include the base api calls
    promises = promises.concat(platformBaseAis);

    // add product related calls
    if (!isPortal) {
      const productApiRequest = [
        this._uiProfileSvc.get(this._appState.get('current_profile')), // 2
        this._getProduct(), // 3
      ];

      promises = promises.concat(productApiRequest);
    }

    return promises;
  }

  private async _getProduct (): Promise<object> {
    return await lastValueFrom(this._productSvc.get(this._appState.get('current_profile')));
  }

  private _updateAppState (resp: object, type: string): void {
    this._appState.set(resp[type] || {});
  }

  // -- getProfile is used to get the profile token from the url when the Angular activatedRoute is not available or can not be used.
  private _getProfile (path: string): string {
    const locationDataArray = path.split('/');
    let isFound = false;

    if (locationDataArray.length >= 2) {
      const testProfile = locationDataArray[1];

      isFound = ROUTES.filter((route) => {
        return route['path'].startsWith(testProfile);
      }).length >= 1;

      if (!isFound) {
        return testProfile;
      }

      return undefined;
    }

    return undefined;
  }

  private _handleUIProfileResp (profile: string, profileData: object): void {
    if (profile && this._isValidProfile(profile)) {
      this._appState.set({ current_profile: profile.toLowerCase() });
    }

    if (typeof profileData['profile'] !== 'undefined' && profileData['profile'].length >= 1) {
      const data = { profile: {} };
      const currentProfile = this._appState.get('current_profile');

      if (currentProfile) {
        data['profile'][currentProfile] = { settings: profileData['profile'], creation_date: moment() };
      } else {
        // when is default used
        data['profile']['default'] = { settings: profileData['profile'], creation_date: moment() };
      }
      this._appState.set(data);
    }
  }

  private _createAllProductCollection (resp: object): object[] {
    let products = [];

    if (resp['AllPortalProductsResponse']) {
      const dataObject = resp[ 'AllPortalProductsResponse' ];

      for (const key in dataObject) {
        const productResp = dataObject[ key ][ 'PortalProductResponse' ] || [];
        const productArray = Array.isArray(productResp) ? productResp : [ productResp ];
        products = products.concat(productArray);
      }
    }

    return products;
  }

  private _sortProductAlphabetically (products: object[]): object[] {
    return products.sort((a: object, b: object) => {
      const aName = a['name'].toLowerCase();
      const bName = b['name'].toLowerCase();

      if (aName < bName) {
        return -1;
      }

      if (aName > bName) {
        return 1;
      }

      return 0;
    });
  }

  private _isValidProfile (requestedProfile: string) {
    let allProducts = this._appState.get('grappleProducts') || [];
    if (!Array.isArray(allProducts)) {
      allProducts = [ allProducts ];
    }

    return allProducts.filter((product) => {
      return product && product['profile'] === requestedProfile;
    }).length  >= 1;
  }
}
