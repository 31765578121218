import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  Injector
} from '@angular/core';

import { ModalService } from './modal.service';

@Component({
  selector: 'pq-modal-placeholder',
  templateUrl: 'modal.component.html'
})
export class ModalPlaceholderComponent implements OnInit {
  public title: string = 'Modal Title';

  @ViewChild('pqmodalcontent', { read: ViewContainerRef, static: false }) public viewContainerRef: any;

  constructor (private _modalService: ModalService,
               private _injector: Injector) {
  }

  public ngOnInit (): void {
    this._modalService.setViewContainerRef(this.viewContainerRef);
    this._modalService.setInjector(this._injector);

  }
}
