import { Injectable } from '@angular/core';

@Injectable()
export class QueryString {
  private _queryString: Object = {};
  private _query: string = window.location.search.substring(1);
  private _vars: string[] = this._query.split('&');

  public getQueryString (url?: string): Object {
    this._queryString = {};
    
    if (url) {
      this._query = url.split('?')[1];
      
      if (this._query) {
        this._vars = this._query.split('&');
      } else {
        this._vars = [];
      }
    }

    for (let value of this._vars) {
      let pair = value.split('=');
      
      // If first entry with this name
      if (typeof (<any> this._queryString)[pair[0]] === 'undefined') {
        this._queryString[pair[0]] = decodeURIComponent(pair[1]);
        
        // If second entry with this name
      } else if (typeof (<any> this._queryString)[pair[0]] === 'string') {
        let arr = [(<any> this._queryString)[pair[0]], decodeURIComponent(pair[1])];
        (<any> this._queryString)[pair[0]] = arr;
        // If third or later entry with this name
      } else {
        (<any> this._queryString)[pair[0]].push(decodeURIComponent(pair[1]));
      }
    }

    return this._queryString;

  }

}
