import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppState } from '../../app.service';

@Injectable()
export class InlineImageService {
  public document: Object;

  private url: string = '/svc/grapple/docretrieve/fulltext/inlineimages';

  constructor (
    private _http: HttpClient,
    private _appState: AppState
  ) {}

  public get (docid: string, mediaId: string): Promise<any> {
    let imageUrl = this.url + '?goid=' + docid;
    if (mediaId) {
      imageUrl = imageUrl + '&id=' + mediaId;
    }
    return this._http.get(imageUrl + '&db=' + this._appState.get('current_profile')).toPromise();
  }

}
