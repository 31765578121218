import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, } from '@angular/router';

import { SessionService } from '../_services';
import { AppState } from '../app.service';
import { Observable } from 'rxjs';

@Injectable()
export class ErrorGuard implements CanActivate {
  constructor (
    public appState: AppState,
    private _sessionService: SessionService,
    private _router: Router
  ) {}

  public canActivate (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    let  status = this.appState.get('status');

    return (() => {
      if (status !== '504') {
        this._router.navigate(['home']);
      }

      return true;
    })();
  }
}
