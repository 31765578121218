<footer class="footer-site" id="container-siteFooterComponent">
    <div class="container-footerInner">
        <div>
            <span role="img" aria-label="Logo: ProQuest - Part of Clarivate" class="footer-logo img-responsive"></span>
        </div>
        <div>
            <div class="container-footerNav">
                <pq-site-branding></pq-site-branding>
                <ul>
                    <li><a (click)="pushFooterNavClickToGA('Contact us')" href="http://support.proquest.com/#submitcase" target="_blank">Contact Us</a></li>
                    <li><a (click)="pushFooterNavClickToGA('Terms and Conditions')" href="http://www.proquest.com/go/termsandconditions" target="_blank">Terms and Conditions</a></li>
                    <li><a (click)="pushFooterNavClickToGA('Privacy Policy')" href="http://www.proquest.com/go/privacy" target="_blank">Privacy Policy</a></li>
                    <li><a (click)="pushFooterNavClickToGA('cookie Policy')" href="http://www.proquest.com/go/cookie" target="_blank">Cookie Policy</a></li>
                    <li><a (click)="pushFooterNavClickToGA('Accessibility')" [href]="getAccessibilityDocumentURL()" target="_blank">Accessibility</a></li>
                    <li>
                      <!-- OneTrust Cookies Settings button start -->
                      <button class="ot-sdk-show-settings btn btn-link btn-action">Cookie Settings</button>
                      <!-- OneTrust Cookies Settings button end -->
                    </li>
                    <li *ngIf="!showCAPTCHA && getPlatformUrl()"><a (click)="pushFooterNavClickToGA('Platform Url')" [href]="getProductPlatformUrl()" target="_blank">{{ platformLinkText }}</a></li>
                    <li *ngIf="showCAPTCHA"><button class="btn btn-link btn-action" (click)="handleCAPTCHACheck()">{{ platformLinkText }}</button></li>
                    <li *ngIf="showLogout()"><button class="btn btn-link btn-action" (click)="doLogout()">Logout</button></li>
                </ul>

            </div>

            <div [innerHTML]="copyright" class="container-copy"></div>
        </div>
    </div>
</footer>
