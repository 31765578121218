import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { AppState } from '../../app.service';

@Injectable()
export class SeoService {
  public product: string = '';
  public company: string = 'ProQuest';
  
  private _baseTitle: string = this.company;

  constructor (
    private _titleService: Title,
    private _meta: Meta,
    private _appState: AppState
  ) {}

  public setTitle (title: string): void {
    this.product = this._appState.get('displayname');
  
    const isPortal = document.location.href.split('?')[0].toLowerCase().indexOf('/portal') !== -1;
    let baseTitle = this._baseTitle;
    
    if (!isPortal && this.product) {
      baseTitle += ' :: ' + this.product;
    }
    
    baseTitle += ' ~ ';
    title = title.replace('&shy;', '');

    this._titleService.setTitle(baseTitle + title);
    this._appState.set({gaPageTitle : baseTitle + title});
  }

  public setCompany (company: string): void {
    this.company = company;
  }

  public setProduct (product: string): void {
    this.product = product;
  }

  public setMetaTag (name: string, content: string) {
    const metaTag = this._meta.getTag(name);

    if (metaTag) {
      this._meta.removeTag(name);
    }

    this._meta.addTag({ name, content });

  }
}
