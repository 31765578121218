import { CommonModule }   from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { CaptchaComponent } from './captcha.component';
import { CaptchaService } from './captcha.service';
import { RecaptchaService } from './recaptcha.service';

export * from './captcha.component';
export * from './captcha.service';

@NgModule({
  declarations: [
    CaptchaComponent
  ],
  providers: [ CaptchaService, RecaptchaService ],
  exports: [ CaptchaComponent ],
  imports: [ CommonModule, FormsModule ]
})
export class CaptchaModule {}
