import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class SearchFilterService {
  private _defaultSortBy: string = 'relevance';
  private _subscription: Subscription;
  private _sortBy: Subject<string> = new BehaviorSubject<string>(this._defaultSortBy);
  private _sourceType: Subject<string> = new BehaviorSubject<string>(null);
  private _limitTo: Subject<string[]> = new BehaviorSubject([]);
  private _publicationDate: Subject<Object> = new BehaviorSubject<Object>(null);
  private _currencyFilter: Subject<boolean> = new BehaviorSubject<boolean>(null);

  constructor (
    private _route: ActivatedRoute,
  ) {
    this._subscription = _route.queryParams.subscribe((queryParam: any) => {
        let sourceType = queryParam['source'] || '';
        let sortBy = queryParam['sort'] ? queryParam['sort'] : 'relevance';
        let limitTo = queryParam['limit'] ? decodeURIComponent(queryParam['limit']).split('|') : [];
        let publication = (queryParam['pubstartdate'] && queryParam['pubenddate']) ?
          { pubstartdate: queryParam['pubstartdate'], pubenddate: queryParam['pubenddate'] } : {};
        let currencyFilter = queryParam['currencyfilter'] ? queryParam['currencyfilter'] : true;

        this._sourceType.next(sourceType);
        this._sortBy.next(sortBy);
        this._limitTo.next(limitTo);
        this._publicationDate.next(publication);
        this._currencyFilter.next(currencyFilter);
      }
    );
  }

  public getSortBy (): Observable<string> {
    return this._sortBy.asObservable();
  }

  public getSourceType (): Observable<string> {
    return this._sourceType.asObservable();
  }

  public getLimitTo (): Observable<string[]> {
    return this._limitTo.asObservable();
  }

  public getPublicationDate (): Observable<Object> {
    return this._publicationDate.asObservable();
  }

  public getDefaultSortBy (): string {
    return this._defaultSortBy;
  }

  public setDefaultSortBy (sortBy: string) {
    this._defaultSortBy = sortBy;
  }

  public getCurrencyFilter (): Observable<boolean> {
    return this._currencyFilter.asObservable();
  }
}
