import { Component, ViewEncapsulation } from '@angular/core';
import { AppState } from '../../app.service';
import { AuthService } from '../../_services';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { PortalLinkDialogComponent } from '../../home/portal/portal-link-dialog.component';
import { environment} from '../../../environments/environment';
import { ProductCheck } from '../../_utilities';

declare var dataLayer: any;

@Component({
  selector: 'pq-site-footer',
  templateUrl: './site.footer.component.html',
  styleUrls: ['./_site.footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SiteFooterComponent {

  public platformLinkText: string = this.appState.get('displayname') && this.appState.get('displayname') !== 'ProQuest' ?
    this.appState.get('displayname') + ' on the ProQuest Platform' : 'ProQuest Platform';
  public copyright: string = 'Copyright &copy ' + new Date().getFullYear() + ' ProQuest LLC.';
  public showCAPTCHA: boolean = this.appState.get('sessionType') === 'USERNAMEPASS';

  constructor (
    public appState: AppState,
    private _authService: AuthService,
    private _dialog: MatDialog,
    private _productCheck: ProductCheck
  ) {}

  public doLogout () {
    if (dataLayer && Array.isArray(dataLayer)) {
      dataLayer.push({
        'event': 'GlobalEvent',
        'category': 'Footer',
        'action': 'Navigation',
        'label': 'Logout'
      });
    }
    this._authService.logout().pipe(
      catchError((error: any): any => {
        // keep for testing reasons
        // console.log(error, 'error');
      })
    ).subscribe((resp) => {
      // keep for testing reasons
      // console.log(resp, 'success');
    });
  }

  public pushFooterNavClickToGA(label: String) {
    if (dataLayer && Array.isArray(dataLayer)) {
      dataLayer.push({
        'event': 'GlobalEvent',
        'category': 'Footer',
        'action': 'Navigation',
        'label': label
      });
    }
  }

  public showLogout (): boolean {
    let sessionObject = sessionStorage.getItem('sessionObject')
      ? JSON.parse(sessionStorage.getItem('sessionObject')) : {};

    return (typeof sessionObject['sessionId'] !== 'undefined') && ((!environment.production)
      || (window.location.href.indexOf('//guided.dev.int.proquest.com') !== -1));
  }

  public getPlatformUrl (): string {
    return this.showCAPTCHA ?  undefined : this.appState.get('platformUrl');
  }

  public getProductPlatformUrl (): string {
       const token = this.appState.get('platformUrl')  && (this.appState.get('platformUrl').split('?')[0].split('/embedded/').length === 2) ? this.appState.get('platformUrl').split('/embedded/')[1] : undefined;
       let url = this.appState.get('platformUrlPrefix') + '/' + this.appState.get('moniker')  + '/embedded/' + token ;
       return url;
  }

  public handleCAPTCHACheck (): void {
    const productPlatformValue = 'proquest';
    this._dialog.open(PortalLinkDialogComponent, {
      disableClose: true,
      data: {
        profile: this.appState.get('current_profile'),
        platform: productPlatformValue
      }
    });
  }

  public getAccessibilityDocumentURL(): string {
    let url = "https://about.proquest.com/company/ProQuest-ADA-Compliance.html";
    if(this.appState.get('moniker')) {
        url = "https://support.proquest.com/articledetail?id=kA23r000000FWdJCAW";
    }
    return url;
  }
}
