import { Injectable } from '@angular/core';
import { Cookie } from 'ng2-cookies/ng2-cookies';

declare var dataLayer: any;

@Injectable()
export class GoogleAnalyticsService {

    public sendPageViewToGA(pagePathName: string, pageVariation: string, title: string) {
      if (pagePathName && pagePathName !== 'undefined' && pagePathName !== '') {
        pageVariation = this._getPageVariation(pagePathName, pageVariation);
        let pageParameters = this._getPageParameters();
        if (dataLayer && Array.isArray(dataLayer)) {
          dataLayer.push({
            'event': 'VirtualPageview',
            'virtualPageURL': pagePathName,
            'virtualPageTitle': title,
            'pageParameters': pageParameters,
            'pageVariation': pageVariation
          });
        }
      }
    }

    private _getPageVariation(page: string, pageVariation: string) {

      if (page === '/' || page.startsWith('/login') || page.startsWith('/barcode')) {
        const sessionObject = sessionStorage.getItem('sessionObject')
        ? JSON.parse(sessionStorage.getItem('sessionObject')) : {};
        if (sessionObject) {
          let sessionType = sessionStorage.getItem('gaSessionType');
          pageVariation = (sessionType === 'usernamepass') ? 'Manual'
                              : (sessionType === 'barcode') ? 'Barcode'
                              : 'Automatic';
        }
      }
      return pageVariation;
    }

    private _getPageParameters() {
      let pageParameters = '';
      if (window.location.href.indexOf('?') !== -1) {
        pageParameters = window.location.href.substring(window.location.href.indexOf('?') + 1);
      }
      if (pageParameters.toLowerCase().indexOf('accountid') === -1) {
        const accountId = Cookie.get('accountId');
        if (accountId) {
          if (pageParameters.length > 0) {
            pageParameters = pageParameters + '&accountid=' + accountId;
          } else {
            pageParameters = 'accountid=' + accountId;
          }
        }
      }
      return pageParameters;
    }
}
