import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, lastValueFrom, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import XMLString from '../../_utilities/xmlstring';
import { AppState } from '../../app.service';
import { ProductCheck } from '../../_utilities/product.check';

@Injectable()
export class TopicService {

  private _url: string = '/svc/grapple/multitopictree/';

  constructor (
    private _http: HttpClient,
    private _appState: AppState,
    private _productCheck: ProductCheck
  ) {}

  public async getTopicMenu (moniker: string): Promise<any> {
    const url = this._url + 'menu/' + moniker;
    return await firstValueFrom(this._http.get(url));
  }

  public async getTopicHome (moniker: string): Promise<any> {
    const url = this._url + 'homepage/' + moniker;
    return await lastValueFrom(this._http.get(url));
  }

  public get (value: string, orderByTrending: boolean, orderByLastUpdated: boolean, offset: number, size?: number): Observable<object[] | object | string> {
    let postBody = '<TopicTreeRequest>' +
                    XMLString.xmlwrap(this._appState.get('current_profile'), 'product', false) +
                    XMLString.xmlwrap( value, 'nodeId', false) +
                    XMLString.xmlwrap('true', 'fetchChildResearchTopics', false) +
                    XMLString.xmlwrap(String(orderByTrending), 'orderByTrending', false) +
                    XMLString.xmlwrap(String(orderByLastUpdated), 'orderByLastUpdated', false) +
                    XMLString.xmlwrap(String(offset), 'offset', false);

    if (size) {
      postBody += XMLString.xmlwrap(String(size), 'size', false);
    }

    postBody += '</TopicTreeRequest>';

    let url = '/svc/grapple/topictree';
    if (this._productCheck.isDiscoverer(this._appState.get('current_profile'))) {
      url = '/svc/grapple/multitopictree';
    }

    return this._http.post(url, postBody)
      .pipe(
        catchError((error: any) => {
          return throwError(error.error || 'Server error');
        })
      );
  }

  public getTopicTree (value: string, type: string = 'nodeId'): Observable<object[] | object> {
    const postBody = '<TopicTreeRequest>' +
                    XMLString.xmlwrap(this._appState.get('current_profile'), 'product', false) +
                    XMLString.xmlwrap( value, type, false) +
                    XMLString.xmlwrap('true', 'fetchBreadcrumbs', false) +
                    '</TopicTreeRequest>';

    return this._http.post('/svc/grapple/topictree', postBody)
      .pipe(
        catchError((error: any) => {
          return throwError(error.error || 'Server error');
        })
      );
  }
}
