import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import XMLString from '../../_utilities/xmlstring';
import { Cookie } from 'ng2-cookies/ng2-cookies';

@Injectable()
export class CaptchaService {
  private _url: string = '/svc/grapple/displaycaptcha';

  constructor (
    private _http: HttpClient,
  ) {}

  public validate (text: string): Promise<any> {
    let postBody = '<ValidateCaptchaRequest>';

    postBody += XMLString.xmlwrap(Cookie.get('session'), 'id', false);
    postBody += XMLString.xmlwrap(text, 'text', false);
    postBody += '</ValidateCaptchaRequest>';

    return this._http.post(this._url, postBody).toPromise();
  }

  public getImage(): Promise<Blob> {
    return this._http.get(this._url, { responseType: 'blob' }).toPromise();
  }

  public reset (key: string): Promise<any> {
    let postBody = this.createPostBody(key);
    return this._http.post('/svc/grapple/captcha', postBody).toPromise();
  }

  public createPostBody (key: string): string {
    let postBody = '<CaptchaRequest>';

    postBody += XMLString.xmlwrap('true', 'successAttempt', false);
    postBody += XMLString.xmlwrap('true', 'reset', false);
    postBody += XMLString.xmlwrap(key, 'sKey', false);

    postBody += '</CaptchaRequest>';

    return postBody;
  }
}
