import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppState } from '../../app.service';

declare var hello: any;

@Injectable()
export class MicrosoftAuthService {
  public static readonly DEFAULT_ONEDRIVE_URL = 'https://onedrive.live.com';

  private _isLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _appId: string;
  private _userDriveUrl: string = MicrosoftAuthService.DEFAULT_ONEDRIVE_URL;

  constructor (
    private _appState: AppState
    ) {}

  public initMsAuth (): BehaviorSubject<boolean> {
    const uri = this._createRedirectUri();

    if (this._appState.get('externalDriveInfo')
      && this._appState.get('externalDriveInfo')['oneDriveInfo']
      && this._appState.get('externalDriveInfo')['oneDriveInfo']['clientId']) {

      this._appId = this._appState.get('externalDriveInfo')['oneDriveInfo']['clientId'];
    }

    try {
      if (this._appId) {

        // Configure hello.js to use MS Graph API...
        hello.init(
          {
            msft: {
              id: this._appId,
              oauth: {
                version: 2,
                auth: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize'
              },
              scope_delim: ' ',
              form: false,
              base: 'https://graph.microsoft.com/v1.0/',
              xhr: function(p: any): boolean {
                var token = p.query.access_token;
                delete p.query.access_token;
                if (token) {
                  // Graph API expects the access token as an auth header vs as a query param...
                  p.headers = {
                    'Authorization': `Bearer ${token}`
                  };
                }

                return true;
              }
            },
          },
          { response_type: 'token', redirect_uri: uri }
        );

        this._isLoaded.next(true);
      } else {
        this._isLoaded.next(false);
      }


    } catch (err) {
      this._isLoaded.next(false);
    }

    return this._isLoaded;
  }

  public login (): Promise<any> {
    return hello('msft').login({
      scope:  'openid User.Read Files.ReadWrite Files.ReadWrite.All Sites.ReadWrite.All',
      prompt: 'login'
    });
  }

  public logout (): Promise<any> {
    return hello('msft', { force: true }).logout().then (() => {
      this._userDriveUrl = MicrosoftAuthService.DEFAULT_ONEDRIVE_URL;

      const msLink = 'https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=';
      let url = msLink + window.location.protocol + '//' + window.location.hostname;

      if (window.location.port) {
        url = url + ':' + window.location.port;
      }

      url += '/static/msoauthlogout.html';

      window.open(url);
    });
  }

  public isLoggedIn (): boolean {
    const session = hello('msft').getAuthResponse();
    return this._isOnline(session);
  }

  public getUserInfo (): Promise<any> {
    const user = hello('msft').api('/me');
    return user;
  }

  public setUserDriveInfo (): Promise<any> {
    const driveInfo = hello('msft').api('/me/drive/root').then( driveInfo => {
      if (driveInfo && driveInfo['webUrl']) {
        this._userDriveUrl = encodeURI(driveInfo['webUrl']);
      } else {
        this._userDriveUrl = MicrosoftAuthService.DEFAULT_ONEDRIVE_URL;
      }
    }).catch( err => {
      // If the endpoint fails to retrieve a user's drive info, we set to the default user drive url to make sure the previous value
      //  is reset. This case would be indicative of something unexpected happening...
      this._userDriveUrl = MicrosoftAuthService.DEFAULT_ONEDRIVE_URL;
    });

    return driveInfo;
  }

  public getUserDriveUrl (): string {
    return this._userDriveUrl;
  }

  public getAccessToken (): string {
    return hello('msft').getAuthResponse().access_token;
  }

  public getExpiresIn (): string {
    let time = hello('msft').getAuthResponse().expires_in || '';

    if (typeof time.toString !== 'undefined') {
      time = time.toString();
    }

    return time;
  }

  private _isOnline (session: object): boolean {
    let currentTime = (new Date()).getTime() / 1000;
    return session && session['access_token'] && (session['expires'] > currentTime);
  }

  private _createRedirectUri (): string {
    let uri = window.location.protocol + '//' + window.location.hostname;

    if (window.location.port) {
      uri = uri + ':' + window.location.port;
    }

    // default location for the redirect uri is the root of the app. Could add a redirect uri page but it would only
    // have a loader graphic becuase all navigation is handled by the responses from api calls
    uri = uri + '/';

    return uri;
  }
}
