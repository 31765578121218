import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'pqSafeUrl'})
export class SafeUrlPipe implements PipeTransform  {
  constructor(private _sanitized: DomSanitizer) {}
  transform(value) {
    return this._sanitized.bypassSecurityTrustResourceUrl(value);
  }
}
