import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppState }  from '../../../app.service';

declare var dataLayer: any;

@Component({
  selector: 'pq-google-link',
  templateUrl: './google.link.component.html',
  styleUrls: ['./_google.link.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class GoogleLinkComponent implements OnInit {
  public statusText: object = {
    logged_in: 'Log Out',
    logged_out: 'Log In',
    default_tooltip: 'Log in with Google',
    signed_in_tooltip_pre: 'Log out '
  };

  public tooltipText: BehaviorSubject<string> = new BehaviorSubject<string>(this.statusText['default_tooltip']);
  public isAvailable: boolean = false;
  public loading: boolean = false;
  public btnText: BehaviorSubject<string> = new BehaviorSubject<string>(this.statusText['logged_out']);
  public logInStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _username: string = this.appState.get('google') ? this.appState.get('google')['username'] : undefined;
  private _isLoggedIn: boolean = false;

  constructor (
    public appState: AppState,
  ) {}

  public ngOnInit () {
    this.loading = true;

    this.logInStatus.subscribe((status) => {
      this._isLoggedIn = status;

      if (status) {
        this._handleLogIn();
      } else {
        this._handleLogout();
      }
    });

    this.appState.stateStatus.subscribe((state: Object) => {
      if (typeof state['google'] !== 'undefined') {
        const googleStatus = state['google'];

        if (typeof googleStatus['isLoaded'] !== 'undefined') {
          this.isAvailable = googleStatus['isLoaded'];
          this.loading = false;
        }

        if (typeof googleStatus['isWorking'] !== 'undefined') {
          this.loading = googleStatus['isWorking'];
        }

        if (typeof googleStatus['isSignedIn'] !== 'undefined') {
          if (this.logInStatus.getValue() !== googleStatus['isSignedIn']) {
            this.logInStatus.next(googleStatus['isSignedIn']);

            if (googleStatus['username']) {
              this.tooltipText.next(this.statusText['signed_in_tooltip_pre'] +  googleStatus['username']);
            } else {
              this.tooltipText.next(this.statusText['signed_in_tooltip_pre']);
            }
          }
        }

      }
    });
  }

  public toggleGoogleAccountLink (e: Event): void {
    if (this.loading === false && this.isAvailable) {
      this.loading = true;

      // if signed in and connected
      if (this._isLoggedIn) {
        if (Array.isArray(dataLayer)) {
          dataLayer.push({
            'event': 'GlobalEvent',
            'category': 'Google Account',
            'action': 'Google Login',
            'label': 'Logout'
          });
        }
        this.appState.set({ google: { requestType: 'signOut' } });

      } else {
        if (Array.isArray(dataLayer)) {
          dataLayer.push({
            'event': 'GlobalEvent',
            'category': 'Google Account',
            'action': 'Google Login',
            'label': 'Login Attempt'
          });
        }
        this.appState.set({ isGoogleFromHeaderLink: true, isGoogleFromSaveToCloudOverlay: false, google: { requestType: 'signIn' } });
      }
    }
  }

  private _handleLogout (): void {
    this.loading = false;
    this.btnText.next(this.statusText['logged_out']);
    this._username = undefined;
    this.tooltipText.next(this.statusText['default_tooltip']);
  }

  private _handleLogIn (): void {
    this.btnText.next(this.statusText['logged_in']);
    if (Array.isArray(dataLayer)) {
      if (this.appState.get('isGoogleFromSaveToCloudOverlay') === true) {
        dataLayer.push({
          'event': 'SaveToCloudOverlay',
          'label': 'Google Drive',
          'action': 'Google Login'
        });
      } else if (this.appState.get('isGoogleFromHeaderLink') === true) {
        dataLayer.push({
          'event': 'GlobalEvent',
          'category': 'Google Account',
          'action': 'Google Login',
          'label': 'Login'
        });
      }
    }

    this.tooltipText.next(this.statusText['signed_in_tooltip_pre']);
  }
}
