<section class="container-findInstitution">
  <mat-dialog-content *ngIf="!isWorking">
    <button (click)="pushFindInstitutionCloseToGA()" [mat-dialog-close]="true" class="btn-close" aria-label="find your institution - close">
      <svg class="gr-cancel-squared">
        <use xlink:href="./assets/img/icons.svg#gr-cancel-squared"></use>
      </svg>
    </button>

    <h3 mat-dialog-title tabindex="-1" id="title-dialog">Find your institution</h3>
    <div class="container-findInstitutionContent">
      <ng-container *ngIf="previousInstitution">
        <p>Access through the same institution used last time on this device or<br> <button type="button" class="btn btn-link" (click)="clearPreviousInstitution()">choose a different institution.</button></p>
      </ng-container>
      <ng-container *ngIf="!previousInstitution">
        <p>This is usually the name of your school, organization, or company.</p>
        <form method="post" action="." (submit)="handleSubmit($event)">
          <fieldset>
            <div  class="input-group">
              <input type="text" value=""
                     class="form-control"
                     name="institutionName"
                     aria-label="Your institution's name"
                     #institutionName
                     autocomplete="off"
                     placeholder="Your institution's name">
              <div class="input-group-btn">
                <button type="submit" aria-label="search button" class="btn btn-default">
                  <svg class="gr-search" xmlns="http://www.w3.org/2000/svg">
                    <use xlink:href="./assets/img/icons.svg#gr-search"></use>
                  </svg>
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </ng-container>
      <div *ngIf="!isLoading && results && results.length" class="container-results">
        <ul [attr.aria-label]="results.length + 'matching institutions displayed below'" aria-live="assertive">
          <li *ngFor="let item of results; index as i; trackBy: trackByFn">
            <a [href]="item.url" (click)="handleSaveInstitution(item.name)">{{item.name}}</a>
            <button type="button" class="btn btn-clear" (click)="handleDeleteInstitution()" [attr.aria-label]="'Deselect ' + previousInstitution" *ngIf="previousInstitution">
              <svg class="gr-cancel-squared">
                <use xlink:href="./assets/img/icons.svg#gr-cancel-squared"></use>
              </svg>
            </button>
          </li>
        </ul>
        <div class="alert alert-warning" aria-live="polite" *ngIf="results.length >= 10">
          <p>
            <b>Your search found over 10 institutions. Please try a new search with more specific terms.</b><br>
            If your institution is not listed here, check with your library or help desk.
          </p>
        </div>
      </div>

      <pq-loading [loading]="isLoading" [error]="hasError" [msg]="msg"></pq-loading>
    </div>
    <div *ngIf="!isLoading && results && results.length === 0" class="container-results">
      <div class="alert alert-danger" role="alert" aria-live="assertive">
        <p>
          <b>Your search found 0 institutions.</b><br>
          Please try a new search with different terms.
        </p>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-content>
    <pq-loading [loading]="isWorking"></pq-loading>
  </mat-dialog-content>
</section>
