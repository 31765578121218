import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../_services/auth/auth.service';
import { SeoService } from '../_services/seo/seo.service';
import { SessionService } from '../_services/session/session.service';
import { ProductService } from '../_services/product/product.service';
import { TokenService } from '../_services/token/token.service';
import { TopicService } from '../_services/topic/topic.service';
import { EventLoggingService } from '../_services/log-event/eventlogging.service';
import { AppState } from '../app.service';

@Component({
  selector: 'pq-login',
  templateUrl: './login.component.html',
  styleUrls: ['./_login.component.scss']
})
export class LoginComponent {
  public multipleMatchingAccount: Array<object> = this.appState.get('multipleMatchingAccount') || [];
  public title: string = this.multipleMatchingAccount.length ? 'Select your school or institution' : 'Login';
  public accountId: string = undefined;
  public profile: string = this.appState.get('current_profile') || '';
  public redirectLocation: string = '';

  constructor (
    public appState: AppState,
    private _authService: AuthService,
    private _seoSvc: SeoService,
    private _sessionService: SessionService,
    private _productService: ProductService,
    private _tokenSvc: TokenService,
    private _topicSvc: TopicService,
    private _eventLogSvc: EventLoggingService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    if (!Array.isArray(this.multipleMatchingAccount)) {
      this.multipleMatchingAccount = [this.multipleMatchingAccount];
    }

    this._setPageTitle(this.title);
    this._route.queryParams.subscribe((queryParam: any) => {
      this.redirectLocation = queryParam['location'];
      this.accountId = queryParam['accountid'];
      this.profile = this._route.snapshot.params['profile'];
    });
  }

  private _setPageTitle (title): void {
    this._seoSvc.setTitle(title);
  }
}
