import { Injectable } from '@angular/core';

import { ResourceTypeModel } from '../../_interfaces';

@Injectable()
export class ResourceTypeService {

  private resourceType: ResourceTypeModel[] = [];

  public set (options: any[]) {
    this.resourceType = options;
  }

  public get (id?: number): Object[] | Object {
    if (id) {
      return this.resourceType.filter((type) => {
          return type.id === id;
        })[0] || {};
    } else {
      return this.resourceType;
    }
  }

  public getIcon (title: string): string {
    const resource = this.resourceType.filter((type) => {
      title = title.replace('[[mkup]]', '');
      return title.toLowerCase().indexOf(type.label.toLowerCase()) !== -1;
    });

    return resource.length ? resource[0].icon : 'gr-document';
  }

  public setCount (count: number, name: string): void {
    const resource = this.resourceType.filter((type) => {
      if (type.label.toLowerCase() === name.toLowerCase()) {
        type.count = count;
      }
    });
  }
}
