import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import XMLString from  '../../_utilities/xmlstring';
import { DocumentAttributesModel } from '../../_interfaces/document/document.model';
import { AppState } from '../../app.service';

@Injectable()
export class EmailDocumentService {
  private _url: string = '/svc/grapple/email';

  constructor (
    private _http: HttpClient,
    private _appState: AppState
  ) {}

  public send (goid: string, email: string, attachedPdf: boolean = false, postData: DocumentAttributesModel): Promise<any> {
    let data = '<EmailRequest>'
      + XMLString.xmlwrap(goid.toString(), 'goid', false)
      + XMLString.xmlwrap(email, 'toAddress', false)
      + XMLString.xmlwrap(attachedPdf ? 'true' : 'false', 'doAttachPdf', false);
    data += '<attributes>';
    for (let prop in postData) {
      if (postData.hasOwnProperty(prop) && postData[prop]) {
        data += XMLString.xmlwrap(postData[prop].toString(), prop, false);
      }
    }
    data += '</attributes></EmailRequest>';

    return this._http.post(this._url + '?db=' + this._appState.get('current_profile'), data).toPromise();
  }
}
