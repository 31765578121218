import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { ConfigService, SessionService } from '../_services';
import { AppState } from '../app.service';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { BootstrapProduct } from '../_utilities';

@Injectable ()
export class AuthCheckGuardService implements CanActivate {

  constructor (
    public appState: AppState,
    private _sessionService: SessionService,
    private _router: Router,
    private _bootstrapProduct: BootstrapProduct,
    private _configSvc: ConfigService
  ) {
  }

  static removeQueryStringParameter (key: string, queryParamLocation: string): string {
    let location = queryParamLocation;
    let regex = new RegExp('([?&])' + key + '=.*?(&|#|$)', 'i');

    if (location.match(regex)) {
      // REMOVE KEY AND VALUE
      location = location.replace(regex, '$1');

      // REMOVE TRAILING ? OR &
      location = location.replace(/([?&])$/, '');
    }
    return location;
  }

  public canActivate (route : ActivatedRouteSnapshot, state : RouterStateSnapshot ): Observable<boolean> | Promise<boolean> | boolean {

    if (route.params && route.params.profile && this._isValidProfile(route.params.profile)) {
      this.appState.set({ current_profile: route.params.profile.toLowerCase() });
    }

    return this._sessionService.loggedInCheck().pipe(
      first(),
      map( (isLoggedIn: boolean) => {
        const status = Number.parseInt(this.appState.get('status'));
        const profile = this.appState.get('current_profile') || '';
        const commands = [];

        // do proxy redirect if required
        const defaultLoginUrl = sessionStorage.getItem('defaultLoginUrl');
        const url = decodeURIComponent(window.location.href);

        if (( status === 504 ) || ( status === 503 )) {
          if (profile) {
            commands.push(profile);
          }

          commands.push('error');
          this._router.navigate(commands, {replaceUrl: true});
          return true;

        } else if (defaultLoginUrl && !isLoggedIn) {
          const barcodePath = '/barcode?';
          const loginPath = '/login?';

          if (( defaultLoginUrl.includes(barcodePath) || defaultLoginUrl.includes(loginPath) )
            && defaultLoginUrl.toLowerCase().includes('accountid')) {

            if (defaultLoginUrl.includes(barcodePath) && !url.includes(barcodePath)) {
              this._updateAuthLoginUrl('barcode', defaultLoginUrl, profile, url);
            } else if (defaultLoginUrl.includes(loginPath) && !url.includes(loginPath)) {
              this._updateAuthLoginUrl('login', defaultLoginUrl, profile, url);
            }

          } else if (defaultLoginUrl.includes('?')) {
            // In PAM if default auth url is http://ezproxy.qa.proquest.com?url for an accountid
            // And if we access = http://localhost:3000/elibrary/document/3521734?accountid=93106
            // document.location.href is going to be the same as above
            let uri = decodeURI(window.location.href);
            // However if we access = http://localhost:3000/elibrary/?accountid=93106
            // this url will be navigated through no-content-guard. Hence the url will have location params.
            const urlParams = decodeURIComponent(window.location.search);

            if (urlParams && urlParams.includes('location=/')) {
              const locationParams = urlParams.substr(urlParams.indexOf('location=/') + 10);
              uri = decodeURI(window.location.href) + locationParams;
            }

            const newQueryParams = AuthCheckGuardService.removeQueryStringParameter('searchId', uri);

            window.location.replace(defaultLoginUrl  + newQueryParams);
          } else {
            // In PAM if default proxy url is http://fpizzo1.wixsite.com/home
            window.location.replace(defaultLoginUrl);
          }
        } else if (isLoggedIn) {
          this._configSvc.get().then((configResp) => {
            this.appState.set(configResp);

            if (this.appState.get('accountId')) {
              Cookie.set('accountId', this.appState.get('accountId'), null, '/');
            }

            const boostrapRequest = this._bootstrapProduct.executeLoad();
            boostrapRequest.then((resp: object) => {
              if (resp[ 'completed' ] === true && resp[ 'status' ] === 'successful') {
                return this._doSuccessfulRedirect();
              } else if (resp[ 'completed' ] === true && resp[ 'status' ] === 'error') {
                this._router.navigate([ 'error' ], { replaceUrl: true, queryParams: new URLSearchParams(window.location.search) });
                return true;
              }
            });
          });

        } else if (!isLoggedIn) {
          let commands = ['login'];

          if (this.appState.get('current_profile')) {
            commands.splice(0, 0, this.appState.get('current_profile'));
          }

          this._router.navigate(commands, { replaceUrl: true, queryParams: new URLSearchParams(window.location.search) });
          return true;
        } else {
          return true;
        }
      })
    );
  }

  private _updateAuthLoginUrl (type: string, defaultLoginUrl: string, profile: string, url: string) {
    // In PAM if default auth url is /barcode?accountid=92926&groupid=1234411
    // or /login?accountid=189737
    const route = profile ? '/' + profile + '/barcode?' : '/' + type + '?';
    const fromStr = defaultLoginUrl.indexOf(route);
    let newQueryParams = AuthCheckGuardService.removeQueryStringParameter('searchId', decodeURI(url));
    newQueryParams =  AuthCheckGuardService.removeQueryStringParameter('accountid', newQueryParams);
    newQueryParams =  AuthCheckGuardService.removeQueryStringParameter('groupid', newQueryParams);
    newQueryParams = '&' + newQueryParams.split('?')[1] || '';
    const newQueryParamsString = new URLSearchParams(newQueryParams).toString();

    defaultLoginUrl = defaultLoginUrl.substring(fromStr);
    this._router.navigateByUrl(defaultLoginUrl + '?' + newQueryParamsString, { replaceUrl: true });
  }

  private _doSuccessfulRedirect (): boolean {
    let profile = this.appState.get('current_profile');
    let products = this.appState.get('grappleProducts') || [];
    const params = this.appState.get('accountId') ? { accountid: this.appState.get('accountId') } : {};
    const hasValidProfileInUrl: boolean = (profile && this._isValidProfile(profile));

    if (!Array.isArray(products)) {
      products = [ Array.isArray(this.appState.get('grappleProducts')) ];
    }

    let path = '/';

    if (hasValidProfileInUrl) {
      this.appState.set({ current_profile : profile });
      path = profile + '/home';
    } else if ((products.length === 0) || !hasValidProfileInUrl) {
      path = 'portal/home';
    }

    this._router.navigate(['/'].concat(path.split('/')), { queryParams: params });
    return true;
  }

  private _isValidProfile (requestedProfile: string) {
    let allProducts = this.appState.get('grappleProducts') || [];
    if (!Array.isArray(allProducts)) {
      allProducts = [ allProducts ];
    }

    return allProducts.filter((product) => {
      return product && product['profile'] === requestedProfile;
    }).length  >= 1;
  }
}
