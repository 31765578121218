import { NgModule } from '@angular/core';
import { CommonModule }   from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../_shared/shared.module';

import { SiteHeaderComponent } from './site.header.component';
import { TopicListMenuComponent, MicrosoftGraphBtnComponent, GoogleLinkComponent, WindowMessageInterface } from './section';

export * from './site.header.component';
export * from './section';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    SharedModule
  ],
  declarations: [
    SiteHeaderComponent, TopicListMenuComponent, GoogleLinkComponent, MicrosoftGraphBtnComponent
  ],
  exports: [ SiteHeaderComponent ],
})
export class SiteHeaderModule {}
