export class SessionModel {
    constructor (
        public accountId?: string,
        public sessionId?: string,
        public sessionRoles?: string,
        public sessionStart?: string,
        public sessionType?: string,
        public preferences?: object[],
        public ltiDeepLinkUri?: string,
        public creationDate?: string
    ) {}

    public fromJSON(json: Object): SessionModel {
        for (let propName in json) {
            if (json.hasOwnProperty(propName)) {
                (<any> this)[propName] = (<any> json)[propName];
            }
        }

        return this;
    }
}
