import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class NotificationService {
  private _url: string = '/svc/grapple/getnotification';

  constructor (
    private _http: HttpClient,
  ) {}

  public get (): Observable<any> {
    return this._http.get(this._url);
  }
}
