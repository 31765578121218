import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { ShibbolethService } from '../../_services/shibboleth/shibboleth.service';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { MatDialogRef} from '@angular/material/dialog';
import { AppState } from '../../app.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare var dataLayer: any;

@Component({
  selector: 'pq-google-login',
  templateUrl: './google-login.component.html',
  styleUrls: ['./_google-login.component.scss'],
  encapsulation: ViewEncapsulation.None
}) export class GoogleLoginComponent implements AfterViewInit, OnInit {
  public profileVal: string = this.appState.get('current_profile');
  public googleEmailLabel: string;
  public googleEmail: string;
  public isLoading: boolean = false;
  public isWorking: boolean = false;
  public hasError: boolean = false;

  public msg: string;
  public results: object[];
  @ViewChild("googleEmail") googleEmailField: ElementRef;
  @ViewChild("title-dialog") dialogTitle: ElementRef;

  available: string;
  googleForm: FormGroup;
  public showEmailErrorMessages: boolean = false;
  public emailErrorMessagesDiv: string = '';


  constructor (
    private _shibbolethSvc: ShibbolethService,
    public appState: AppState,
    public router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private _dialogRef: MatDialogRef<GoogleLoginComponent>

  ) {}

  public ngOnInit () {
  }

  public ngAfterViewInit () {
  }

  public pushGoogleLoginCloseToGA() {
    if (dataLayer && Array.isArray(dataLayer)) {
      dataLayer.push({'event': 'SaveToCloudOverlay', 'label': 'Close', 'action': 'Close'});
    }
  }

  get f() { return this.googleForm.controls; }

  isValidGoogleLoginEmail(){
    var re = /\S+@\S+\.\S+/;
    return re.test(this.googleEmail);
  }

  public handleGoogleLoginSubmit (e: Event): void {
    e.preventDefault();
    const theForm = e.currentTarget as HTMLFormElement;
    this.googleEmail = theForm.googleEmail ? theForm.googleEmail.value : '';

    if(!this.isValidGoogleLoginEmail()){
      this.showEmailErrorMessages = true;
      this.emailErrorMessagesDiv = 'invalidEmail';

    }else {
      if(this.googleEmail.endsWith("gmail.com") ||this.googleEmail.endsWith("google.com") || this.googleEmail.endsWith("hotmail.com") || this.googleEmail.includes("gmail") || this.googleEmail.includes("google") || this.googleEmail.includes("hotmail") ){
        this.showEmailErrorMessages = true;
        this.emailErrorMessagesDiv = 'gmailAddress';

      } else {
        let url = "/svc/grapple/googleValidation";
        let httpRes = this.http.get(`${url}?email=${this.googleEmail}`).toPromise();
        httpRes.then((resp: any) => {
          if (resp.isVerified) {
            if (this.profileVal != null) {
              this.router.navigate(['/', this.profileVal, 'login', 'googleauth']);
            }
            else {
              this.router.navigate(['/', 'login', 'googleauth']);
            }
          }
          else {
            this.showEmailErrorMessages = true;
            this.emailErrorMessagesDiv = 'institutionNotConfigured';
          }
        }).catch((err) => {
          this.isLoading = false;
          this.hasError = true;
        });

      }
    }

  }
} 
