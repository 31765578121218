import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, timeout } from 'rxjs/operators';
import { LanguageModel } from '../../_interfaces';
import XMLString from '../../_utilities/xmlstring';
import { Observable } from 'rxjs';

@Injectable()
export class TranslationService {

    private _url: string = '/svc/grapple/translate';
    private _desturl: string = '/svc/grapple/translate?src=';

    constructor(private _http: HttpClient) {
    }


    public translate (content: string, srcLanguage: string, destLanguage: string, requestFullTranslate: boolean = false): Promise<any> {
        let postBody = this.createPostBody(content, srcLanguage, destLanguage, requestFullTranslate);

        return this._http.post(this._url, postBody).pipe(timeout(90000)).toPromise();
    }

    public createPostBody (content: string, srcLanguage: string, destLanguage: string, requestFullTranslate: boolean = false): string {
        let postBody = '<TranslationRequest>';

        postBody += XMLString.xmlwrap(content, 'content', false);
        postBody += XMLString.xmlwrap(srcLanguage, 'srcLanguage', false);
        postBody += XMLString.xmlwrap(destLanguage, 'destLanguage', false);
        postBody += XMLString.xmlwrap(requestFullTranslate ? 'true' : 'false', 'restOfDoc', true);
        postBody += '</TranslationRequest>';

        return postBody;
    }

    public getSupportedLanguages (): Observable<LanguageModel[]> {
      return this._http.get(this._url).pipe(
        catchError((err: any) => {
          return [];
        }),
        map((resp) => {
          return resp['TranslationLanguagesResponse'] && resp['TranslationLanguagesResponse']['Language'] ? resp['TranslationLanguagesResponse']['Language'] : [];
        })
      );
    }

    public getDestinationLanguages (srcLanguage: string): Observable<LanguageModel[]> {
      return this._http.get(this._desturl.concat(srcLanguage)).pipe(
        catchError((err: any) => {
          return [];
        }),
        map((resp) => {
          return resp['TranslationLanguagesResponse'] && resp['TranslationLanguagesResponse']['Language'] ? resp['TranslationLanguagesResponse']['Language'] : [];
        })
      );
    }

}
