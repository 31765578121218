import { ApplicationRef, Component, Inject, OnInit } from '@angular/core';
import { ProductService } from '../../_services';
import { AppState } from '../../app.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'pq-portal-link-dialog',
  templateUrl: './portal-link-dialog.component.html',
  styleUrls: ['./_portal-link-dislog.component.scss']
})
export class PortalLinkDialogComponent implements OnInit {

  public showCAPTCHA: boolean = false;
  public loading: boolean = true;
  public error: boolean = false;
  public msg: string = this._appState.get('portalOpeningLinkError');

  private _captchaLink: string = '';

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialog,
    private _appState: AppState,
    private _appRef: ApplicationRef,
    private _productSvc: ProductService,
    private _dialogRef: MatDialogRef<PortalLinkDialogComponent>
  ) {}

  ngOnInit (): void {
    this.loading = true;
    this.error = false;
    this.showCAPTCHA = (this.data.platform === 'proquest') && (this._appState.get('sessionType') === 'USERNAMEPASS');
    const svc = this._productSvc.getProductUrl(this.data.profile, this.data.platform);

    svc.subscribe({
      next: this._handleSuccess.bind(this),
      error: this._handleError.bind(this)
    });

  }

  public handleCAPTCHAResp (resp): void {
    if (resp.key) {
      this.error = resp.error;
      this._handleProductLink.call(this, this._captchaLink, '_blank');
    }
  }

  private _handleSuccess (resp): void {
    let url = resp[ 'ProductUrlResponse' ] && resp[ 'ProductUrlResponse' ][ 'url' ] ? resp[ 'ProductUrlResponse' ][ 'url' ] : '';
    const urlArrayData = url.split('/embedded');

    if (this.data.platform === 'native') {
      url = window.location.origin + '/' + this.data.profile + '/home';
    }

    if (url && this.data.url && urlArrayData.length) {
      url = this.data.url + '/embedded' + urlArrayData[ 1 ];
    } else if (url && this.data.url && ( urlArrayData.length === 0 )) {
      url = this.data.url;
    }

    if (url && !this.showCAPTCHA) {
      this.error = false;
      this._handleProductLink(url, this.data.profile);

    } else if (url && this.showCAPTCHA) {

      this.error = false;
      this._captchaLink = url;

    } else {
      this.error = true;
    }

    this.loading = false;
  }

  private _handleError (): void {
      this.error = true;
      this.loading = false;
  }

  private _handleProductLink (url: string, windowName: string): void {
    this.dialogRef.closeAll();
    window.open(url, windowName);
    this._appRef.tick(); // force ui rewrite due to return call coming from Google and outside of GR App scope

  }
}
