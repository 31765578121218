import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select'
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { PortalLinkDialogComponent } from '../home/portal';

import {
  ImageSliderComponent,
  LoadingComponent,
  CaptchaModule,
  BasicSearchComponent,
  TermListComponent
} from '../_globals';

import {
  AuthorFormatPipe,
  CleanTextPipe,
  DateFormatPipe,
  ImageTitleFormatPipe,
  LexileScoreFormatPipe,
  SearchIdRemovalPipe,
  CommonAndSubjectsSoftHyphenPipe,
  SafeHtmlPipe,
  PathUpdatePipe,
  SafeUrlPipe,
  TruncatePipe
} from '../_utilities';

import {
  FileDownloadService,
  UsageLoggingService,
  EventLoggingService,
  RelatedItemsService
} from '../_services';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatDialogModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatTooltipModule,
    ReactiveFormsModule,
    CaptchaModule
  ],
  declarations: [
    ImageSliderComponent,
    BasicSearchComponent,
    LoadingComponent,
    TermListComponent,
    PortalLinkDialogComponent,
    AuthorFormatPipe,
    CleanTextPipe,
    DateFormatPipe,
    ImageTitleFormatPipe,
    LexileScoreFormatPipe,
    SearchIdRemovalPipe,
    SafeHtmlPipe,
    CommonAndSubjectsSoftHyphenPipe,
    PathUpdatePipe,
    SafeUrlPipe,
    TruncatePipe
  ],
  exports: [
    ImageSliderComponent,
    BasicSearchComponent,
    LoadingComponent,
    TermListComponent,
    PortalLinkDialogComponent,
    AuthorFormatPipe,
    CleanTextPipe,
    DateFormatPipe,
    ImageTitleFormatPipe,
    LexileScoreFormatPipe,
    SearchIdRemovalPipe,
    SafeHtmlPipe,
    MatDialogModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatTooltipModule,
    ReactiveFormsModule,
    CaptchaModule,
    CommonAndSubjectsSoftHyphenPipe,
    PathUpdatePipe,
    SafeUrlPipe,
    TruncatePipe
  ],
  entryComponents: [
    PortalLinkDialogComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<NgModule> {
    return {
      ngModule: SharedModule,
      providers: [
        FileDownloadService,
        CleanTextPipe,
        UsageLoggingService,
        EventLoggingService,
        RelatedItemsService,
        LexileScoreFormatPipe
      ]
    }
  }
}
