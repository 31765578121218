import { Component, OnInit } from '@angular/core';
import { AppState } from '../../app.service';
import { SeoService } from '../../_services/seo/seo.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CleverService } from '../../_services/clever/clever.service';
import { GoogleAuthentificationService } from '../../_services/google-authentification/google-authentification.service';

@Component({
  selector: 'sso-content',
  templateUrl: './sso.component.html',
  styleUrls: ['./_sso.component.scss'],
})
export class SsoComponent implements OnInit {
  public title: string = "SSO Authentication";
  public hasError: boolean = false;
  public error: string;

  private _ssoError: object = {
    cleverauth: this.appState.get('ssoCleverGeneralError')
  }

  private _ssoType: object = {
    cleverauth: {
      request: this._cleverSvc.get(this.appState.get('current_profile'))
    },
    googleauth: {
      request: this._googleAuthentificationSvc.get(this.appState.get('current_profile'))
    }
  };
  private _request: Promise<object>;

  constructor (
    public appState: AppState,
    private _seoSvc: SeoService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _googleAuthentificationSvc: GoogleAuthentificationService,
    private _cleverSvc: CleverService,
  ) {
    this._seoSvc.setTitle(this.title);
    this.hasError = false;

    this._route.params.subscribe((params: ParamMap) => {
      const sso = params['ssotype'];
      // use valid sso type
      if (this._ssoType[sso]) {
        // make sso request
        this._request = this._ssoType[sso]['request'];
        this.error = this._ssoError[sso];

        if (this._request) {
          this._request.then((resp: object) => {
            if (resp['redirectUrl']) {
              this.hasError= false;
              window.location.replace(resp['redirectUrl']);
            } else {
              this.hasError = true;
            }
          });

          this._request.catch((err: any) => {
            this.hasError = true;
          });

        } else {
          this._loginRedirect(params['profile']);
        }

      } else {
        this._loginRedirect(params['profile']);
      }
    });
  }

  public ngOnInit (): void {}

  private _loginRedirect (profile?: string): void {
    const commands = ['/'];
    if (profile && profile !== 'undefined') {
      commands.push(profile)
    }

    commands.push('login');

    this._router.navigate(commands);
  }
}
