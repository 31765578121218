import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'pqLexileScoreFormat' })
export class LexileScoreFormatPipe implements PipeTransform {
  public transform (text: string) {
    const value = parseInt(text, 0);

    if (text.toLowerCase() === 'none') {
      return 'unavailable';
    }

    text += 'L';

    if (value < 0) {
      if (text[0] === '-') {
        text = text.substr(1);
      }

      text = 'BR' + text;
    }

    return text;
  }
}
