import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UiProfileService {
  private _url: string = '/svc/grapple/uiprofile/';

  constructor (
    private _http: HttpClient
  ) {}

  public get (profile?: string): Promise<any> {
    let url = profile ? this._url + profile : this._url + 'default';

    return this._http.get(url).toPromise();
  }

  public flushcache (): Promise<any> {
    let url = this._url + 'flushcache';

    return this._http.post(url, '').toPromise();
  }
}
