import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { lastValueFrom, throwError } from 'rxjs';
import { Cookie } from 'ng2-cookies/ng2-cookies';

import { BootstrapProduct } from '../../_utilities';
import { ConfigModel, SessionModel } from '../../_interfaces';
import { AppState } from '../../app.service';
import { ProductService } from '../product/product.service';
import { SessionService } from '../session/session.service';
import { SeoService } from '../seo/seo.service';
import { ROUTES } from '../../app.routes';
import SessionTokenRedirect from '../../_utilities/session-token-redirect';
import DomainChecks from 'src/app/_utilities/domain-check';
import { PendoService } from 'src/app/login/user-pass/pendo.service';

@Injectable()
export class ConfigService {
  public config: ConfigModel[] = [];
  public url: string = '/assets/config.json';

  private _path: string;

  constructor (
    public appState: AppState,
    private _http: HttpClient,
    private _loaction: Location,
    private _session: SessionService,
    private _productSvc: ProductService,
    private _bootstrapProduct: BootstrapProduct,
    private pendoService:PendoService
  ) {

  }

  public get (): Promise<object> {
    const request = fetch(this.url);    
    return request.then((response: Response) => {
      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }
      return response.json();
    });
  }

  public  async load (path: string): Promise<boolean> {
    try {
      this._path = path;
      const profile = this._getProfile(path);
      const config = await this.get();
      this.appState.set(config);

      if (profile) {
        const productInfo = await this._productSvc.getLimitedInfo(profile);

        if (productInfo[ 'ProductInfo' ] && productInfo[ 'ProductInfo' ][ 'uiprofile' ]) {
          this.appState.set(productInfo[ 'ProductInfo' ]);
          this.appState.set({current_profile: profile});
          //  stubbing the list of grapple products for unauthenticated pages with valid moniker request.
          //  This list should update with the complete list when a user is authenticated.
          this.appState.set({grappleProducts: [ {profile} ]});
          Cookie.set('profile', profile, null, '/');
        }
      }

      const sessionResp = await this._doSessionSvc();
      const session = this._handleSessionResponse(sessionResp);

      if (session['sessionRoles'] === 'authenticated') {
        const bootstrapAppResp = await this._bootstrapProduct.executeLoad();
        if (bootstrapAppResp['completed'] !== true || bootstrapAppResp['status'] !== 'successful') {
          this.appState.set({'status': '502' });
        }
      }

      return true;

    } catch (err) {
      this.appState.set({'status': '502' });
      return false;
    }
  }

  public updateAppSession (sessionResp: object = {}): void {
    this._session.session = new SessionModel().fromJSON(sessionResp);

    if (sessionResp['sessionRoles'] === 'authenticated') {
      const sessionObject = this._session.createSessionObject(sessionResp);
      sessionStorage.setItem('sessionObject', JSON.stringify(sessionObject));

      if (sessionResp['multipleMatchingAccount']) {
        this.appState.set({ multipleMatchingAccount: sessionResp['multipleMatchingAccount'] });
      }

      sessionResp['preferences'].forEach((entry) => {
        if (entry['code'] === 'DEFAULT_RESULTS_PER_PAGE') {
          let entryValue = parseInt(entry['value'], 0);
          this.appState.set({ DEFAULT_RESULTS_PER_PAGE: entryValue });
        }
        if (entry['code'] === 'ENABLE_GOOGLE_INTEGRATION') {
          this.appState.set({ ENABLE_GOOGLE_INTEGRATION: entry['value'] });
        }
        if (entry['code'] === 'ENABLE_ONEDRIVE') {
          this.appState.set({ ENABLE_ONEDRIVE: entry['value'] });
        }
        if (entry['code'] === 'ENABLE_NOODLETOOLS_EXPORT') {
          this.appState.set({ ENABLE_NOODLETOOLS_EXPORT: entry['value'] === "Y"});
        }
        if (entry['code']  === 'PRODUCT_URL') {
          this.appState.set({ PRODUCT_URL: atob(entry['value']) });
        }
        if (entry['code']  === 'REDIRECT_ID') {
          this.appState.set({ REDIRECT_ID: entry['value'] });
        }
      });

      if (this.appState.get("REDIRECT_ID")) {
        let productUrl = this.appState.get("PRODUCT_URL")
        let redirectId = this.appState.get("REDIRECT_ID")

        let domainCheck = new DomainChecks();
        if (domainCheck.checkIfRedirectFromProxyDomain()) {
          let sessionTokenRedirect = new SessionTokenRedirect();
          sessionTokenRedirect.doRedirect(productUrl, redirectId);
        }
      }

      this.appState.set({ accountId: sessionResp['accountId'], sessionType: sessionResp['sessionType'] });

      Cookie.set('session', sessionResp['sessionId'], null, '/');
      if (sessionResp['accountId']) {
        Cookie.set('accountId', sessionResp['accountId'], null, '/');
        this.appState.set({ accountid: sessionResp['accountId'] });
      }
    }
  }

  private async _doSessionSvc (): Promise<object> {
    this.appState.set({ initialLoadComplete: true });

    return await lastValueFrom(this._session.load());
  }

  private _handleSessionResponse (resp: object): object {

    const session = resp['SessionResponse'] || {};
    if (resp['SessionResponse']) {
      const sessionObject = this._session.createSessionObject(session);
      this._createSessionStorage(sessionObject);
      this.updateAppState(session);

      if (session['multipleMatchingAccount']) {
        this.appState.set({ multipleMatchingAccount: session['multipleMatchingAccount'] });
      }

      this.updateAppSession(session);
    } else {
      this.appState.set({'status': '502' });
    }

    return session;
  }

  public updateAppState (sessionResp: object = {}) {
    this._session.session = new SessionModel().fromJSON(sessionResp);
    if (sessionResp['sessionRoles'] === 'authenticated') {
      const sessionObject =  this._session.createSessionObject(sessionResp);
      sessionStorage.setItem('sessionObject', JSON.stringify(sessionObject));
      this.pendoService.sessionDetails(sessionResp,this._path)
  
      if (sessionResp['multipleMatchingAccount']) {
        this.appState.set({ multipleMatchingAccount: sessionResp['multipleMatchingAccount'] });
      }

      sessionResp['preferences'].forEach((entry) => {

        if (entry['code'] === 'DEFAULT_RESULTS_PER_PAGE') {
          let entryValue = parseInt(entry['value'], 0);
          this.appState.set({ DEFAULT_RESULTS_PER_PAGE: entryValue });
        }
        if (entry['code'] === 'ENABLE_GOOGLE_INTEGRATION') {
          this.appState.set({ ENABLE_GOOGLE_INTEGRATION: entry['value'] });
        }
        if (entry['code'] === 'ENABLE_ONEDRIVE') {
          this.appState.set({ ENABLE_ONEDRIVE: entry['value'] });
        }
        if (entry['code'] === 'ENABLE_NOODLETOOLS_EXPORT') {
          this.appState.set({ ENABLE_NOODLETOOLS_EXPORT: entry['value'] === "Y"});
        }
        if (entry['code']  === 'PRODUCT_URL') {
          this.appState.set({ PRODUCT_URL: atob(entry['value']) });
        }
        if (entry['code']  === 'REDIRECT_ID') {
          this.appState.set({ REDIRECT_ID: entry['value'] });
        }
      });

      if (this.appState.get("REDIRECT_ID")) {
        let productUrl = this.appState.get("PRODUCT_URL")
        let redirectId = this.appState.get("REDIRECT_ID")
        let domainCheck = new DomainChecks();
        if (domainCheck.checkIfRedirectFromProxyDomain()) {
          let sessionTokenRedirect = new SessionTokenRedirect();
          sessionTokenRedirect.doRedirect(productUrl, redirectId);
        }
      }

      this.appState.set({ accountId: sessionResp['accountId'], sessionType: sessionResp['sessionType'] });

      Cookie.set('session', sessionResp['sessionId'], null, '/');
      if (sessionResp['accountId']) {
        Cookie.set('accountId', sessionResp['accountId'], null, '/');
      }
    }
  }

  private _handleError (error: any, message: string): any {
    let errorMsg = error;

    if (error && error.error) {
      errorMsg = error.error;
    }

    return throwError(errorMsg || message);
  }

  private _createSessionStorage (sessionObject: object): void {
    sessionStorage.setItem('sessionObject', JSON.stringify(sessionObject));
  }

  //-- getProfile is used to get the profile token from the url when the Angular activatedRoute is not available or can not be used.
  private _getProfile (path: string): string {
    let locationDataArray = path.split('/');

    let testProfile = '';
    let isFound = false;

    if (locationDataArray.length >= 2) {
      testProfile = locationDataArray[1];

      isFound = ROUTES.filter((route) => {
        return route['path'].startsWith(testProfile);
      }).length >= 1;

      if (!isFound) {
        return testProfile;
      }

      return undefined;
    }

    return undefined;
  }
}
