import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SearchFilterService } from '../../_services/search-filter/search.filter.service';
import { ResultsModel } from '../../_interfaces';
import { AppState } from '../../app.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import XMLString from '../../_utilities/xmlstring';
import DateFormat from '../../_utilities/date.format';

@Injectable()
export class SearchService {
  public results: ResultsModel;
  private _resultsToShow: string = '10';
  private _sortOrder: string = this._searchFilterSvc.getDefaultSortBy();
  private url: string = '/svc/grapple/search/' + this._appState.get('current_profile');

  constructor (
    private _http: HttpClient,
    private _searchFilterSvc: SearchFilterService,
    private _appState: AppState
  ) {}

  public doSearch (queryParams: Object): Promise<any> {
    let postBody = this.createPostBody(queryParams);

    return this._http.post(this.url, postBody).pipe(
      catchError((error: any) => {
        return throwError(error.error || 'Server error');
      })
    )
    .toPromise();
  }

  public createPostBody (queryParams: Object): string {
    let postBody = '<SearchRequest>';
    let logSearch = this._appState.get('logSearch') !== null ? this._appState.get('logSearch') : true;

    postBody += XMLString.xmlwrap(queryParams['query'], 'searchTerm', false);

    if (queryParams['searchType']) {
      postBody += XMLString.xmlwrap(queryParams['searchType'], 'searchType', false);
    } else {
      postBody += XMLString.xmlwrap('Basic', 'searchType', false);
    }

    if (queryParams['offset']) {
      postBody += XMLString.xmlwrap(queryParams['offset'], 'offset', false);
    }

    if (DateFormat.validate(queryParams['pubstartdate']) && DateFormat.validate(queryParams['pubenddate'])) {
      postBody += XMLString.xmlwrap(queryParams['pubstartdate'], 'pubStartDate', false);
      postBody += XMLString.xmlwrap(queryParams['pubenddate'], 'pubEndDate', false);
    }

    if (queryParams['sort']) {
      this._sortOrder = queryParams['sort'];
    } else if (!this._sortOrder) {
      this._sortOrder = this._searchFilterSvc.getDefaultSortBy();
    }

    postBody += XMLString.xmlwrap(this._sortOrder, 'sort', false);

    if (queryParams['source']) {
      postBody += XMLString.xmlwrap(queryParams['source'], 'sourceType', false);
    }

    if (queryParams['lexilemin']) {
      postBody += XMLString.xmlwrap(queryParams['lexilemin'], 'lexilemin', false);
    }

    if (queryParams['lexilemax']) {
      postBody += XMLString.xmlwrap(queryParams['lexilemax'], 'lexilemax', false);
    }

    if (queryParams['limit']) {
      postBody += XMLString.xmlwrap(queryParams['limit'], 'limit', false);
    }

    if (queryParams['currencyfilter'] !== undefined) {
      postBody += XMLString.xmlwrap(queryParams['currencyfilter'], 'currencyFilter', false);
    }

    postBody += XMLString.xmlwrap(logSearch, 'logSearch', true);
    postBody += XMLString.xmlwrap(this._resultsToShow, 'resultsToShow', false);

    postBody += '</SearchRequest>';

    return postBody;
  }

  public setResultsToShow (num: string) {
    this._resultsToShow = num;
  }

  public getResultsToShow (): string {
    return this._resultsToShow;
  }

  public setSortOrder (type: string) {
    this._sortOrder = type;
  }
}
