import { Injectable } from '@angular/core';

declare var document: any;

@Injectable()
export class RecaptchaService {
  private _src: string = 'https://www.google.com/recaptcha/api.js';

  loadScript (): Promise<any> {
    return new Promise((resolve, reject) => {
      let doc = <HTMLDivElement>document.getElementById('signup-form');
      // load script
      let script = document.createElement('script');
      script.src = this._src;
      script.async = true;
      script.defer = true;

      if (script.readyState) { // IE
        script.onreadystatechange = () => {
          if (script.readyState === 'loaded' || script.readyState === 'complete') {
            script.onreadystatechange = null;
            resolve({ loaded: true, status: 'Loaded' });
          }
        };
      } else { // everyone else
        script.onload = () => {
          resolve({ loaded: true, status: 'Loaded' });
        };
      }

      script.onerror = (error: any) => {
        resolve({ loaded: false, status: 'Loaded' });
      };

      doc.appendChild(script);
    });
  }
}
