<div class="datepicker" [ngStyle]="{'font-family': fontFamily}" [ngClass]="inputStyle">
    <label [attr.for]="inputName" aria-hidden="true">{{inputLabel}}</label>
    <div class="form-group" [ngClass]="{ 'error': hasError }">
      <input type="text"
             [disabled]="disabled"
             class="form-control datepicker__input"
             [placeholder]="placeholder"
             [attr.id]="inputName"
             [attr.name]="inputName"
             [(ngModel)]="inputText"
             (ngModelChange)="updateInputText($event)"
             (blur)="handleInputBlur($event)"
             (click)="handleInputClick($event)"
             maxlength="10"
             [attr.aria-label]="'Enter ' + dateType + ' date using the format: month (2 digits), date (2 digits), year (4 digits)'"
             onlyNumber>

      <svg class="gr-calendar" (click)="onInputClick()">
          <use xlink:href="./assets/img/icons.svg#gr-calendar"></use>
      </svg>
    </div>
    <div class="datepicker__calendar" *ngIf="showCalendar">
        <div class="datepicker__calendar__nav">
            <button type="button" class="datepicker__calendar__nav__arrow" (click)="onArrowLeftClick()" [disabled]="!canMoveToPrevMonth()">
                <svg class="datepicker__calendar__nav__chevron" x="0px" y="0px" viewBox="0 0 50 50">
                    <g>
                        <path d="M39.7,7.1c0.5,0.5,0.5,1.2,0,1.7L29,19.6c-0.5,0.5-1.2,1.2-1.7,1.7L16.5,32.1c-0.5,0.5-1.2,0.5-1.7
                    ,0l-2.3-2.3c-0.5-0.5-1.2-1.2-1.7-1.7l-2.3-2.3c-0.5-0.5-0.5-1.2,0-1.7l10.8-10.8c0.5-0.5,
                    1.2-1.2,1.7-1.7L31.7,0.8c0.5-0.5,1.2-0.5,1.7,0 l2.3,2.3c0.5,0.5,1.2,1.2,1.7,1.7L39.7,7.1z"/>
                    </g>
                    <g>
                        <path d="M33.4,49c-0.5,0.5-1.2,0.5-1.7,0L20.9,38.2c-0.5-0.5-1.2-1.2-1.7-1.7L8.4,
                    25.7c-0.5-0.5-0.5-1.2,0-1.7l2.3-2.3c0.5-0.5,1.2-1.2,1.7-1.7l2.3-2.3c0.5-0.5,1.2-0.5,1.7,
                    0l10.8,10.8c0.5,0.5,1.2,1.2,1.7,1.7 l10.8,10.8c0.5,0.5,0.5,1.2,0,1.7L37.4,45c-0.5,0.5-1.2,
                    1.2-1.7,1.7L33.4,49z"/>
                    </g>
                </svg>
            </button>
            <div class="datepicker__calendar__nav__header">
                {{ currentMonth }} {{ currentYear }}
            </div>
            <button type="button" class="datepicker__calendar__nav__arrow" (click)="onArrowRightClick()" [disabled]="!canMoveToNextMonth()">
                <svg class="datepicker__calendar__nav__chevron" x="0px" y="0px" viewBox="0 0 50 50">
                    <g>
                        <path d="M8.4,7.1c-0.5,0.5-0.5,1.2,0,1.7l10.8,10.8c0.5,0.5,1.2,1.2,1.7,1.7l10.8,10.8c0.5,0.5,1.2,0.5,1.7
                    ,0l2.3-2.3 c0.5-0.5,1.2-1.2,1.7-1.7l2.3-2.3c0.5-0.5,0.5-1.2,0-1.7L29,13.2c-0.5-0.5-1.2-1.2-1.7-1.7L16.5,
                    0.8c-0.5-0.5-1.2-0.5-1.7,0 l-2.3,2.3c-0.5,0.5-1.2,1.2-1.7,1.7L8.4,7.1z"/>
                    </g>
                    <g>
                        <path d="M14.8,49c0.5,0.5,1.2,0.5,1.7,0l10.8-10.8c0.5-0.5,1.2-1.2,1.7-1.7l10.8-10.8c0.5-0.5,0.5-1.2,0-1.7l-2.3-2.3
                    c-0.5-0.5-1.2-1.2-1.7-1.7l-2.3-2.3c-0.5-0.5-1.2-0.5-1.7,0L20.9,28.5c-0.5,0.5-1.2,1.2-1.7,1.7L8.4,
                    40.9c-0.5,0.5-0.5,1.2,0,1.7 l2.3,2.3c0.5,0.5,1.2,1.2,1.7,1.7L14.8,49z"/>
                    </g>
                </svg>
            </button>
        </div>
        <div class="datepicker__calendar__content">
            <div class="datepicker__calendar__label" *ngFor="let day of dayNames">
                {{ day }}
            </div>
            <div [@calendarAnimation]="animate" class="datepicker__calendar__month">
                <div *ngFor="let day of calendarDays" class="datepicker__calendar__month__day" [ngStyle]="{'cursor': day == 0 ? 'initial' : 'pointer', 'background-color': getDayBackgroundColor(day), 'color': isHoveredDay(day) ? accentColor : getDayFontColor(day), 'pointer-events': day == 0 ? 'none' : '' }" (click)="onSelectDay(day)" (mouseenter)="hoveredDay = day" (mouseleave)="hoveredDay = null">
                    <span *ngIf="day != 0">{{ day.getDate() }}</span>
                </div>
            </div>
            <div class="datepicker__calendar__cancel" (click)="onCancel()">
                Cancel
            </div>
        </div>
    </div>
</div>
