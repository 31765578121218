import {
  ViewContainerRef,
  Injector,
  Compiler,
  ComponentRef,
  Injectable
} from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';

@Injectable()
export class ModalService {
  public activeInstances: number = 0;

  private _vcRef: ViewContainerRef;
  private _injector: Injector;

  constructor (private _compiler: Compiler) {
  }

  public setViewContainerRef (vcRef: ViewContainerRef): void {
    this._vcRef = vcRef;
  }

  public setInjector (injector: Injector): void {
    this._injector = injector;
  }

  public create<T> (module: any, component: any, parameters?: Object): ReplaySubject<Object> {
    // we return a stream so we can  access the componentRef
    let componentRef$ = new ReplaySubject();

    // compile the component based on its type and
    // create a component factory
    this._compiler.compileModuleAndAllComponentsAsync(module).then((factory) => {
      // look for the componentfactory in the modulefactory
      let componentFactory = factory.componentFactories.filter((item) => {
        return item.componentType === component;
      })[0];
      // the injector will be needed for DI in
      // the custom component
      const childInjector = Injector.create([], this._injector);
      // create the actual component
      let componentRef = this._vcRef.createComponent(componentFactory, 0, childInjector);

      // pass the @Input parameters to the instance
      Object.assign(componentRef.instance, parameters);

      this.activeInstances++;

      componentRef.instance['componentIndex'] = this.activeInstances;

      // add a destroy method to the modal instance
      componentRef.instance['destroy'] = () => {
        this.activeInstances--;
        // this will destroy the component
        componentRef.destroy();
      };

      // the component is rendered into the ViewContainerRef
      // so we can update and complete the stream
      componentRef$.next(componentRef);
      componentRef$.complete();
    });

    return componentRef$;
  }
}
