import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import XMLString from '../../_utilities/xmlstring';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppState } from '../../app.service';

@Injectable()
export class RelatedItemsService {
  private _url: string = '/svc/grapple/getdocrelateditems';
  private _recordUrl: string = '/svc/grapple/recordrelateditem';

  constructor (
    private _http: HttpClient,
    private _appState: AppState
  ) {}

  public fetchRelatedItems (docid: string): Observable<any> {
    const url = `${this._url}?goid=${docid}&showMore=false&db=${this._appState.get('current_profile')}`;

    return this._http.get(url)
      .pipe(
        catchError((error) => {
          return of (error.errors || 'Sever error');
        })
      );
  }

  public fetchResearchTopicRelatedItems (legacyid: string): Observable<any> {
    const url = `/svc/grapple/getrtdocrelateditems?docid=${legacyid}&db=${this._appState.get('current_profile')}`;

    return this._http.get(url)
      .pipe(
        map((resp) => {
          const respRoot = resp['DocRTRelatedItemRetrieveResponse'];
          let items =  respRoot
          && respRoot['RelatedResearchTopicItems']
          && respRoot['RelatedResearchTopicItems']['TopicTreeNode'] ?
            respRoot['RelatedResearchTopicItems']['TopicTreeNode'] : [];

          if (!Array.isArray(items)) {
            items =  [ items ];
          }

          return items;
        }),
        catchError((error) => {
          console.log(error)
          // throwError()
          return of (error.errors || 'Sever error');
        })
      );
  }

  public fetchMoreRelatedItems (docid: string, showMore: string = 'false'): Observable<any> {
    const url = this._url + '?goid=' + docid + '&db=' + this._appState.get('current_profile') + '&showMore=' + showMore;
    return this._http.get(url)
      .pipe(
        map((resp) => {
          if (resp && resp['DocRelatedItemRetrieveResponse'] && resp['DocRelatedItemRetrieveResponse']['relatedItem'] && !Array.isArray(resp['DocRelatedItemRetrieveResponse']['relatedItem'])) {
            resp['DocRelatedItemRetrieveResponse']['relatedItem'] =  [ resp['DocRelatedItemRetrieveResponse']['relatedItem'] ];
          }

          return resp;
        }),
        catchError((error) => {
          return of(error.errors || 'Sever error');
        })
      );
  }

  public recordRelatedItem (requestId: string, fromDocId: string, recDocId: string, method: string): Observable<any> {
    const postBody = this.createPostBody(requestId, fromDocId, recDocId, method);
    return this._http.post(this._recordUrl, postBody)
      .pipe(
        catchError((error: Response, request: Observable<any>) => {
          return of (request);
        })
      );
  }

  private createPostBody (requestId: string,
                          fromDocId: string,
                          recDocId: string,
                          method: string): string {

    let postBody = '<RecordRelatedItemRequest>';
    postBody += XMLString.xmlwrap(requestId, 'requestId', false);
    postBody += XMLString.xmlwrap(fromDocId, 'fromDocId', false);
    postBody += XMLString.xmlwrap(recDocId, 'recDocId', false);
    postBody += XMLString.xmlwrap(method, 'method', false);
    postBody += '</RecordRelatedItemRequest>';
    return postBody;
  }
}
