export default class SessionTokenRedirect {

  public doRedirect(endpoint: string, token: string) {
    const f = document.createElement("form");
    f.enctype = "application/x-www-form-urlencoded";
    f.method = "POST";
    f.action = endpoint+"/svc/grapple/session";

    const tokenField = document.createElement("input");
    f.appendChild(tokenField);
    tokenField.name = "sessionToken";
    tokenField.type = "hidden";
    tokenField.value = token;
    document.body.append(f);
    f.submit();
  }
}
