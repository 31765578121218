import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { ShibbolethService } from '../../_services/shibboleth/shibboleth.service';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { MatDialogRef} from '@angular/material/dialog';

declare var dataLayer: any;

@Component({
  selector: 'pq-find-institution',
  templateUrl: './find-institution.component.html',
  styleUrls: ['./_find-institution.component.scss'],
  encapsulation: ViewEncapsulation.None
}) export class FindInstitutionComponent implements AfterViewInit, OnInit {
  public isLoading: boolean = false;
  public isWorking: boolean = false;
  public hasError: boolean = false;
  public msg: string;
  public results: object[];
  public previousInstitution: string;

  @ViewChild("institutionName") institutionNameField: ElementRef;
  @ViewChild("title-dialog") dialogTitle: ElementRef;

  constructor (
    private _shibbolethSvc: ShibbolethService,
    private _dialogRef: MatDialogRef<FindInstitutionComponent>
  ) {}

  public ngOnInit () {
    this.previousInstitution = Cookie.get('shibbolethInstitution');
    if (this.previousInstitution) {
      this.getInstitutions(this.previousInstitution, true);
    }
  }

  public ngAfterViewInit () {
    if (!this.previousInstitution) {
      this.institutionNameField.nativeElement.focus();
    }
  }

  public pushFindInstitutionCloseToGA() {
    if (dataLayer && Array.isArray(dataLayer)) {
      dataLayer.push({'event': 'SaveToCloudOverlay', 'label': 'Close', 'action': 'Close'});
    }
  }

  public handleDeleteInstitution () {
    Cookie.delete('shibbolethInstitution', '/');
    this.previousInstitution = undefined;
    this.results = undefined;
    this.dialogTitle.nativeElement.focus();
  }

  public handleSaveInstitution (value) {
    this.isWorking = true
    Cookie.set('shibbolethInstitution', value, 365 * 10, '/');
    this._dialogRef.disableClose = true;
  }

  public handleSubmit (e: Event): void {
    e.preventDefault();
    const theForm = e.currentTarget as HTMLFormElement;
    const text = theForm.institutionName ? theForm.institutionName.value : '';

    if (text) {
      this.getInstitutions(text, false);
    }
  }

  private getInstitutions(text: string, hasSavedInstitution: boolean) {
    this.isLoading = true;

    this._shibbolethSvc.searchInstitutions(text.trim()).then((resp) => {
      this.isLoading =  false;
      this.hasError = resp['Institutions'] && Array.isArray(resp['Institutions']) ? false :  true;
      if (!this.hasError) {
        let results = [];
        resp['Institutions'] = resp['Institutions'].splice(0, 10);
        resp['Institutions'].forEach((item) => {
          const itemObject = JSON.parse(item);
          const itemPropertyNames = Object.getOwnPropertyNames(itemObject);
          const itemPropertyValues = Object.values(itemObject);
          const result = {
            name: itemPropertyNames[0],
            url: itemPropertyValues[0]
          };

          results.push(result);
        });

        if (hasSavedInstitution) {
          results = results.filter((institution) => {
            return institution.name === this.previousInstitution;
          });
        }

        this.results = results;
      }
    }).catch((err) => {
      this.isLoading = false;
      this.hasError = true;
    });
  }

  public trackByFn (index, item) {
    return index;
  }

  public clearPreviousInstitution (): void {
    this.previousInstitution =  undefined;
    this.results =  undefined;
    this.dialogTitle.nativeElement.focus();
  }
}
