import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppState } from '../app.service';

@Injectable ()
export class UpdatePathGuardService implements CanActivate {

  constructor(
    private _appState: AppState,
    private _router: Router
  ) {}

  public canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const profile = this._appState.get('current_profile') || undefined;
    const grappleProducts = this._appState.get('grappleProducts') || [];
    const allProducts = this._appState.get('allProducts') || [];
    const hasOnlyOneProduct = (allProducts.length === 2 && grappleProducts.length === 1);
    let commands = route.url.map((segment) => {
      return segment.path;
    });

    if (profile && hasOnlyOneProduct) {
      // add the profile to the url segments array
      commands.splice(0, 0, profile);
      this._router.navigate(commands, { replaceUrl: true, queryParams: route.queryParams });
    } else {
      this._router.navigate([ 'portal', 'home' ], { replaceUrl: true, queryParams: route.queryParams });
    }

    return false;
  }
}
