import { Injectable } from '@angular/core';
import moment from 'moment';

import { PubDateOptionModel } from '../../_interfaces';

@Injectable()
export class PubDateOptionService {
  // create dates for set date options of publication date filter
  public dateFormat: string = 'YYYYMMDD'; // format of variable
  public currentDate: string = moment().format(this.dateFormat);
  public start30Days: string = moment().subtract(30, 'days').format(this.dateFormat);
  public start12Months: string = moment().subtract(12, 'months').format(this.dateFormat);
  public dateErrors: Object = {
    format: `Invalid date format. Please use the format mm/dd/yyyy`,
    order: `Invalid date range. The from date is later than the to date.`,
    required: `Please enter a start date.`
  };

  private option: PubDateOptionModel[] = [
    { id: 1, title: 'Last 30 days',   fromDate: this.start30Days,   toDate: this.currentDate, active: false },
    { id: 2, title: 'Last 12 months', fromDate: this.start12Months, toDate: this.currentDate, active: false },
  ];

  public get (id?: number): Object[] | Object {
    if (id) {
      return this.option.filter((type) => {
          return type.id === id;
        })[0] || {};
    } else {
      return this.option;
    }
  }
}
