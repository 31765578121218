import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { AppState } from '../../app.service';
import XMLString from  '../../_utilities/xmlstring';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'

@Injectable()
export class AuthService {
  public loggedIn = false;

  private _url: string = '/svc/grapple/session';
  private _redirectLocation = '';

  constructor (
    public appState: AppState,
    private http: HttpClient,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    this.loggedIn = (sessionStorage.getItem('sessionObject') && (<any> sessionStorage.getItem('sessionObject'))['sessionId']);
    this._route.queryParams.subscribe((queryParam: any) => {
      this._redirectLocation = queryParam['location'];
    });
  }

  public login (info: object): Observable<object> {
    const headers = new HttpHeaders();
    let data = '<SessionRequest>';
    let barcode = (<any> info)['barcode'];
    let groupId = info['groupid'] || info['groupId'];
    let accountId = info['accountid'] || info['accountId'];
    let durableUrl = document.location.href;

    if (barcode) {
      data += XMLString.xmlwrap(barcode, 'barcode', false)
           + XMLString.xmlwrap(accountId, 'accountId', false);

      if (groupId) {
        data += XMLString.xmlwrap(groupId, 'groupId', false);
      }

    } else {
      data += XMLString.xmlwrap((<any> info)['user'], 'username', false)
        + XMLString.xmlwrap((<any> info)['password'], 'password', false);
    }

    data += XMLString.xmlwrap(location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : ''), 'proxyHostPort', false);
    data += XMLString.xmlwrap(durableUrl, 'durableUrl', false);
    data += '</SessionRequest>';

    headers.set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post(this._url, data, ({headers}));
  }

  public logout (): Observable<Object> {
    let sessionObject = sessionStorage.getItem('sessionObject') ?
      JSON.parse(sessionStorage.getItem('sessionObject')) : {};
    let logoutUrl = this._url + '/delete/' + sessionObject['sessionId'];
    let headers = new HttpHeaders();

    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.delete(logoutUrl, ({headers})).pipe(
      map((resp) => {
        sessionStorage.removeItem('sessionObject');
        Cookie.delete('session', '/');
        this.appState.clear();
        this._router.navigate(['login']);

        return resp;
      })
    );
  }
}
