export default class XMLString {

  public static encodeAsXML (value: string): string {
    value = value || '';

    return value.replace(/\&/g, '&amp;')
      .replace(/\>/g, '&gt;')
      .replace(/\</g, '&lt;')
      .replace(/\"/g, '&quot;');
  }

  public static xmlwrap (value: string, tag: string, rawMode: boolean): string {
    if (rawMode) {
      return '<' + tag + '>' + value + '</' + tag + '>';
    }
    return '<' + tag + '>' + this.encodeAsXML(value) + '</' + tag + '>';
  }
}
