import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class TokenService {
  private _url: string = '/svc/grapple/token';

  constructor (
    private _http: HttpClient
  ) {}

  public async get (): Promise<any> {
    return await lastValueFrom(this._http.get(this._url));
  }
}
