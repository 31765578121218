<section class="container-googleLogin">
  <mat-dialog-content *ngIf="!isWorking">
    <button
      (click)="pushGoogleLoginCloseToGA()"
      [mat-dialog-close]="true"
      class="btn-close"
      aria-label="Google Login - close"
    >
      <svg class="gr-cancel-squared">
        <use xlink:href="./assets/img/icons.svg#gr-cancel-squared"></use>
      </svg>
    </button>

    <h3 mat-dialog-title tabindex="-1" id="title-dialog">Confirm your email</h3>
    <div class="container-googleLoginContent">
      <p>
        Please enter your <b>school /institution email address. </b>Personal
        Gmail accounts<br />
        are <i>not</i> compatible.
      </p>
      <!-- Email Error Messages -->
      <div class="email-error-messages" *ngIf="showEmailErrorMessages">
        <div class="alert alert-warning" role="alert">
          <ng-container *ngIf="emailErrorMessagesDiv === 'invalidEmail'">
            Please enter <b>valid</b> email address.
          </ng-container>
          <ng-container *ngIf="emailErrorMessagesDiv === 'gmailAddress'">
            <b>You can't log into ProQuest with your Gmail address </b>
            <br />
            Be sure to enter your school/institution email address and not
            your<br />
            personal Gmail address. For help, please ask your librarian or
            teacher.
          </ng-container>
          <ng-container
            *ngIf="emailErrorMessagesDiv === 'institutionNotConfigured'"
          >
            <b>You can't login with Google using this email address.</b>
            <br />
            Your institution may not be configured to use Google to sign into<br />
            ProQuest. Try again using your school/institution email address and
            not<br />
            your personal Google email. For help, please ask your librarian or teacher.
          </ng-container>
        </div>
      </div>

      <form
        method="post"
        action="."
        [formGroup]="googleForm"
        (submit)="handleGoogleLoginSubmit($event)"
      >
        <fieldset>
          <div class="input-group">
            <label [for]="googleEmailLabel"
              ><b>Email address &nbsp;&nbsp;</b></label
            >
            <input
              type="email"
              id="googleEmailLabel"
              value=""
              class="google-form-control"
              name="googleEmail"
              aria-label="Email address"
              autocomplete="off"
              placeholder="School/institution email address"
              required
              email
            />

            <div class="input-group-btn">
              <button style  type="submit" aria-label="Next" class="btn btn-default" style="border-radius: 5px;">
                <span>Next</span>
              </button>
            </div>
          </div>
        </fieldset>
      </form>

      <pq-loading
        [loading]="isLoading"
        [error]="hasError"
        [msg]="msg"
      ></pq-loading>
    </div>
  </mat-dialog-content>
  <mat-dialog-content>
    <pq-loading [loading]="isWorking"></pq-loading>
  </mat-dialog-content>
</section>
