import { AfterViewChecked, Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Cookie } from '../../../../node_modules/ng2-cookies/ng2-cookies';
import { AppState } from '../../app.service';

@Component({
  selector: 'pq-institution',
  templateUrl: './institution.component.html',
  styleUrls: ['./_institution.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InstitutionComponent implements AfterViewChecked {
  @Input() public multipleMatchingAccount: Array<object> = [];

  @Input() set profile (value: string) {
    this._profileVal = value;
  };

  @Input() set redirectLocation (value: string) {
    this._redirectLocation = value;
  }

  @Input() set accountId (value: string) {
    this._accountId = value;
  }

  public hasMatchAccountId: boolean = false;
  private _profileVal: string = '';
  private _redirectLocation: string = '';
  private _accountId: string = undefined;

  constructor (
    public appState: AppState
  ) {}

  get redirectLocationVal () {
    return this._redirectLocation;
  }

  public ngAfterViewChecked (): void {
    const accounts = this.multipleMatchingAccount || [];
    let matchingAccount = undefined;

    if (accounts.length && this._accountId) {
      matchingAccount = accounts.filter((account: object) => {
        return account['accountId'] === this._accountId;
      });

      if (matchingAccount.length) {
        matchingAccount = matchingAccount[0]['accountId'];
        this._handleLogin(matchingAccount);
      }
    }

  }

  public loginForm: FormGroup = new FormGroup({
    institutionAccount: new FormControl('')
  });

  public onSubmit (e: Event): void {
    const accountId = this.loginForm.get('institutionAccount').value;

    if (accountId) {
      this._handleLogin(accountId);
    }
  }

  public getProductName () {
    return this.appState.get('displayname') || 'ProQuest';
  }

  private _handleLogin (accountId: string): void {
    Cookie.set('accountId', accountId, null, '/');
    this._doRedirect(accountId);
  }

  private _doRedirect (accountId: string): void {
    const profile = this.appState.get('current_profile');
    const products = this.appState.get('grappleProducts') || [];
    const allProducts = this.appState.get('allProducts') || [];
    const hasOnlyOneProduct = (allProducts.length === 2 && products.length === 1);
    const paramsStr = '?location=' + encodeURIComponent(this.redirectLocationVal) + "&accountid=" + accountId;
    let path = profile ? profile + '/home' : 'home';

    if (!hasOnlyOneProduct || this.appState.get('enablePortalRedirect')) {
      path = '/portal/home';
    }

    window.scrollTo(0, 0);
    if (this._redirectLocation) {
      window.location.href = path + paramsStr;
    } else {
      window.location.href = path;
    }
  }
}
