import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import XMLString from  '../../_utilities/xmlstring';

@Injectable()
export class PollyService {

  private _url: string = '/svc/grapple/texttospeech?goid=';

  constructor (private _http: HttpClient) {
  }

  public get (goid: string): Promise<any> {
    const url = this._url + goid;
    return this._http.get(url).toPromise();
  }

  public post (audioData: object): Promise<any> {
    const url = this._url.split('?')[0];
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    const data = '<TextToSpeechRequest>' + XMLString.xmlwrap(audioData['text'], 'content', false) + '</TextToSpeechRequest>';
    return this._http.post(url, data, {headers}).toPromise();
  }

}
