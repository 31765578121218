import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { QueryString } from '../../_utilities';
import { FormControl } from '@angular/forms';
import { TermService } from '../../_services/term/term.service';
import { AppState } from '../../app.service';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActionLinkModel } from '../../_interfaces/action-link/action.link.model';

declare var dataLayer: any;

@Component({
  selector: 'pq-basic-search',
  templateUrl: './basic.search.component.html',
  styleUrls: ['./_basic.search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BasicSearchComponent {
  @Input() public sectionClassName: string;
  @Input() public profile: string;
  @Input() public gaSearchFrom: string;
  @Input() public actionLink: ActionLinkModel;

  public ctrl: FormControl = new FormControl();
  public searchParam: string = 'query';
  public term: string = (<any> this._query.getQueryString())[this.searchParam] || '';
  public filteredOptions: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public productName: string = this._appState.get('displayname') || '';
  public ariaMessageTxt: string = '';
  public static ariaSearchBoxMessageTxt = `Search suggestions displayed. Use the down arrow key to browse suggestions.`;

  constructor (
    private _router: Router,
    private _route: ActivatedRoute,
    private _query: QueryString,
    private _termSvc: TermService,
    private _appState: AppState
  ) {

    this.term = (<any> this._query.getQueryString())[this.searchParam];
    this.ctrl.valueChanges.pipe(
      switchMap((input: string) => {
        this.ariaMessageTxt = '';
        return this._termSvc.get(input || '', this._appState.get('current_profile') || '');
      })
    ).subscribe((results: string[]) => {
      this.filteredOptions.next(results);
    });

    this._route.queryParams.subscribe((queryParam: any) => {
      if (typeof queryParam[this.searchParam] === 'undefined') {
        this._appState.set({ searchParams: {} });
      }

      this.ctrl.setValue(queryParam[this.searchParam]);
      this.ariaMessageTxt = BasicSearchComponent.ariaSearchBoxMessageTxt;
    });
  }

  public handleAutoCompleteOpened () {
    this.ariaMessageTxt = BasicSearchComponent.ariaSearchBoxMessageTxt;
  }

  public handleAutoCompleteClosed () {
    this.ariaMessageTxt = '';
  }

  public doSearch (result: Object) {
    const route = this.profile ? this.profile + '/' + 'results' : 'results';

    if (this.ctrl.value && this.ctrl.value.trim()) {
      this._appState.set({ basicSearchType: true, logSearch: true });
      let params = this._appState.get('searchParams') || {};
      params['query'] = this.ctrl.value.trim();
      this.pushSearchToGA(params['query']);
      this._router.navigate([route], { queryParams: params });
    }

  }

  public onAcOptionSelected (acOptionSelected: String) {
    const route = this.profile ? this.profile + '/' + 'results' : 'results';
    let params = this._appState.get('searchParams') || {};
    params['query'] = acOptionSelected.trim();
    this._router.navigate([route], { queryParams: params });
  }

  public pushAdvSearchNavToGA() {
    if (dataLayer && Array.isArray(dataLayer)) {
      if (this.gaSearchFrom === 'home') {
        dataLayer.push({'event': 'AdvancedSearchNav', 'category': 'Home'});
      }  else if (this.gaSearchFrom === 'results') {
        dataLayer.push({'event': 'AdvancedSearchNav', 'category': 'Results'});
      }
    }
  }

  private pushSearchToGA(searchKeyword: String) {
    if (this.gaSearchFrom && dataLayer && Array.isArray(dataLayer)) {
      if (this.gaSearchFrom === 'home') {
        dataLayer.push({'event': 'SearchTextEntry', 'category': 'Home','label': searchKeyword});
        dataLayer.push({'event': 'SearchButton', 'category': 'Home'});
        this._appState.set({ gaSearchFrom: 'Home' });
      } else if (this.gaSearchFrom === 'results') {
        dataLayer.push({'event': 'SearchTextEntry', 'category': 'Results','label': searchKeyword});
        dataLayer.push({'event': 'SearchButton', 'category': 'Results'});
        this._appState.set({ gaSearchFrom: 'Results' });
      }
    }
  }
}
