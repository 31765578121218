import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EventLogAttributes } from '../../_interfaces/event-log/eventlog.model';
import XMLString from '../../_utilities/xmlstring';
import { firstValueFrom, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import { AppState } from '../../app.service';

@Injectable()
export class EventLoggingService {

  private _getReqUrl: string = '/svc/grapple/geteventtype';
  private _postLogEventUrl: string = '/svc/grapple/logevent';

  constructor (private _http: HttpClient,
               private _appState: AppState,
    ) {}

  public async getEventTypes(): Promise<any[]> {
    const events = this._http.get(this._getReqUrl)
      .pipe(
        map((data: Object) => {
          let eventResponse = data;
          let eventTypes: Object[] = [];

          if (eventResponse['EventLogResponse'] && eventResponse['EventLogResponse']['EventType']) {
            let etNodes = eventResponse['EventLogResponse']['EventType'];
            for (let node of etNodes) {
              eventTypes.push({ id: node.eventId, type: node.eventName });
            }
          }

          return eventTypes;

        }),
        catchError((error: any) => {
          return throwError(error.error) || throwError('Server error');
        })
      );

    return await firstValueFrom(events);
  }

  public logEvent (eventLog: EventLogAttributes): Promise<any> {
    return this._http.post(this._postLogEventUrl, this.createPostBody(eventLog)).toPromise();
  }

  public createPostBody (postData: EventLogAttributes): string {
    let postBody = '<EventLogRequest>';

    for (let prop in postData) {
      if (postData.hasOwnProperty(prop) && postData[prop]) {
        postBody += XMLString.xmlwrap(postData[prop].toString(), prop, false);
      }
    }
    postBody += XMLString.xmlwrap(this._appState.get('current_profile'), "moniker", false);

    postBody += '</EventLogRequest>';

    return postBody;
  }
}
