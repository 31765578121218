<div *ngIf="displayBranding" class="container-branding">
    <div *ngIf="linkText">
        <div *ngIf="linkOpensInNewWindow else openLinkInSameWindow">
            <a class="footerTextLink" target="_blank" [href]='_DomSanitizer.bypassSecurityTrustUrl(linkTextUrl)'>{{linkText}}</a>
        </div>
        <ng-template #openLinkInSameWindow>
            <a class="footerTextLink" [href]='_DomSanitizer.bypassSecurityTrustUrl(linkTextUrl)'>{{linkText}}</a>
        </ng-template>
    </div>
    <div class="clearfix"></div>
    <div *ngIf="brandImage && imageUrl else noImageUrl">
        <div *ngIf="imageOpensInNewWindow else openInSameWindow">
            <a [href]='imageUrl' [title]='imageText' target="_blank">
                <img [src]='brandImage' [alt]='imageText' class="img-responsive footerLogo img-rounded" (error)="handleBrokenImage()">
            </a>
        </div>
        <ng-template #openInSameWindow>
            <a [href]='imageUrl' [title]='imageText'>
                    <img [src]='brandImage' [alt]='imageText' class="img-responsive footerLogo img-rounded" (error)="handleBrokenImage()">
            </a>
        </ng-template>
    </div>
    <ng-template #noImageUrl>
        <div *ngIf="brandImage">
            <img [src]='brandImage' [title]='imageText' [alt]='imageText' class="img-responsive footerLogo img-rounded" (error)="handleBrokenImage()">
        </div>
    </ng-template>
</div>
