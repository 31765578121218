import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AppState } from 'src/app/app.service';
import { catchError } from 'rxjs';
declare let pendo: any;
@Injectable({
  providedIn: 'root'
})
export class PendoService {
  targetingCookies = false;
  private accountId;
  private accountName;
  private sessionType;
  private isTrailProduct;
  private skuData;
  private isPaidUser;
  private functionalcookies;
  private visitorId;
  constructor(private httpClient: HttpClient) {
    this.initializeVisitorId();
  }
  public targetingCookiesEnabledOrDisabled(value) {
    this.targetingCookies = value;
  }
  public functiongCookiesEnabledOrDisabled(value: boolean): void {
    if (value) {
      setTimeout(() => {
        this.pendoIntialize();
      }, 1000);
    }
    this.functionalcookies = value;
    this.pendoDNPCall();
  }
  getLanguage(): string {
    // Splitting the localeId by hyphen and taking the first part
    const language = window.navigator.language.split('-')[0];
    return language;
  }
  pendoDNPCall() {
    let val = this.functionalcookies ? false : true
    const url = '/svc/grapple/pendoDoNotProcess';
    let id = this.visitorId;
    const queryParams = `?visitorId=${id}&doNotProcess=${val}`;
    setTimeout(() => {
      if (pendo && pendo.isReady && pendo.isReady()) {
        this.httpClient.post(url + queryParams, {})
          .toPromise()
          .then((response) => {
            // Handle response if needed
          })
          .catch((error) => {
            // Handle error if needed
          });
      }
    });
  }
  pendoIntialize() {
    pendo.initialize({
      visitor: {
        id: this.visitorId,
        GuidedResearch_targetingCookies: this.targetingCookies ? 'YES' : 'NO',
        // typeof OnetrustActiveGroups !== "undefined" && OnetrustActiveGroups.includes("C0004") ? 'YES' : 'NO',
        is_trialProduct: this.isTrailProduct,
        skuData: this.skuData,
        interfaceLanguage: this.getLanguage(),
        is_mrUser: 'False',
        authmethod: this.sessionType,
      },
      account: {
        id: this.accountId,
        accountName: this.accountName,
        is_paying: this.isPaidUser,
      }
    });
  }
  sessionDetails(session, path) {
    this.accountId = session.accountId;
    this.accountName = session.accountName;
    this.sessionType = session.sessionType;
    this.isTrailProduct = session.isTrailProduct;
    this.skuData = path == '/elibrary/home' ? session.skuData[0] : path == '/sirsdiscoverer/home' ? session.skuData[2] : path == '/sirsissuesresearcher/home' ? session.skuData[1] : "";
    this.isPaidUser = session.isPaidUser;
  }
  private initializeVisitorId(): void {
    const storedVisitorId = localStorage.getItem('visitorId');
    if (storedVisitorId) {
      this.visitorId = storedVisitorId;
    } else {
      this.visitorId = this.generateUniqueId();
      localStorage.setItem('visitorId', this.visitorId);
    }
  }
  private generateUniqueId(): string {
    // Generates a unique ID (UUID v4 can be used or any unique ID generator)
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
