export default class DomainChecks {

  public checkIfRedirectFromProxyDomain() {
    const PROQUEST_TLD = "proquest.com";
    const LOCALHOST_DOMAIN = "localhost";

    return !
      (location.host.toLowerCase().endsWith(PROQUEST_TLD) || 
      location.host.toLowerCase().startsWith(LOCALHOST_DOMAIN))    
  }

}