import { Injectable } from '@angular/core';
import { CitationStyleModel } from '../../_interfaces';

@Injectable()
export class CitationStyleService {

  private citationStyle: CitationStyleModel[] = [
    { id: 1, title: 'MLA 9',      value: 'MLA9'       },
    { id: 2, title: 'APA 7',      value: 'APA7'       },
    { id: 3, title: 'Turabian 8', value: 'TURABIAN8_NOTES'  },
  ];

  public get (id?: number): Object[] | Object {
    if (id) {
      return this.citationStyle.filter((type) => {
        return type.id === id;
      })[0] || {};
    } else {
      return this.citationStyle;
    }
  }
}
