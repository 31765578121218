import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ShibbolethService {
  private _url: string = '/svc/grapple/shibinstitutions';

  constructor (
    private _http: HttpClient
  ) {}

  public searchInstitutions (term: string): Promise<any> {
    return this._http.get(`${this._url}?filter=${term}`).toPromise();
  }
}
