<a href="#content" (click)="handleContentLink($event)" class="skip">Skip to content</a>
<pq-notification></pq-notification>
<div class="container-ltiBanner" role="banner" *ngIf="displayLtiBanner">
  <p>
    <svg class="gr-info-circle" xmlns="http://www.w3.org/2000/svg">
      <use xlink:href="./assets/img/icons.svg#gr-info-circle"></use>
    </svg>
    Navigate to a document and use the “Select this” button at the top of the page.
  </p>
</div>
<div [ngClass]="['page-container']" id="page" tabindex="-1">
	<pq-site-header [profile]="profile"></pq-site-header>
  <iframe sandbox="allow-same-origin allow-popups allow-scripts allow-forms" class="iframe-googleCom" *ngIf="iframeUrl"  [src]="iframeUrl | pqSafeUrl" #iframeGoogleCom></iframe>
	<main id="content" tabindex="-1">
		<router-outlet></router-outlet>
    <pq-loading [loading]="loading" [error]="error" id="container-mainLoader"></pq-loading>
	</main>
</div>
<pq-site-footer></pq-site-footer>
<pq-modal-placeholder></pq-modal-placeholder>


