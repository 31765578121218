import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter
} from '@angular/core';
import { CaptchaService } from './captcha.service';
import { NgForm } from '@angular/forms';
import { RecaptchaService } from './recaptcha.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pq-captcha',
  styleUrls: ['./_captcha.component.scss'],
  templateUrl: './captcha.component.html'
})
export class CaptchaComponent implements OnInit{
  @Input() isRobot: boolean = false;
  @Output() onCheck: EventEmitter<object> = new EventEmitter<object>();

  public working: boolean = false;
  public formError: boolean = false;
  public captchaImage: any = '';
  public useReCAPTCHA: boolean = true; // default to google recaptcha
  public error: boolean = false;
  public loading: boolean = false;

  public captcha: string;
  private _attempts: number = 0;

  constructor(
    private _captchaSvc: CaptchaService,
    private _recaptchaSvc: RecaptchaService,
    private _route: ActivatedRoute,
    private _dialogRef: MatDialogRef<CaptchaComponent>
  ) {
    window['verifyCallback'] = this.verifyCallback.bind(this);

    // testing override for switching the default captcha system
    this._route.queryParams.subscribe(params => {
      if (typeof params['userecaptcha'] !== 'undefined') {
        this.useReCAPTCHA = params['userecaptcha'] === 'true' ? true : false;
      }
    });
  }

  public ngOnInit () {
    this.loading = true;
    this.formError = false;

    // style change to fix display conflict between recaptcha and google material dialog
    document.getElementsByTagName('html')[0].style.top = '0';

    if (this.useReCAPTCHA) {
      const recaptchaLoad = this._recaptchaSvc.loadScript();

      recaptchaLoad.then((resp) => {
        if (resp['loaded']) {
          this.loading = false;
          this.useReCAPTCHA = true;
        } else {
          this.useReCAPTCHA = false;
          this._getCAPTCHAImage();
        }
      });

      recaptchaLoad.catch((err) => {
        this.useReCAPTCHA = false;
        this._getCAPTCHAImage();
      });
    } else {
      this._getCAPTCHAImage();
    }
  }

  public verifyCallback (key: string) {
    this.onCheck.emit({ key: key, loading: false, error: false });
  }

  public handleCAPTCHAForm (form: NgForm) {
    const formValues = form.value;
    const captcha = formValues.captcha as string;

    if (form.valid && !this.working) {
      this.working = true;

      this._attempts++;

      const text = captcha.trim().toLowerCase();

      this._captchaSvc.validate(text).then((resp: any) => {
        const respObject = resp['ValidateCaptchaResponse'] || {};
        const isValid = respObject['validCaptcha'] === 'true';
        this.working = false;

        if (isValid) {
          this.formError = false;
          this._attempts = 0;

          this.onCheck.emit({ key: respObject['id'], loading: false, error: false });
        } else {

          if (this._attempts === 3) {
            this.loading = true;
            form.reset();
            this._getCAPTCHAImage();
          } else {
            this.formError = true;
          }
        }
      });
    }
  }

  public handleImageRefresh (): void {
    this._attempts = 0;
    this.working = true;
    this._getCAPTCHAImage();
    this.captcha = null;
  }

  public closeDialog() {
    this._dialogRef.close();
  }

  private _createImageFromBlob (image: Blob): void {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      this.captchaImage = reader.result;
    });

    reader.readAsDataURL(image);
  }

  private _getCAPTCHAImage (): void {
    this._captchaSvc.getImage().then((image) => {
      this._createImageFromBlob(image);
      this.loading = false;
      this.working = false;
    }).catch((err) => {
      this.loading = false;
      this.error = true;
    });
  }
}
