import { Inject, Injectable } from '@angular/core';
import { InjectionToken } from '@angular/core';
import { FaviconsConfig } from './favicons-config.interface';
import { IconsConfig } from './icons-config.interface';
import { IconConfig } from './icon-config.interface';

export var BROWSER_FAVICONS_CONFIG = new InjectionToken<FaviconsConfig>('Favicons Configuration');

// This abstract class acts as both the interface for implementation (for any developer
// that wants to create an alternate implementation) and as the dependency-injection
// token that the rest of the application can use.
export abstract class FaviconsService {
  abstract activate (name: string): void;

  abstract reset (): void;
}

// I provide the browser-oriented implementation of the Favicons class.
@Injectable()
export class BrowserFavicons implements FaviconsService {

  private elementId: string;
  private icons: Array<IconsConfig>;
  private useCacheBusting: boolean;

  // I initialize the Favicons service.
  constructor (@Inject(BROWSER_FAVICONS_CONFIG) config: FaviconsConfig) {
    this.elementId = 'favicons-service-injected-node';
    this.icons = Object.assign(Object.create(null), config.icons);
    this.useCacheBusting = ( config.cacheBusting || false );
    // Since the document may have a static favicon definition, we want to strip out
    // any exisitng elements that are attempting to define a favicon. This way, there
    // is only one favicon element on the page at a time.
    this._removeExternalLinkElements(document.head.querySelectorAll('link[ rel ~= \'icon\' ]'));
    this._removeExternalLinkElements(document.head.querySelectorAll('link[ rel ~= \'apple-touch-icon\']'));

  }

  // ---
  // PUBLIC METHODS.
  // ---
  // I activate the favicon with the given name / identifier.
  public activate (name: string): void {

    if (!this.icons[ name ]) {
      throw( new Error(`Favicon for [ ${ name } ] not found.`) );
    }

    const nodes = this.icons[ name ]['icons'] as Array<IconConfig>;

    this._addIcons(nodes);

  }


  // I activate the default favicon (with isDefault set to True).
  public reset (): void {
    let hasDefault: boolean = false;

    this._removeExternalLinkElements(document.head.querySelectorAll('link[ rel ~= \'icon\' ]'));
    this._removeExternalLinkElements(document.head.querySelectorAll('link[ rel ~= \'apple-touch-icon\' ]'));

    for (let name of Object.keys(this.icons)) {

      let icon = this.icons[ name ];
      let nodes = icon['icons'];

      if (icon.isDefault) {
        this._addIcons(nodes);
        hasDefault = true;

        return;
      }
    }

    if (hasDefault === false) {
      this._addIcons(this.icons['elibrary']['icons']);
    }

  }

  private _addIcons (nodes: Array<IconConfig>): void {
    nodes.forEach((config: IconConfig, index: number) => {
      config['index'] = index.toString();
      this._setNode(config);
    });
  }

  // ---
  // PRIVATE METHODS.
  // ---
  // I inject the favicon element into the document header.
  private _addNode (config: IconConfig, href: string): void {

    const linkElement = document.createElement('link');

    linkElement.setAttribute('id', this.elementId + config['index']);
    linkElement.setAttribute('rel', config['rel']);
    linkElement.setAttribute('type', config.type);
    linkElement.setAttribute('href', href);

    document.head.appendChild(linkElement);

  }


  // I return an augmented HREF value with a cache-busting query-string parameter.
  private _cacheBustHref (href: string): string {

    const augmentedHref = ( href.indexOf('?') === -1 ) ? `${ href }?faviconCacheBust=${ Date.now() }` : `${ href }&faviconCacheBust=${ Date.now() }`;

    return ( augmentedHref );

  }


  // I remove any favicon nodes that are not controlled by this service.
  private _removeExternalLinkElements (linkElements: NodeList): void {
    if (linkElements.length) {
      for (let linkElement of Array.from(linkElements)) {
        linkElement.parentNode.removeChild(linkElement);
      }
    }
  }


  // I remove the favicon node from the document header.
  private _removeNode (index?: string): void {

    if (typeof index !== 'undefined') {
      const linkElement = document.head.querySelector('#' + this.elementId + index);

      if (linkElement) {
        document.head.removeChild(linkElement);
      }
    }
  }


  // I remove the existing favicon node and inject a new favicon node with the given
  // element settings.
  private _setNode (iconConfig: IconConfig): void {

    const augmentedHref = this.useCacheBusting ? this._cacheBustHref(iconConfig.href) : iconConfig.href;

    this._removeNode(iconConfig['index']);
    this._addNode(iconConfig, augmentedHref);

  }

}
